angular.module('cdApp.shared').run([
  '$templateCache',
  ($templateCache) => {
    $templateCache.put(
      '@/app/shared/components/accordion/accordion.component.html',
      require('./components/accordion/accordion.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/add-group-member/add-group-member.component.html',
      require('./components/add-group-member/add-group-member.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/add-payment-method/edit-billing-customer.component.html',
      require('./components/edit-billing-customer/edit-billing-customer.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/advanced-filter/advanced-filter-criteria/advanced-filter-criteria.component.html',
      require('./components/advanced-filter/advanced-filter-criteria/advanced-filter-criteria.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/advanced-filter/advanced-filter-operator/advanced-filter-operator.component.html',
      require('./components/advanced-filter/advanced-filter-operator/advanced-filter-operator.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/advanced-filter/advanced-filter.component.html',
      require('./components/advanced-filter/advanced-filter.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/announcements/announcements.component.html',
      require('./components/announcements/announcements.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/app-menu/app-menu.component.html',
      require('./components/app-menu/app-menu.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/app-nav/app-nav.component.html',
      require('./components/app-nav/app-nav.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/billing-information/billing-information.component.html',
      require('./components/billing-information/billing-information.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/cd-comments/cd-comments.html',
      require('./components/cd-comments/cd-comments.html')
    );

    $templateCache.put(
      '@/app/shared/components/cd-comments/modals/delete-comment-confirmation-modal.html',
      require('./components/cd-comments/modals/delete-comment-confirmation-modal.html')
    );

    $templateCache.put(
      '@/app/shared/components/cd-comments/modals/edit-comment-modal.html',
      require('./components/cd-comments/modals/edit-comment-modal.html')
    );

    $templateCache.put(
      '@/app/shared/components/cd-comments-form/cd-comments-form.html',
      require('./components/cd-comments-form/cd-comments-form.html')
    );

    $templateCache.put(
      '@/app/shared/components/cd-dynamic-progressbar/cd-dynamic-progressbar.html',
      require('./components/cd-dynamic-progressbar/cd-dynamic-progressbar.html')
    );

    $templateCache.put(
      '@/app/shared/components/cd-statistic/cd-statistic.html',
      require('./components/cd-statistic/cd-statistic.html')
    );

    $templateCache.put(
      '@/app/shared/components/column-manager/column-manager.component.html',
      require('./components/column-manager/column-manager.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-content/create-content.html',
      require('./components/create-content/create-content.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-content/modals/confirm-cancel.html',
      require('./components/create-content/modals/confirm-cancel.html')
    );

    $templateCache.put(
      '@/app/shared/components/contribution-feed/contribution-feed.component.html',
      require('./components/contribution-feed/contribution-feed.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-content/partials/absence/absence-form.html',
      require('./components/create-content/partials/absence/absence-form.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-content/partials/blog/blog-form.html',
      require('./components/create-content/partials/blog/blog-form.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-content/partials/event/event-form.html',
      require('./components/create-content/partials/event/event-form.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-content/partials/event/partials/basic-step.html',
      require('./components/create-content/partials/event/partials/basic-step.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-content/partials/event/partials/planning-step.html',
      require('./components/create-content/partials/event/partials/planning-step.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-content/partials/event/partials/public-step.html',
      require('./components/create-content/partials/event/partials/public-step.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-folder/create-folder.component.html',
      require('./components/create-folder/create-folder.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-group/create-group.component.html',
      require('./components/create-group/create-group.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-group-message/create-group-message.component.html',
      require('./components/create-group-message/create-group-message.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/create-user/create-user.component.html',
      require('./components/create-user/create-user.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/date-interval-filter/date-interval-filter.component.html',
      require('./components/date-interval-filter/date-interval-filter.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/edit-recurring-modal/edit-recurring-modal.component.html',
      require('./components/edit-recurring-modal/edit-recurring-modal.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/empty-state/empty-state.component.html',
      require('./components/empty-state/empty-state.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/error-state/error-state.component.html',
      require('./components/error-state/error-state.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/file-uploader/file-uploader.component.html',
      require('./components/file-uploader/file-uploader.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/file-uploader-modal/file-uploader-modal.component.html',
      require('./components/file-uploader-modal/file-uploader-modal.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/flex-alert/flex-alert.component.html',
      require('./components/flex-alert/flex-alert.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/font-picker/font-picker.component.html',
      require('./components/font-picker/font-picker.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/forgot-password/forgot-password.component.html',
      require('./components/forgot-password/forgot-password.component.html')
    );

    $templateCache.put(
      '@/app/homepage/homepage/homepage.component.html',
      require('@/app/homepage/homepage/homepage.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/image-crop/image-crop.component.html',
      require('./components/image-crop/image-crop.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/image-crop-modal/image-crop-modal.component.html',
      require('./components/image-crop-modal/image-crop-modal.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/image-drop-area/image-drop-area.component.html',
      require('./components/image-drop-area/image-drop-area.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/input-label/input-label.component.html',
      require('./components/input-label/input-label.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/input-search-pages/input-search-pages.component.html',
      require('./components/input-search-pages/input-search-pages.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/input-search-pages/page-search-results.template.html',
      require('./components/input-search-pages/page-search-results.template.html')
    );

    $templateCache.put(
      '@/app/shared/components/invitation-response/invitation-response.component.html',
      require('./components/invitation-response/invitation-response.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/invitation-response-button/invitation-response-button.component.html',
      require('./components/invitation-response-button/invitation-response-button.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/landing-page/landing-page.component.html',
      require('./components/landing-page/landing-page.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/legal-document-modal/legal-document-modal.component.html',
      require('./components/legal-document-modal/legal-document-modal.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/legal-documents/legal-documents.component.html',
      require('./components/legal-documents/legal-documents.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/loader/loader.component.html',
      require('./components/loader/loader.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/loader/partials/spinner.partial.html',
      require('./components/loader/partials/spinner.partial.html')
    );

    $templateCache.put(
      '@/app/shared/components/login-form/login-form.component.html',
      require('./components/login-form/login-form.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/login-page/login-page.component.html',
      require('./components/login-page/login-page.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/login-prompt/login-prompt.component.html',
      require('./components/login-prompt/login-prompt.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/page-header/page-header.component.html',
      require('./components/page-header/page-header.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/page-statistics/page-statistics.component.html',
      require('./components/page-statistics/page-statistics.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/password-requirements/password-requirements.component.html',
      require('./components/password-requirements/password-requirements.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/people-custom-fields-data/people-custom-fields-data.component.html',
      require('./components/people-custom-fields-data/people-custom-fields-data.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/register-page/register-page.component.html',
      require('./components/register-page/register-page.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/repeat/templates/Modal.html',
      require('./components/repeat/templates/Modal.html')
    );

    $templateCache.put(
      '@/app/shared/components/repeat/templates/Rrule.html',
      require('./components/repeat/templates/Rrule.html')
    );

    $templateCache.put(
      '@/app/shared/components/select-folder/select-folder.component.html',
      require('./components/select-folder/select-folder.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/shifts-list/shifts-list.html',
      require('./components/shifts-list/shifts-list.html')
    );

    $templateCache.put(
      '@/app/shared/components/shifts-view-note/shifts-view-note.html',
      require('./components/shifts-view-note/shifts-view-note.html')
    );

    $templateCache.put(
      '@/app/shared/components/simple-modal/simple-modal.html',
      require('./components/simple-modal/simple-modal.html')
    );

    $templateCache.put(
      '@/app/shared/components/steps/steps.html',
      require('./components/steps/steps.html')
    );

    $templateCache.put(
      '@/app/shared/components/task-assign-user/task-assign-users.component.html',
      require('./components/task-assign-user/task-assign-users.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/text-avatar/text-avatar.component.html',
      require('./components/text-avatar/text-avatar.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/text-truncater/text-truncater.component.html',
      require('./components/text-truncater/text-truncater.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/update-organization-email/update-organization-email.component.html',
      require('./components/update-organization-email/update-organization-email.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/update-person-tags-modal/update-person-tags-modal.component.html',
      require('./components/update-person-tags-modal/update-person-tags-modal.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/update-user-phone/update-user-phone.component.html',
      require('./components/update-user-phone/update-user-phone.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/user-organizations/user-organizations.component.html',
      require('./components/user-organizations/user-organizations.component.html')
    );

    $templateCache.put(
      '@/app/shared/components/widget-manager/partials/embed-widget-modal.html',
      require('./components/widget-manager/partials/embed-widget-modal.html')
    );
    $templateCache.put(
      '@/app/shared/components/legal-popup/legal-popup.component.html',
      require('./components/legal-popup/legal-popup.component.html')
    );
  },
]);
