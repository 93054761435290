import { useForm } from 'antd/es/form/Form';
import { useCallback, useEffect } from 'react';

import getTextCatalog from '../../services/I18nService';

import { InnerModalProps } from '@/react/shared/components/cd-modal-new/CdModal2';
import { mainApi } from '@/react/api';
import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';

export default function useEditPortalCategory(
  setModalProps: (props: InnerModalProps) => void,
  categoryId: string,
  onFinish: () => void
) {
  const [form] = useForm();

  const onOk = useCallback(
    () =>
      form
        .validateFields()
        .then(async (values) =>
          mainApi.put(`/portal/categories/` + categoryId, {
            name: values.name,
            description: values.description === '' ? null : values.description,
          })
        )
        .then(() => {
          onFinish();
        })
        .catch(handleAntFormValidationErrors),
    [categoryId, form, onFinish]
  );

  useEffect(() => {
    setModalProps({
      title: getTextCatalog.getString('Edit portal category'),
      width: 500,
      onOk,
    });
  }, [setModalProps, onOk]);

  return { form };
}
