class CreateUserModalComponent {
  constructor($state, $uibModal) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.createGroup = this.createGroup.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(newUser) {
    if (!newUser) return this.dismiss();
    this.close({ $value: newUser });
  }

  createGroup(done) {
    const { $uibModal } = this;

    $uibModal
      .open({
        component: 'cdCreateGroupModal',
      })
      .result.then((newGroup) => {
        done(newGroup);
      });
  }
}
CreateUserModalComponent.$inject = ['$state', '$uibModal'];

angular.module('cdApp.shared').component('cdCreateUserModal', {
  templateUrl: '@/app/shared/components/create-user/create-user.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: CreateUserModalComponent,
});
