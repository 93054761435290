(function () {
  'use strict';

  function FilesController(
    moment,
    _,
    $scope,
    $rootScope,
    AuthenticationService,
    filesFactory,
    $uibModal,
    $stateParams,
    $timeout,
    $sce,
    $window,
    $state,
    gettextCatalog,
    userAccess,
    toastr,
    Me
  ) {
    $scope.groupId = $stateParams.gid;
    if ($scope.groupId) {
      $rootScope.currentGroupState = 'files';
    }

    $scope.mainModel = {
      groupfiles: [],
      filearchivefolders: {},
      deferedRequestAbort: {},
      translation: {},
    };

    $scope.pageNumber = 1;
    $scope.mainModel.initialLoad = true;
    $scope.mainModel.busy = true;
    $scope.mainModel.showerror = true;
    $scope.mainModel.sortby = 'title';

    $scope.mainModel.asc = true;
    $scope.mainModel.breadcrumbs = [];
    $scope.mainModel.totalCount = 0;

    $scope.userPermissionTable = [];
    $scope.toolTipText = '';
    $scope.validators = [];
    $scope.mainModel.home_object = {};
    $scope.mainModel.stopreplace = false;

    // Add perfect scroller.
    $timeout(function () {
      angular
        .element('.browse-files-scroll')
        .perfectScrollbar({ suppressScrollX: true });
    }, 700);

    // By default search globally
    $scope.mainModel.isGlobalSearch = true;
    $scope.mainModel.previousIsGlobal = true;

    $scope.mainModel.currentFolderId = -1;
    $scope.filePicker = [];
    $scope.filePicker.sizeSelections = [
      {
        id: 'thumbnail',
        name: gettextCatalog.getString('Thumbnail') + ' (80 x 80)',
        width: 80,
        height: 80,
      },

      {
        id: 'medium',
        name:
          gettextCatalog.getString('Medium') +
          ' (' +
          gettextCatalog.getString('Max width: 370px') +
          ')',
        width: 370,
        height: 278,
      },

      {
        id: 'large',
        name:
          gettextCatalog.getString('Large') +
          ' (' +
          gettextCatalog.getString('Max width: 700px') +
          ')',
        width: 700,
        height: 525,
      },
    ];

    $scope.filePicker.sizeSelection = $scope.filePicker.sizeSelections[1];

    $scope.mainModel.selectedFiles = [];
    $scope.mainModel.isfilesfoldersselected = false;

    if ($scope.groupId) {
      $scope.mainModel.home_object = {
        groups: $scope.groupId,
        move_type: 'group',
      };
    }

    if (typeof $stateParams.folder_id == 'undefined') {
      $scope.mainModel.folder_id = -1;
    } else {
      $scope.mainModel.folder_id = $stateParams.folder_id;
    }

    $scope.mouseIsDown = false;
    $scope.visibilityOptions = [
      { label: gettextCatalog.getString('Public'), value: 'web' },
      {
        label: gettextCatalog.getString('Only share with group'),
        value: 'group',
      },
    ];

    $scope.groups = [];

    $scope.mainModel.folderData = {};
    $scope.mainModel.currentFolderGroup = $scope.groupId;

    // Default sort by Name in ASC order
    $scope.mainModel.orderByDirection = 'ASC';
    $scope.mainModel.orderBy = 'name';
    $scope.filePicker.selectedFile = {};

    $scope.isOrderingUp = function () {
      return $scope.mainModel.orderByDirection === 'ASC';
    };
    $scope.isOrderingDown = function () {
      return $scope.mainModel.orderByDirection !== 'ASC';
    };
    $scope.isOrderingBy = function (fieldName) {
      return $scope.mainModel.orderBy === fieldName;
    };

    $scope.orderBy = function (fieldName) {
      if (fieldName === $scope.mainModel.orderBy) {
        $scope.mainModel.orderByDirection =
          $scope.mainModel.orderByDirection === 'ASC' ? 'DESC' : 'ASC';
      } else {
        $scope.mainModel.orderByDirection = 'ASC';
        $scope.mainModel.orderBy = fieldName;
      }
      $scope.getFilesFolders({
        folderId: $scope.mainModel.folder_id,
        searchText:
          !$scope.mainModel.search || $scope.mainModel.search.length === 0
            ? ''
            : $scope.mainModel.search,
        resetStorage: true,
      });
    };

    var getKey = function (obj, value) {
      var key = -1;
      angular.forEach(obj, function (element, index) {
        if (element.value == value) {
          key = index;
        }
      });
      return key;
    };

    // Increment the displayed files folders by 10.
    $scope.incrementDisplay = function () {
      if ($scope.mainModel.groupfiles.length < $scope.mainModel.totalCount) {
        $scope.pageNumber++;
        $scope.getFilesFolders({
          folderId: $scope.mainModel.folder_id,
          searchText:
            !$scope.mainModel.search || $scope.mainModel.search.length === 0
              ? ''
              : $scope.mainModel.search,
        });
      }
    };

    /**
     * Get file field validators
     */

    filesFactory.http
      .httpGET(cdApp.config.api.main + '/files/uploadvalidators', {
        gallery: false,
      })
      .success(function (data) {
        $scope.validators = data;
      });

    // Trigger a tooltip.
    $scope.tooltipTrigger = function (event) {
      if ($scope.mainModel.selectedFiles.length == 0) {
        $scope.toolTipText = 'Please select at least one file.';
      }
    };

    // Get the permission of a user.
    filesFactory.http
      .httpGET(cdApp.config.api.main + '/filesystem/permissions')
      .success(function (data) {
        $scope.userPermissionTable = data;
      })
      .error(function () {});

    $scope.uid = Me.id;

    // Get all groups that the user is a member.
    // organizations/:organizationId/users/:uid/groups
    var groupUrl =
      cdApp.config.api.main +
      '/organizations/' +
      $window.churchdeskOrganizationId +
      '/users/' +
      Me.id +
      '/groups';
    filesFactory.http.httpGET(groupUrl).success(function (data) {
      angular.forEach(data, function (obj, key) {
        $scope.groups.push({ label: obj.name, value: obj.id });
      });
      if ($scope.groups.length == 1) {
        $scope.mainModel.folderData['group'] = $scope.groups[0];
      } else {
        $scope.mainModel.folderData['group'] =
          $scope.groups[getKey($scope.groups, $scope.groupId)];
      }
    });

    $scope.filterImage = function (item) {
      return (
        item.fileInfo &&
        item.fileInfo.filetype.type &&
        $scope.isImage(item.fileInfo.filetype.type)
      );
    };

    $scope.filterFolder = function (item) {
      return item.type == 'folder';
    };

    $scope.filterFiles = function (item) {
      if (_.has(item, 'fileInfo')) {
        return $scope.isImage(item.fileInfo.filetype.type);
      }
      return item.type == 'folder';
    };

    $scope.isFile = function (type) {
      return type == 'file';
    };

    $scope.isFolder = function (type) {
      return type == 'folder';
    };

    $scope.isNotImage = function (file_type) {
      return file_type != 'image';
    };

    $scope.isImage = function (file_type) {
      return file_type == 'image';
    };

    /**
     * Format date
     * @param date
     * @returns {*}
     */
    $scope.formatdate = function (date) {
      var m = moment(date, moment.ISO_8601);
      return m.format('lll');
    };

    /**
     * Selects all files or folders
     */
    $scope.allFilesSelected = function () {
      if ($scope.mainModel.selected) {
        $scope.mainModel.groupfiles.forEach(function (item) {
          if (
            item.permission &&
            (item.permission.canEdit || item.permission.canDelete)
          ) {
            $scope.mainModel.selectedFiles.push(item);
            item.selected = true;
          }
        });
        $scope.toolTipText = '';
      } else {
        $scope.mainModel.groupfiles.forEach(function (item) {
          item.selected = false;
        });
        $scope.mainModel.selectedFiles = [];
      }
    };

    /**
     * Selects only one file for the filearchive
     * @param item
     */
    $scope.oneFileSelected = function (item) {
      if (item.type === 'file') {
        for (var i = 0; i < $scope.mainModel.groupfiles.length; i++) {
          $scope.mainModel.groupfiles[i].selected = false;
        }
        item.selected = true;

        $scope.filePicker.selectedFile = item;
      }
    };

    $scope.fileSelected = function (item) {
      if (item.selected) {
        $scope.mainModel.selectedFiles.push(item);
        if (
          $scope.mainModel.selectedFiles.length ==
          $scope.mainModel.groupfiles.length
        ) {
          $('.item_check_all').prop('checked', true);
        }
        $scope.toolTipText = '';
      } else {
        var index = $scope.mainModel.selectedFiles.indexOf(item);
        $scope.mainModel.selectedFiles.splice(index, 1);
        $('.item_check_all').prop('checked', false);
      }
    };

    // Checks if the single FILE is selected, not a folder.
    $scope.singleFileIsSelected = function () {
      return (
        $scope.mainModel.selectedFiles.length == 1 &&
        !_.includes(_.map($scope.mainModel.selectedFiles, 'type'), 'folder')
      );
    };

    $scope.hasSelectedMultipleFiles = () => {
      return (
        _.filter($scope.mainModel.selectedFiles, { type: 'file' }).length > 1
      );
    };

    $scope.canDownloadFolder = () => {
      return (
        $scope.mainModel.currentFolderId !== -1 &&
        $scope.mainModel.groupfiles.length > 0 &&
        _.filter($scope.mainModel.selectedFiles, { type: 'folder' }).length ===
          0
      );
    };

    $scope.canMoveFiles = () => {
      return (
        $scope.mainModel.selectedFiles.length > 0 &&
        _.every(
          $scope.mainModel.selectedFiles,
          (item) => item.permission.canEdit
        )
      );
    };

    $scope.canDeleteFiles = () => {
      return (
        $scope.mainModel.selectedFiles.length > 0 &&
        _.every(
          $scope.mainModel.selectedFiles,
          (item) => item.permission.canDelete
        )
      );
    };

    $scope.getMoveToolTipString = () => {
      if ($scope.mainModel.selectedFiles.length === 0)
        return gettextCatalog.getString('Select a file to move it');
      if (
        !_.every(
          $scope.mainModel.selectedFiles,
          (item) => item.permission.canEdit
        )
      )
        return gettextCatalog.getString(
          'You do not have permission to edit some of the selected files.'
        );
    };

    $scope.getDeleteToolTipString = () => {
      if ($scope.mainModel.selectedFiles.length === 0)
        return gettextCatalog.getString('Select a file to delete it');
      if (
        !_.every(
          $scope.mainModel.selectedFiles,
          (item) => item.permission.canDelete
        )
      )
        return gettextCatalog.getString(
          'You do not have permission to delete some of the selected files.'
        );
    };

    /**
     * Download a folder in a zip format.
     */
    $scope.downloadFolder = () => {
      const folderId = $scope.mainModel.currentFolderId;
      if (!folderId) return;
      filesFactory.http
        .httpPOST(cdApp.config.api.main + '/files/create/zip', { folderId })
        .success(function () {
          $uibModal.open({
            component: 'cdSimpleModal',
            resolve: {
              title: () => gettextCatalog.getString('Preparing the folder'),
              body: () =>
                gettextCatalog.getString(
                  'You will receive an email with a link to download when it is ready.'
                ),

              options: {
                showSecondaryButton: false,
                hideConfirmButton: true,
              },
            },
          });
        })
        .error(function (error) {
          if (_.isArray(error)) {
            toastr.error(
              _.get(error, 'message') ||
                gettextCatalog.getString(
                  'An error occurred, please try again. If the problem persists, please contact our support.'
                )
            );
          }
        });
    };

    /**
     * Download list of files zipped.
     */
    $scope.downloadFiles = () => {
      const filesToDownload = _.filter($scope.mainModel.selectedFiles, {
        type: 'file',
      });

      if (_.isEmpty(filesToDownload)) return;
      filesFactory.http
        .httpPOST(cdApp.config.api.main + '/files/create/zip', {
          fileIds: _.map(filesToDownload, 'id'),
        })
        .success(function () {
          $uibModal.open({
            component: 'cdSimpleModal',
            resolve: {
              title: () => gettextCatalog.getString('Preparing the files'),
              body: () =>
                gettextCatalog.getString(
                  'You will receive an email with a link to download when it is ready.'
                ),

              options: {
                showSecondaryButton: false,
                hideConfirmButton: true,
              },
            },
          });
        })
        .error(function (error) {
          if (_.isArray(error)) {
            toastr.error(
              _.get(error, 'message') ||
                gettextCatalog.getString(
                  'An error occurred, please try again. If the problem persists, please contact our support.'
                )
            );
          }
        });
    };

    /**
     * Helper function to get files folders.
     * @param folderid
     * @param searchtex
     */
    $scope.getFilesFolders = function ({ folderId, searchText, resetStorage }) {
      resetStorage = resetStorage || false;
      // If there is an already going request, drop it before continuing.
      // So that it wont replace the data once it resolved.
      if (!_.isEmpty($scope.mainModel.deferedRequestAbort)) {
        $scope.mainModel.deferedRequestAbort.abortRequest('User cancelled');
      }

      $scope.mainModel.folder_id = folderId;

      if ($scope.mainModel.busy && !$scope.mainModel.initialLoad) {
        return;
      }
      $scope.mainModel.busy = true;
      $scope.mainModel.initialLoad = false;
      if (resetStorage) {
        $scope.filePicker.selectedFile = {};
        $scope.mainModel.breadcrumbs = [];
        $scope.mainModel.groupfiles = [];
        $scope.pageNumber = 1;
      }

      // Make sure this is empty when we are loading new items.
      $scope.mainModel.selectedFiles = [];
      const url = cdApp.config.api.main + '/filesystem/v2';
      const params = {
        page: $scope.pageNumber,
        orderBy: $scope.mainModel.orderBy,
        orderDirection: $scope.mainModel.orderByDirection,
        limit: 50,
      };

      if ($scope.groupId) {
        params.groupId = $scope.groupId;
      }
      if (folderId !== -1) {
        params.folderId = folderId;
      }
      if (!angular.isUndefined(searchText) && searchText != '') {
        params.search = searchText;
      }

      // Load a small part of the data here, for faster loading.
      Promise.all([
        filesFactory.http.httpGET(url, params).then(({ data }) => {
          // While searching if the user deletes the search text then we replace
          // the old data back. Then we should prevent this promise from
          // overwriting it (if it already fired before the text gets emptied).
          if (!$scope.mainModel.stopreplace) {
            $scope.mainModel.totalCount = data.count;

            if (!resetStorage) {
              $scope.mainModel.groupfiles = _.concat(
                $scope.mainModel.groupfiles,
                data.items
              );
            } else {
              $scope.mainModel.groupfiles = data.items;
            }
            // Update the scrollbar
            $timeout(function () {
              angular.element('.browse-files-scroll').scrollTop(0);
              angular
                .element('.browse-files-scroll')
                .perfectScrollbar('update');
            }, 100);
          }
          $scope.mainModel.busy = false;
        }),
        new Promise(async (resolve) => {
          if (folderId == -1 || folderId === 'root' || !folderId) {
            $scope.mainModel.breadcrumbs = [];
            return resolve();
          }

          const breadcrumbsData =
            await filesFactory.http.generateBreadcrumb(folderId);

          $scope.mainModel.breadcrumbs = _.map(
            breadcrumbsData,
            function (d, i) {
              return {
                id: d.id,
                title: d.title,
                groupId: d.groupId,
              };
            }
          );

          var index = -1;
          _.each($scope.mainModel.breadcrumbs, function (obj, i) {
            if (obj.id == folderId) {
              index = i;
            }
          });
          $scope.mainModel.breadcrumbs.splice(
            index + 1,
            $scope.mainModel.breadcrumbs.length - index - 1
          );
          const lastEntryInBreadCrumb = _.last($scope.mainModel.breadcrumbs);
          $scope.mainModel.currentFolderId = folderId;
          $scope.mainModel.currentFolderGroup = lastEntryInBreadCrumb?.groupId;
          resolve();
        }),
      ]).catch((data) => {
        if (_.isArray(data)) {
          toastr.error(
            _.get(data, 'message') ||
              gettextCatalog.getString(
                'An error occurred, please try again. If the problem persists, please contact our support.'
              )
          );
        }
        $scope.mainModel.busy = false;
      });
    };

    // Get All tags.
    filesFactory.http
      .httpGET(cdApp.config.api.main + '/files/tags')
      .success(function (data) {
        var filetags = [];
        for (var i = 0; i < data.length; i++) {
          filetags.push(data[i]);
        }

        $scope.select2Options = {
          tags: filetags,
        };
      })
      .error(function () {});

    //Count tags
    $scope.tagsCount = function (tags) {
      return _.size(tags);
    };

    // Concatenate tags to string
    $scope.getTaxonomyHtml = function (tags) {
      return (tags || []).join(', ');
    };

    /**
     * Get files folders for a specific folder.
     * @param item
     * @param event
     */
    $scope.getInFolderFilesFolders = function (item) {
      // Handle the breadcrumbs.
      $scope.handleBreadCrumb(item.id);

      // Remove search string.
      $scope.mainModel.search = '';
    };

    /**
     * Get the formatted date format.
     * @param unix timestamp
     */
    $scope.formatCreatedDate = function (unix) {
      return moment(unix * 1000).format('lll');
    };

    /**
     * Browse files folders from the breadcrumb.
     * @param folderid
     * @param group
     */
    $scope.handleBreadCrumb = function (folderId) {
      $scope.getFilesFolders({
        folderId,
        searchText: '',
        resetStorage: true,
      });
    };

    // Watch the search box and do either live search or backend based on users
    // choice.
    $scope.$watch(
      'mainModel.search',
      _.debounce(function (newValue, oldValue) {
        // load only files in the root directory at the beginning
        if (_.isNil(newValue) || _.isEmpty(newValue)) {
          $scope.mainModel.isGlobalSearch = false;
        }

        $timeout(function () {
          if (
            $scope.mainModel.isGlobalSearch ||
            !$scope.mainModel.currentFolderId
          ) {
            // Search globally
            $scope.getFilesFolders({
              folderId: -1,
              searchText: newValue,
              resetStorage: true,
            });
          } else {
            // Search locally
            $scope.getFilesFolders({
              folderId: $scope.mainModel.currentFolderId,
              searchText: newValue,
              resetStorage: true,
            });
          }
        });
      }, 400)
    );

    // Search setting.
    $scope.searchSetting = function (isGlobal) {
      if (isGlobal && !$scope.mainModel.isGlobalSearch) {
        $scope.getFilesFolders({
          folderId: -1,
          searchText: $scope.mainModel.search,
          resetStorage: true,
        });
      } else if (!isGlobal && $scope.mainModel.isGlobalSearch) {
        var searchFolderId = 'root';
        if ($scope.mainModel.currentFolderId !== -1) {
          searchFolderId = $scope.mainModel.currentFolderId;
        }
        $scope.getFilesFolders({
          folderId: searchFolderId,
          searchText: $scope.mainModel.search,
          resetStorage: true,
        });
      }
      $scope.mainModel.isGlobalSearch = isGlobal;
      $scope.mainModel.previousIsGlobal = $scope.mainModel.isGlobalSearch;
    };

    /**
     * Folder creation modal.
     */
    $scope.createFolder = function () {
      $uibModal
        .open({
          component: 'cdCreateFolderModal',
          resolve: {
            groups: () => $scope.groups,
            groupId: () => $scope.mainModel.currentFolderGroup,
            parentFolderId: () => $scope.mainModel.folder_id,
          },
        })
        .result.then((newFolder) => {
          $scope.mainModel.groupfiles.push(newFolder);
          $scope.mainModel.empty = false;
          toastr.success(
            gettextCatalog.getString('"{{title}}" has been created.', {
              title: newFolder.title,
            })
          );
        });
    };

    /**
     * Delete multiple files or folders
     */
    $scope.deleteMultipleFiles = function () {
      var modalDeleteMultipleFilesInstance = $uibModal.open({
        templateUrl:
          '@/app/intranet/files/templates/modals/ModalDeleteMultipleFiles.html',
        scope: $scope,
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            'ngInject';

            $scope.ok = function () {
              $scope.processing = true;
              var allowed_file_list_nid = {},
                allowed_folder_list_nid = {},
                denied_files = 0,
                allowed_file_list = {},
                allowed_folder_list = {};
              angular.forEach($scope.mainModel.selectedFiles, function (item) {
                if (item.permission && item.permission.canDelete) {
                  if (item.type == 'file') {
                    allowed_file_list_nid[item.id] = {
                      id: item.id,
                      group: item.groupId,
                    };

                    allowed_file_list[item.id] = item;
                  } else {
                    allowed_folder_list_nid[item.id] = {
                      id: item.id,
                      group: item.groupId,
                    };

                    allowed_folder_list[item.id] = item;
                  }
                } else {
                  denied_files++;
                }
              });

              if (!_.isEmpty(allowed_file_list)) {
                var deletedFiles = {
                  gid: $scope.groupdId,
                  folder_id: $scope.mainModel.folder_id,
                  allowed_file_list: angular.toJson(allowed_file_list_nid),
                };

                var url = cdApp.config.api.main + '/files/multidelete';
                $scope.deleteItems(
                  url,
                  deletedFiles,
                  allowed_file_list,
                  denied_files,
                  $uibModalInstance
                );
              }

              if (!_.isEmpty(allowed_folder_list)) {
                var deletedFolders = {
                  gid: $scope.groupdId,
                  folder_id: $scope.mainModel.folder_id,
                  allowed_folder_list: angular.toJson(allowed_folder_list_nid),
                };

                url = cdApp.config.api.main + '/folders/multidelete';
                $scope.deleteItems(
                  url,
                  deletedFolders,
                  allowed_folder_list,
                  denied_files,
                  $uibModalInstance
                );
              }

              if (
                _.isEmpty(allowed_file_list) &&
                _.isEmpty(allowed_folder_list)
              ) {
                var msg = '';
                if (denied_files > 0) {
                  msg = gettextCatalog.getString(
                    'You do not have permission to delete files and folders.'
                  );
                } else {
                  msg = gettextCatalog.getString(
                    'You need to select at least one file or folder.'
                  );
                }
                toastr.warning(msg);
                $uibModalInstance.dismiss('cancel');

                // Make sure we uncheck everything incase there are undeleted
                // remains.
                _.each($scope.mainModel.selectedFiles, function (item) {
                  item.selected = false;
                });
                $scope.mainModel.selectedFiles = [];
              }
            };

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],
      });
    };

    /**
     * Helper function for multi deleting files or folders
     * @param url
     * @param itemidstodelete
     * @param itemstodelete
     * @param denied_files
     * @param $uibModalInstance
     */
    $scope.deleteItems = function (
      url,
      itemidstodelete,
      itemstodelete,
      denied_files,
      $uibModalInstance
    ) {
      filesFactory.http
        .deleteMultiple(url, itemidstodelete)
        .success(function (response) {
          angular.forEach(response, function (id, key) {
            var index_primary = $scope.mainModel.groupfiles.indexOf(
              itemstodelete[id]
            );

            var index_secondary = $scope.mainModel.selectedFiles.indexOf(
              itemstodelete[id]
            );

            $scope.mainModel.groupfiles.splice(index_primary, 1);
            $scope.mainModel.selectedFiles.splice(index_secondary, 1);
          });

          if (response.length != 0) {
            toastr.success(
              response.length +
                ' ' +
                gettextCatalog.getString('files and folders have been deleted.')
            );
          } else if (denied_files > 0) {
            toastr.warning(
              denied_files +
                ' ' +
                gettextCatalog.getString(
                  'files or folders have not been deleted since you do not have enough permission to do that.'
                )
            );
          } else {
            toastr.error(
              gettextCatalog.getString('No files or folders have been deleted.')
            );
          }

          if ($scope.mainModel.groupfiles.length == 0) {
            $scope.mainModel.empty = true;
          }

          if (angular.isDefined($uibModalInstance)) {
            $uibModalInstance.dismiss('cancel');
          }

          // Make sure we uncheck everything incase there are undeleted
          // remains.
          _.each($scope.mainModel.selectedFiles, function (item) {
            item.selected = false;
          });
          $scope.mainModel.selectedFiles = [];
        })
        .error(function (data) {
          toastr.error(
            _.get(data, 'message') ||
              gettextCatalog.getString(
                'An error occurred, please try again. If the problem persists, please contact our support.'
              )
          );

          if (angular.isDefined($uibModalInstance)) {
            $uibModalInstance.dismiss('cancel');
          }

          // Make sure we uncheck everything incase there are undeleted
          // remains.
          _.each($scope.mainModel.selectedFiles, function (item) {
            item.selected = false;
          });
          $scope.mainModel.selectedFiles = [];
        });
    };

    const stripHtml = (html) => {
      // Create a new div element
      var temporalDivElement = document.createElement('div');
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = html;
      // Retrieve the text property of the element (cross-browser support)
      return (
        temporalDivElement.textContent ||
        temporalDivElement.innerText ||
        ''
      ).trim();
    };

    /**
     * Edit file or folder
     * @param item
     * @param event
     */
    $scope.edit = function (item, event) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      const index = $scope.mainModel.groupfiles.indexOf(item);
      const visibility = item.visibility || 0;
      const tags = item.type === 'file' ? item.tags : '';

      $uibModal.open({
        templateUrl:
          '@/app/intranet/files/templates/modals/ModalEditFormModal.html',
        scope: $scope,
        resolve: {
          data() {
            return {
              type: item.type,
              title: item.title,
              visibility: item.visibility || null,
              tags: tags,
              fileInfo: item.fileInfo,
              credits: item.credits,
            };
          },
        },

        windowClass: 'modal-ui-select',
        controller: [
          '$scope',
          '$uibModalInstance',
          'data',
          'Analytics',
          '_',
          function ($scope, $uibModalInstance, data, Analytics, _) {
            'ngInject';

            $scope.type = data.type;

            $scope.item = {
              title: data.title,
            };

            if (data.type == 'file') {
              $scope.item.tags = data.tags;
              $scope.item.visibility =
                $scope.visibilityOptions[
                  getKey($scope.visibilityOptions, data.visibility)
                ];

              $scope.item.fileInfo = data.fileInfo;
              $scope.item.credits = data.credits;
            }

            $scope.save = () => {
              $scope.processing = true;

              const payload = {
                title: $scope.item.title,
              };

              let apiUrl = cdApp.config.api.main;

              if (item.type === 'folder') {
                apiUrl += `/folders/${item.id}`;
              } else if (item.type === 'file') {
                apiUrl += `/files/${item.id}`;
                payload.tags = $scope.item.tags;
                payload.visibility = $scope.item.visibility.value;
                payload.credits = stripHtml($scope.item.credits);
              }

              filesFactory.http
                .update(apiUrl, payload)
                .success((response) => {
                  $scope.mainModel.groupfiles[index].title = $scope.item.title;
                  $scope.mainModel.groupfiles[index].credits =
                    $scope.item.credits;
                  $scope.mainModel.groupfiles[index].updatedAt =
                    new Date().toISOString();

                  if (item.type == 'file') {
                    $scope.mainModel.groupfiles[index].tags = $scope.item.tags;
                    $scope.select2Options.tags = _.union(
                      $scope.select2Options.tags,
                      $scope.item.tags
                    );

                    $scope.mainModel.groupfiles[index].visibility =
                      $scope.item.visibility.value;
                  }

                  $uibModalInstance.close();
                  toastr.success(
                    gettextCatalog.getString('"{{title}}" has been updated.', {
                      title: data.title,
                    })
                  );
                })
                .error((response) => {
                  toastr.error(
                    response.message ||
                      gettextCatalog.getString('Problem updating.')
                  );
                });

              $scope.mainModel.isfilesfoldersselected = false;
            };

            $scope.cancel = () => {
              $uibModalInstance.dismiss('cancel');
              $scope.mainModel.isfilesfoldersselected = false;
            };
          },
        ],
      });
    };

    /**
     * Delete a file or a folder
     * @param item
     * @param event
     */
    $scope.delete = function (item, event) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      var index = $scope.mainModel.groupfiles.indexOf(item);
      var modalDeleteFormInstance = $uibModal.open({
        templateUrl:
          '@/app/intranet/files/templates/modals/ModalDeleteItem.html',
        scope: $scope,
        controller: [
          '$scope',
          '$uibModalInstance',
          'type',
          'title',
          function ($scope, $uibModalInstance, type, title) {
            'ngInject';

            $scope.type = type;
            $scope.title = title;
            $scope.ok = function () {
              $scope.processing = true;
              var url = cdApp.config.api.main;

              if (item.type == 'file') {
                url += '/files/' + item.id;
              } else {
                url += '/folders/' + item.id;
              }

              filesFactory.http
                .delete(url)
                .success(function (data) {
                  if (item.type == 'folder' && data[0] == false) {
                    // this folder or a folder/file inside it is not owned by this user
                    $uibModalInstance.dismiss('cancel');
                    toastr.warning(
                      gettextCatalog.getString(
                        'You do not own this folder, or a file/folder inside of it. Please contact your administrator.'
                      )
                    );
                  } else {
                    $scope.mainModel.groupfiles.splice(index, 1);
                    $uibModalInstance.dismiss('cancel');
                    toastr.success(
                      gettextCatalog.getString(
                        '"{{title}}" has been deleted.',
                        {
                          title: $scope.title,
                        }
                      )
                    );
                  }
                  if ($scope.mainModel.groupfiles.length == 0) {
                    $scope.mainModel.empty = true;
                  }
                })
                .error(function (data) {
                  toastr.error(
                    _.get(data, 'message') ||
                      gettextCatalog.getString(
                        'An error occurred, please try again. If the problem persists, please contact our support.'
                      )
                  );

                  $uibModalInstance.dismiss('cancel');
                });
              $scope.mainModel.isfilesfoldersselected = false;
            };

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
              $scope.mainModel.isfilesfoldersselected = false;
            };
          },
        ],

        resolve: {
          type: function () {
            return item.type;
          },
          title: function () {
            return item.title;
          },
        },
      });
    };

    /**
     * File uploader
     */
    $scope.handleFileUploadss = function () {
      const isSelectedGroupPartOfMyGroups =
        $scope.groups[
          getKey($scope.groups, $scope.mainModel.currentFolderGroup)
        ];
      if (
        !isSelectedGroupPartOfMyGroups &&
        $scope.mainModel.breadcrumbs.length !== 0
      ) {
        toastr.warning(
          gettextCatalog.getString(
            'To create content within this folder, you need to be a member of the group, this folder relates to.'
          )
        );

        return;
      }

      if (_.isEmpty($scope.groups)) {
        toastr.warning(
          gettextCatalog.getString(
            'You must be a member of at least one group to upload files.'
          )
        );

        return;
      }

      $uibModal
        .open({
          component: 'cdFileUploaderModal',
          windowClass: 'cd-large-modal',
          backdropClass: 'cd-large-modal-backdrop',
          animation: false,
          resolve: {
            groups: () => $scope.groups,
            groupId: () => $scope.mainModel.currentFolderGroup,
            folderId: () => $scope.mainModel.folder_id,
            permissions: () => $scope.userPermissionTable,
            validators: () => $scope.validators,
          },
        })
        .result.then((newFiles) => {
          $scope.getFilesFolders({
            folderId: $scope.mainModel.folder_id,
            searchText:
              !$scope.mainModel.search || mainModel.search.length === 0
                ? ''
                : $scope.mainModel.search,
            resetStorage: true,
          });
        });
    };

    /**
     * Move files or folders with drag and drop
     * @param eDraggables
     * @param eDroppable
     */
    $scope.handleDrop = function (eDraggables, eDroppable) {
      var items = {},
        hoverClass;
      var move_to;
      var sTarget = eDroppable.data('model');

      var isDropForbidden = function (aTarget, eDroppable) {
        return (
          aTarget.type == 'file' ||
          eDroppable.hasClass('draggable') ||
          eDroppable.hasClass('activeBreadCrumb')
        );
      };

      if (_.has(sTarget, 'id') && !_.has(sTarget, 'move_type')) {
        hoverClass = sTarget.type == 'file' ? 'errorDrop' : 'activeDrop';
        move_to = 'folder';
      } else if (_.has(sTarget, 'move_type') && sTarget.move_type == 'group') {
        hoverClass = 'activeDrop';
        move_to = 'group';
      } else {
        hoverClass = 'activeDrop';
        move_to = 'home';
      }

      _.each(eDraggables, function (eDraggable) {
        var index = angular.element(eDraggable).data('index');
        var sSrc = angular.element(eDraggable).data('model');
        items[index] = { id: sSrc.id, type: sSrc.type };
      });

      var itemstomove = {
        gid: $scope.groupId,
        move_to_type: move_to,
        move_from: $scope.mainModel.folder_id,
        item_to_move: items,
      };

      var error = isDropForbidden(sTarget, eDroppable);
      if (error && !_.isEmpty(items)) {
        eDroppable.removeClass(hoverClass);
        _.each($scope.mainModel.selectedFiles, function (item) {
          item.selected = false;
        });
        $scope.mainModel.selectedFiles = [];
      } else {
        var targettitle = sTarget.title
          ? ' ' + gettextCatalog.getString('Folder') + ' ' + sTarget.title
          : gettextCatalog.getString('Group');
        if (itemstomove.move_to_type !== 'home') {
          var move_to_id = move_to == 'folder' ? sTarget.id : $scope.groupId;
          // var url = '/api/folder/' + move_to_id + '/move';
          var url =
            cdApp.config.api.main + '/filesystem/move?moveToId=' + move_to_id;
          $scope.moveFilesFolders(url, itemstomove, targettitle);
        } else {
          // If we are moving to the home, ask the user to which group
          // the files and folders should be moved.
          var modalSelectGroup = $uibModal.open({
            scope: $scope,
            templateUrl: 'modalGroupSelect.html',
            controller: [
              '$scope',
              '$uibModalInstance',
              'data',
              function ($scope, $uibModalInstance, data) {
                'ngInject';

                $scope.selected = { group: null };
                $scope.inputerror = false;
                $scope.processing = false;
                $scope.cancel = function () {
                  $uibModalInstance.dismiss('cancel');
                };

                $scope.moveToGroup = function () {
                  data.tomove.move_to_type = 'group';
                  var group = $scope.selected.group;
                  if (!_.isEmpty($scope.selected.group)) {
                    $scope.processing = true;
                    var url =
                      cdApp.config.api.main +
                      '/filesystem/move?moveToId=' +
                      $scope.selected.group.value;
                    // var url = '/api/folder/' + $scope.selected.group.value + '/move';
                    $scope.moveFilesFolders(
                      url,
                      data.tomove,
                      data.targettitle,
                      $uibModalInstance
                    );
                  } else {
                    $scope.inputerror = true;
                  }
                };
              },
            ],

            resolve: {
              data: function () {
                return {
                  tomove: itemstomove,
                  targettitle: targettitle,
                };
              },
            },
          });
        }

        eDroppable.removeClass(hoverClass);
      }
    };

    /**
     * Helper function for moving files and folders
     * @param url
     * @param modifiedData
     * @param target
     * @param $uibModalInstance
     */
    $scope.moveFilesFolders = function (
      url,
      modifiedData,
      target,
      $uibModalInstance
    ) {
      filesFactory.http
        .move(url, modifiedData)
        .success(function (data) {
          var temp = $scope.mainModel.groupfiles;
          var accessDenied = false;
          var movedSome = false;
          var deniedItems = '';
          var movedItems = '';
          _.each(data, function (obj) {
            if (obj.success) {
              temp = _.filter(temp, function (item) {
                return item.id != obj.id;
              });
              movedSome = true;
              movedItems += obj.title + ', ';
            } else {
              accessDenied = true;
              deniedItems += obj.title + ', ';
            }
          });

          if (_.isEmpty(temp)) {
            $scope.mainModel.empty = true;
          }

          $scope.mainModel.groupfiles = temp;
          if (movedSome) {
            toastr.success(
              gettextCatalog.getString(
                'Successfully moved {{moveditems}} to {{target}}.',
                {
                  moveditems: movedItems,
                  target: target,
                }
              )
            );
          }
          if (accessDenied) {
            toastr.warning(
              gettextCatalog.getString(
                'You do not have permission to move those files.'
              )
            );
          }
          if (angular.isDefined($uibModalInstance)) {
            $uibModalInstance.dismiss('cancel');
          }
          $scope.mainModel.selectedFiles = [];
        })
        .error(function (data) {
          toastr.error(
            _.get(data, 'message') ||
              gettextCatalog.getString(
                'An error occurred, please try again. If the problem persists, please contact our support.'
              )
          );

          if (angular.isDefined($uibModalInstance)) {
            $uibModalInstance.dismiss('cancel');
          }
          $scope.mainModel.selectedFiles = [];
        });
    };

    // Viewing files when called from the dashboard or group overview.
    if (
      angular.isDefined($stateParams.id) &&
      angular.isDefined($stateParams.title)
    ) {
      $state.go('app.private.intranet.files.view', { id: item.id });
    }

    /**
     * Move files or folder to another folder
     */
    $scope.move = function () {
      $uibModal
        .open({
          component: 'cdSelectFolderModal',
          windowClass: 'modal-scrollable',
          resolve: {
            folders: () =>
              _.filter($scope.mainModel.groupfiles, { type: 'folder' }),
            groups: () => $scope.groups,
            showFolderGroup: () => true,
          },
        })
        .result.then((folder) => {
          const items = [];
          _.each($scope.mainModel.selectedFiles, (item, index) =>
            items.push({ id: item.id, type: item.type })
          );

          const modifiedData = {
            gid: $scope.groupId,
            move_to_type: 'folder',
            move_from: $scope.mainModel.folder_id,
            item_to_move: items,
          };

          const url = `${cdApp.config.api.main}/filesystem/move?moveToId=${folder.id}`;
          if (!_.isEmpty(items)) {
            $scope.processing = true;
            $scope.moveFilesFolders(url, modifiedData, folder.title);
          }
        });
    };

    /**
     * Easier way for users to download files.
     *
     *  Instead of opening a modal to download a file, users can now slect
     *  the file and the download it.
     * @returns {*}
     */
    $scope.getdownloadlink = function () {
      if (!_.isEmpty($scope.mainModel.selectedFiles)) {
        var link =
          cdApp.config.api.main +
          '/files/' +
          $scope.mainModel.selectedFiles[0].id +
          '/download?access_token=' +
          AuthenticationService.getAccessToken();
        link += '&organizationId=' + $window.churchdeskOrganizationId;
        return $sce.trustAsResourceUrl(link);
      }
    };

    //User access check
    $scope.user_access = function (op, type, node) {
      //User permissions
      var userPerm = $scope.userPermissionTable;
      return userAccess.user_access(op, type, node, userPerm);
    };

    // Check if the user has access to change visibility of the node
    $scope.visibility_access = function () {
      //User permissions
      var userPerm = $scope.userPermissionTable;
      return userAccess.visibility_access(userPerm);
    };

    $scope.filePickerSearch = function () {
      angular.element('.browse-files-scroll').scrollTop(0);
    };

    // Check selected items type (file/folder/both)
    $scope.isType = function (type) {
      if (_.isArray(type)) {
        return _.every(type, $scope.isType);
      }
      return _.some($scope.mainModel.selectedFiles, function (item) {
        return item.type === type;
      });
    };

    /**
     * Return the URL to the file view modal, which is different if we're in a group
     *
     * @param {Number} fileId
     * @param {Number} groupId
     */
    $scope.getFileViewPath = (fileId, groupId) => {
      const stateParams = { id: fileId };
      const stateName = !groupId
        ? 'app.private.intranet.files.view'
        : 'app.private.intranet.group.files.view';

      if (groupId) {
        stateParams.gid = groupId;
      }

      return $state.href(stateName, stateParams);
    };

    /**
     * Callback function called when a file has been successfully uploaded
     *
     * @param {Object} file
     */
    $scope.onFileUploaded = (file) => {
      $scope.mainModel.groupfiles.push(file);
    };
  }

  FilesController.$inject = [
    'moment',
    '_',
    '$scope',
    '$rootScope',
    'AuthenticationService',
    'filesFactory',
    '$uibModal',
    '$stateParams',
    '$timeout',
    '$sce',
    '$window',
    '$state',
    'gettextCatalog',
    'userAccess',
    'toastr',
    'Me',
  ];

  angular
    .module('cdApp.intranet')
    .controller('FilesController', FilesController)
    .factory('filesFactory', [
      '$http',
      '$q',
      function ($http, $q) {
        var dataFactory = {};

        var defereRequestAbort = function (url, params) {
          var cancelrequest = $q.defer();

          var abortRequest = function (reason) {
            cancelrequest.resolve(reason);
          };

          var promise = $http
            .get(url, { timeout: cancelrequest.promise, params })
            .then(function (response) {
              return response.data;
            });

          return {
            promise: promise,
            abortRequest: abortRequest,
          };
        };

        dataFactory.createFolder = function (url, folderData) {
          return $http({
            method: 'POST',
            url: url,
            data: $.param(folderData),
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
        };

        dataFactory.delete = function (url) {
          return $http({
            method: 'DELETE',
            url: url,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
        };

        dataFactory.deleteMultiple = function (url, fileList) {
          return $http({
            method: 'POST',
            url: url,
            data: $.param(fileList),
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
        };

        dataFactory.update = function (url, modifiedData) {
          return $http({
            method: 'PUT',
            url: url,
            data: $.param({ ...modifiedData, tags: modifiedData.tags || [] }),
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
        };

        dataFactory.move = function (url, modifiedData) {
          return $http({
            method: 'POST',
            url: url,
            data: modifiedData,
          });
        };

        dataFactory.generateBreadcrumb = function (folder_id) {
          var defer = $q.defer();
          var url = cdApp.config.api.main + '/filesystem/breadcrumb';
          const params = { folderId: folder_id };
          $http
            .get(url, { params })
            .success(function (res) {
              defer.resolve(res);
            })
            .error(function (err) {
              defer.reject(err);
            });

          return defer.promise;
        };

        // Use this for all get requests.
        dataFactory.httpGET = function (url, params) {
          return $http({
            method: 'GET',
            url: url,
            params,
          });
        };

        // Use this for all post request
        dataFactory.httpPOST = function (url, data) {
          return $http({
            method: 'POST',
            url: url,
            data,
          });
        };

        return {
          http: dataFactory,
          deferedRequest: defereRequestAbort,
        };
      },
    ])
    .directive('groupTableColspan', function () {
      return function (scope, element, attrs) {
        var colspan = 7;
        if (attrs.groupTableColspan === -1) {
          colspan = 8;
        }
        element.attr('colspan', colspan);
      };
    });
})();
