import getTextCatalog from '@/react/services/I18nService';
export const TIME_OPTIONS = () => [
  {
    value: 5,
    label: getTextCatalog.getString('5 minutes'),
  },
  {
    value: 10,
    label: getTextCatalog.getString('10 minutes'),
  },
  {
    value: 15,
    label: getTextCatalog.getString('15 minutes'),
  },
  {
    value: 20,
    label: getTextCatalog.getString('20 minutes'),
  },
  {
    value: 30,
    label: getTextCatalog.getString('30 minutes'),
  },
  {
    value: 45,
    label: getTextCatalog.getString('45 minutes'),
  },
  {
    value: 60,
    label: getTextCatalog.getString('1 hour'),
  },
  {
    value: 90,
    label: getTextCatalog.getString('1,5 hours'),
  },
  {
    value: 120,
    label: getTextCatalog.getString('2 hours'),
  },
  {
    value: 180,
    label: getTextCatalog.getString('3 hours'),
  },
];
