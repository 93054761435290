import React from 'react';
import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import getTextCatalog from '../../services/I18nService';
import { PortalCategory } from '../types/portal.type';
import useEditPortalCategory from '../hooks/use-edit-portal-category';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';

export const EditPortalCategoryModal = createCdModal<{
  category: PortalCategory;
  onFinish: any;
}>({
  modalName: 'EditPortalCategoryModal',
  InnerModal: ({ setModalProps, category, onFinish }) => {
    const { form } = useEditPortalCategory(
      setModalProps,
      category.id,
      onFinish
    );

    return (
      <Form name="editPortalCategory" form={form} initialValues={category}>
        <Form.Item
          label={getTextCatalog.getString('Name')}
          name="name"
          rules={[
            {
              required: true,
              message: getTextCatalog.getString(
                'Please input the category name!'
              ),
            },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={getTextCatalog.getString('Description')}
          name="description"
          extra={getTextCatalog.getString('Maximum 255 characters')}
          rules={[
            {
              max: 255,
              message: getTextCatalog.getString('Maximum characters reached!'),
            },
          ]}
          labelCol={{ span: 6 }}
        >
          <TextArea />
        </Form.Item>
      </Form>
    );
  },
});
