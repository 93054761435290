import { mainApi } from '../../api';
import { User, CreateUserBody } from '../types/User.types';

class UserService {
  public getUsers = async (): Promise<User[]> => {
    const response = await mainApi.get('/users');
    if (response.ok) return response.data as User[];
    throw response.data;
  };

  public createUser = async (data: CreateUserBody): Promise<{ id: number }> => {
    const response = await mainApi.post('/users/', data);
    if (response.ok) return response.data as { id: number };
    throw response.data;
  };

  /**
   * Generic function to retrieve a download token for fetching files from the backend.
   * @param identifier {string} the type of the file you want a download token for.
   * @returns a token
   */
  public getDownloadToken = async (
    identifier: 'contributions'
  ): Promise<string> => {
    const response = await mainApi.get<{ token: string }>(
      '/login/generate-download-token',
      {
        identifier,
      }
    );
    if (response.ok) return response.data.token;
    throw response.data;
  };
}

export default new UserService();
