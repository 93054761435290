import angular from 'angular';

angular.module('cdApp.settings').run([
  '$templateCache',
  ($templateCache) => {
    $templateCache.put(
      '@/app/settings/account-settings/account-settings.component.html',
      require('./account-settings/account-settings.component.html')
    );

    $templateCache.put(
      '@/app/settings/legal-settings/legal-settings.component.html',
      require('./legal-settings/legal-settings.component.html')
    );

    $templateCache.put(
      '@/app/settings/other-settings/other-settings.component.html',
      require('./other-settings/other-settings.component.html')
    );

    $templateCache.put(
      '@/app/settings/people-settings/people-settings.component.html',
      require('./people-settings/people-settings.component.html')
    );

    $templateCache.put(
      '@/app/settings/settings.component.html',
      require('./settings.component.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EditAccount.html',
      require('./users-settings/shared/partials/EditAccount.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EditAdmin.html',
      require('./users-settings/shared/partials/EditAdmin.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EditGraveyard.html',
      require('./users-settings/shared/partials/EditGraveyard.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EditBasic.html',
      require('./users-settings/shared/partials/EditBasic.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EditContact.html',
      require('./users-settings/shared/partials/EditContact.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EditNotifications.html',
      require('./users-settings/shared/partials/EditNotifications.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EntityCreate.html',
      require('./users-settings/shared/partials/EntityCreate.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EntityMembershipActive.html',
      require('./users-settings/shared/partials/EntityMembershipActive.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EntityMembershipPending.html',
      require('./users-settings/shared/partials/EntityMembershipPending.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/partials/EntityUpdate.html',
      require('./users-settings/shared/partials/EntityUpdate.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/templates/send-message.modal.html',
      require('./users-settings/shared/templates/send-message.modal.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/shared/templates/toggle-status.modal.html',
      require('./users-settings/shared/templates/toggle-status.modal.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/user-detail/user-detail.component.html',
      require('./users-settings/user-detail/user-detail.component.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/user-edit/user-edit.component.html',
      require('./users-settings/user-edit/user-edit.component.html')
    );

    $templateCache.put(
      '@/app/settings/users-settings/user-list/user-list.component.html',
      require('./users-settings/user-list/user-list.component.html')
    );

    $templateCache.put(
      '@/app/settings/widgets-settings/widgets-settings.component.html',
      require('./widgets-settings/widgets-settings.component.html')
    );

    $templateCache.put(
      '@/app/settings/mvr-form/mvr-form.component.html',
      require('./mvr-form/mvr-form.component.html')
    );

    $templateCache.put(
      '@/app/settings/report-templates/template-placeholder-definitions-modal.html',
      require('./report-templates/template-placeholder-definitions-modal.html')
    );
  },
]);
