import { react2angular } from 'react18-react2angular';
import angular from 'angular';

import CreateUserModal from '../../../../../react/user/components/CreateUserModal';
import sharedContext from '../../../../shared/components/shared-context-root/shared-context-root.component';

// Example: <cdr-create-user-modal />
angular
  .module('cdApp.settings')
  .component(
    'cdrCreateUserModal',
    react2angular(
      sharedContext.use(CreateUserModal),
      ['launchNewGroupModal', 'closeModal', 'userInfo'],
      []
    )
  );
