import { Button, Space, Tag } from 'antd';
import { useState, useEffect, Suspense } from 'react';
import React from 'react';
import moment from 'moment';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import NiceModal from '@ebay/nice-modal-react';
import { curry } from 'lodash';
import { css } from 'styled-components';

import useCdForm from '../../hooks/useCdForm';

import FormsFilter from './FormListFilter';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import {
  ActionMenuDivider,
  ActionMenuItem,
  ActionMenuType,
  ColumnType,
} from '@/react/shared/components/cd-table';
import {
  CdCheckIcon,
  CdClose,
  CdCopyIcon,
  CdDeleteIcon,
  CdEditIcon,
  CdEyeIcon,
  CdForm,
} from '@/react/shared/components/Icons';
import {
  CdTabs,
  CdTabPaneTab,
} from '@/react/shared/components/tab-container/TabContainer';
import getTextCatalog from '@/react/services/I18nService';
import { getStateUrl, navigate } from '@/react/services/StateServiceFactory';
import CdQueryTable from '@/react/shared/components/cd-query-table/CdQueryTable';
import { SelectedOrganizationChurches } from '@/react/organization/store/organization';
import {
  FormLabels,
  FormListSearch,
  FormListSearchParamsAtom,
} from '@/react/forms/store/form';
import { FormFromSearch } from '@/react/forms/types';
import { HasMultipleChurches } from '@/react/user/store/user-session';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

const navigateFp: (arg: any) => () => void = curry(navigate, 2);
const mapStatus = (string) => {
  switch (string) {
    case 'max-submissions-reached':
      return getTextCatalog.getString('Limit reached');
      break;
    case 'sold-out':
      return getTextCatalog.getString('Sold out');
      break;
    case 'closed':
      return getTextCatalog.getString('Closed');
      break;
    case 'charging-disabled':
      return getTextCatalog.getString('Charging disabled');
      break;
    case 'open':
      return getTextCatalog.getString('Open');
      break;
    case 'expired':
      return getTextCatalog.getString('Expired');
      break;
    default:
      return string;
  }
};

const statusToClassname = ({ status }: any) => {
  switch (status) {
    case 'max-submissions-reached':
    case 'sold-out':
    case 'expired':
      return 'highlight';
      break;
    default:
      return null;
  }
};

const rowStyles = css`
  .highlight {
    background-color: ${({ theme }) => theme.colors.highlight};
  }
`;

function FormListTable() {
  const { duplicate, close, open, deleteForm } = useCdForm();
  const isMultiParish = useRecoilValue(HasMultipleChurches);

  const formColums = (): ColumnType<FormFromSearch>[] => [
    {
      title: getTextCatalog.getString('Title'),
      dataIndex: 'title',
      sorter: true,
      render: (title, form) => (
        <a href={getStateUrl('app.private.forms.view', form)}>{title}</a>
      ),
    },
    {
      title: getTextCatalog.getString('Parishes'),
      hidden: !isMultiParish,
      dataIndex: ['churches'],
      render: (churches) =>
        churches.map(({ id, name }) => <Tag key={id}>{name}</Tag>),
    },
    {
      title: getTextCatalog.getString('Label'),
      dataIndex: 'label',
      render: (label, cdForm) => {
        if (!label) {
          return (
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => NiceModal.show('AssignLabel', { cdForm })}
            >
              <Space>{getTextCatalog.getString('Add label')}</Space>
            </Button>
          );
        }
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => NiceModal.show('AssignLabel', { cdForm })}
          >
            {label}
          </Button>
        );
      },
    },
    {
      title: getTextCatalog.getString('Responses'),
      dataIndex: 'responseCount',
      sorter: true,
    },
    {
      title: getTextCatalog.getString('Created at'),
      dataIndex: 'createdAt',
      render: (dateString) => moment(dateString).format('LLL'),
      sorter: true,
    },
    {
      title: getTextCatalog.getString('Created by'),
      dataIndex: ['author', 'name'],
    },
    {
      title: getTextCatalog.getString('Status'),
      dataIndex: 'status',
      render: mapStatus,
    },
  ];

  const formActionButtons = (): (
    | ActionMenuItem<FormFromSearch>
    | ActionMenuDivider
  )[] => [
    {
      text: getTextCatalog.getString('View responses'),
      icon: <CdEyeIcon fixedWidth />,
      disabled: false,
      onClick: navigateFp('app.private.forms.view'),
    },
    {
      text: getTextCatalog.getString('Edit form'),
      icon: <CdEditIcon fixedWidth />,
      disabled: false,
      onClick: navigateFp('app.private.forms.edit'),
    },
    {
      text: getTextCatalog.getString('Copy form'),
      icon: <CdCopyIcon fixedWidth />,
      disabled: false,
      onClick: duplicate,
    },
    {
      text: ({ status }) =>
        'closed' === status
          ? getTextCatalog.getString('Open form')
          : getTextCatalog.getString('Close form'),
      icon: ({ status }) =>
        'closed' === status ? (
          <CdCheckIcon fixedWidth />
        ) : (
          <CdClose fixedWidth />
        ),

      disabled: false,
      onClick: (form) => ('closed' === form.status ? open(form) : close(form)),
    },
    {
      type: ActionMenuType.DIVIDER,
    },
    {
      text: getTextCatalog.getString('Delete'),
      icon: <CdDeleteIcon fixedWidth />,
      disabled: false,
      onClick: (form) =>
        showConfirmModal({
          title: getTextCatalog.getString('Delete "{{formTitle}}"?', {
            formTitle: form.title,
          }),
          message: getTextCatalog.getString(
            'Are you sure you want to delete "{{formTitle}}" and the {{responseCount}} responses from it?',
            {
              formTitle: form.title,
              responseCount: form.responseCount,
            }
          ),
          icon: <CdDeleteIcon />,
          okButtonProps: { danger: true },
          okText: getTextCatalog.getString('Delete'),
          onOk: () => deleteForm(form),
        }),
    },
  ];

  return (
    <CdQueryTable<FormFromSearch>
      showColumnSelector={false}
      dataResult={FormListSearch}
      searchParams={FormListSearchParamsAtom}
      emptyStateProps={{
        title: getTextCatalog.getString('No forms found'),
        subtitle: getTextCatalog.getString(
          'Create a form now, and share it with others.'
        ),
        EmptyStateIcon: <CdForm />,
      }}
      columns={formColums()}
      actionButtons={formActionButtons()}
      rowClassName={statusToClassname}
      rowStyles={rowStyles}
      headingText={(data) =>
        getTextCatalog.getPlural(data.total, '1 form', '{{count}} forms', {
          count: data.total,
        })
      }
    />
  );
}

export default function FormListHOC(params) {
  return (
    <Suspense fallback={<CdrPageLoader />} key="formListHOC">
      <FormList {...params} />
    </Suspense>
  );
}

function FormList({
  $stateParams: { active, labelId, churches, myForms },
  createForm,
}) {
  const [activeTab, setActiveTab] = useState(active);
  const setSearchQuery = useSetRecoilState(FormListSearchParamsAtom);
  const usersChurches = useRecoilValue(SelectedOrganizationChurches);
  const labels = useRecoilValue(FormLabels);

  useEffect(() => {
    navigate('app.private.forms.list', {
      active: activeTab,
    });
    setSearchQuery((old) => ({
      ...old,
      activeState: activeTab === 'open',
      myForms,
    }));
  }, [activeTab, setSearchQuery, myForms]);

  const tabItems = [
    {
      key: 'open',
      label: <CdTabPaneTab title={getTextCatalog.getString('Open forms')} />,
      children: (
        <>
          <FormsFilter initialQuery={{ labelId, churches, myForms }} />
          <FormListTable />
        </>
      ),
    },
    {
      key: 'closed',
      label: <CdTabPaneTab title={getTextCatalog.getString('Closed forms')} />,
      children: (
        <>
          <FormsFilter initialQuery={{ labelId, churches, myForms }} />
          <FormListTable />
        </>
      ),
    },
  ];

  return (
    <CdPage
      pageHeaderProps={{
        title: getTextCatalog.getString('Forms'),
        extra: (
          <Button
            onClick={() => createForm(usersChurches, labels?.items)}
            type="primary"
          >
            {getTextCatalog.getString('Create')}
          </Button>
        ),
      }}
    >
      <CdTabs activeKey={activeTab} onChange={setActiveTab} items={tabItems} />
    </CdPage>
  );
}
