import React, { useCallback, useEffect, useState } from 'react';
import { Col, InputNumber, Row } from 'antd';

import 'react-image-crop/dist/ReactCrop.css';
import { useRotaAndIntentionState } from '../../hooks/use-rota-and-intention-state';

import gettextCatalog from '@/react/services/I18nService';
import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';
import NotificationService from '@/react/services/NotificationService';

export const EditRotaDutyModal = createCdModal<{
  rota: any;
  isSeriesEvent: boolean;
}>({
  modalName: 'EditRotaDuty',
  InnerModal: ({ setModalProps, rota }) => {
    const [requiredNumber, setRequiredNumber] = useState(rota.required);
    const { updateRotaTableStateToEditTheRotaDuty } =
      useRotaAndIntentionState();
    const onOk = useCallback(() => {
      if (requiredNumber > 0 || !requiredNumber) {
        updateRotaTableStateToEditTheRotaDuty(rota.taskId, requiredNumber);
      } else {
        setRequiredNumber(rota.required);

        NotificationService.notifyError(
          gettextCatalog.getString(
            'Please specify a number or leave empty for no limit.'
          )
        );
      }
    }, [
      requiredNumber,
      rota.required,
      rota.taskId,
      updateRotaTableStateToEditTheRotaDuty,
    ]);

    useEffect(() => {
      setModalProps({
        title: (
          <span
            dangerouslySetInnerHTML={{
              __html: gettextCatalog.getString(
                'How many are required for <i>{{dutyName}}</i> at each event?',
                {
                  dutyName: rota.title,
                }
              ),
            }}
          ></span>
        ),
        width: 420,
        onOk,
        okText: gettextCatalog.getString('Save'),
      });
    }, [onOk, rota.title, setModalProps]);

    return (
      <Row>
        <Col span={24}>
          <InputNumber
            min={1}
            onChange={(e) => {
              setRequiredNumber(e);
            }}
            defaultValue={rota.required}
            value={requiredNumber}
            placeholder={gettextCatalog.getString('No limit')}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
    );
  },
});
