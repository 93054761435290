import React from 'react';
import { Col, Row, Space } from 'antd';
import moment, { Moment } from 'moment';

import { FieldSection } from '../FieldSection';
import getTextCatalog from '../../../../services/I18nService';
import CdQueryTable from '../../../../shared/components/cd-query-table/CdQueryTable';
import { IntentionsTypes } from '../../types/intention.type';
import {
  IntentionSearchParamsAtom,
  IntentionsSearchQuery,
} from '../../store/intentions';

import IntentionsColumns from './IntentionsColumns';
import IntentionsFilters from './IntentionsFilters';

const UnassignedIntentions = ({
  eventTitle,
  eventStartDate,
  churchIds,
  maxNumberOfPrimaryIntentions,
  extraData,
  setExtraData,
  categoryIds,
}: {
  eventTitle: string;
  eventStartDate: Moment;
  churchIds: number[];
  maxNumberOfPrimaryIntentions: number;
  extraData: any;
  setExtraData: (value) => void;
  categoryIds: number[];
}) => {
  const columns = IntentionsColumns({
    maxNumberOfPrimaryIntentions,
    setExtraData,
  }).getUnAssignedIntentionColumns;

  const tableHeader = getTextCatalog.getString(
    'Add intentions to {{title}} {{eventStartDate}}',
    {
      title: eventTitle ? `<b>${eventTitle}</b>,` : null,
      eventStartDate: moment(eventStartDate).format('LLLL'),
    }
  );
  return (
    <Row style={{ width: '100%', marginBottom: '8px' }}>
      <Col span={24} style={{ marginBottom: '24px' }}>
        <FieldSection>
          <span dangerouslySetInnerHTML={{ __html: tableHeader }}></span>
        </FieldSection>
      </Col>
      <Row
        style={{
          paddingRight: '24px',
          paddingLeft: '24px',
          width: '100%',
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <IntentionsFilters churchIds={churchIds} />

          <CdQueryTable<IntentionsTypes>
            noStyle
            showColumnSelector={false}
            dataResult={IntentionsSearchQuery}
            searchParams={IntentionSearchParamsAtom}
            extraParams={{
              extraIntentionsData: extraData,
              eventCategoryIds: categoryIds,
            }}
            emptyStateProps={{
              title: getTextCatalog.getString('No Intentions'),
            }}
            columns={columns}
            pageSize={5}
          />
        </Space>
      </Row>
    </Row>
  );
};

export default UnassignedIntentions;
