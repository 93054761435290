import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { ErrorBoundary } from '@sentry/react';
import { ButtonProps, Modal, Button, Skeleton } from 'antd';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Suspense } from 'react';
import styled from 'styled-components';

import { CdClose } from '../Icons';

import { CdErrorPage } from '@/react/shared/components/cd-error-page/CdErrorPage';
import getTextCatalog from '@/react/services/I18nService';

// Difference between Modal and Pop-up: https://ux.stackexchange.com/questions/94423/should-clicking-outside-an-important-modal-close-it

const CustomModal = styled(Modal)<{
  $maxBodyHeight: number;
  $noPadding: boolean;
}>`
  ${(props) => props.$maxBodyHeight && ' top: 20px'};

  .ant-modal-body {
    ${(props) =>
      props.$maxBodyHeight && `max-height: ${props.$maxBodyHeight}vh`};
    ${(props) => props.$maxBodyHeight && 'overflow-y: scroll'};
    ${(props) => props.$noPadding && 'padding: 0px'};
  }
`;
export interface InnerModalProps {
  title?: string | React.ReactNode;
  width?: number | string;
  okButtonProps?: ButtonProps;
  onOk?: () => void;
  okText?: string | React.ReactNode;
  hideOk?: boolean;
  closable?: boolean; // TODO: We should remove it
  cancelText?: string;
  onCancel?: () => void;
  footer?: React.ReactNode; // TODO: We should remove it
  maskClosable?: boolean; // Should only be used in rare cases.
  maxBodyHeight?: number; // it should be based on 'vh'
  noPadding?: boolean;
}

interface InnerModalType {
  setModalProps?: React.Dispatch<React.SetStateAction<InnerModalProps>>;
  closeModal: () => void;
  loading?: boolean;
}

export type createCdModalProps<T> = {
  modalName: string;
  InnerModal: FunctionComponent<T & InnerModalType>;
};

export const createCdModal = function <T extends Record<string, unknown>>({
  modalName,
  InnerModal,
}: createCdModalProps<T>) {
  return NiceModal.create<T>((callingProps) => {
    const modal = useModal(modalName);
    const [modalProps, _setModalProps] = useState<InnerModalProps>({
      title: '',
      width: 800,
    });

    const setModalProps = useCallback(
      (newValues) => {
        if (typeof newValues === 'function') {
          _setModalProps((prevValues) => ({
            ...prevValues,
            ...newValues(prevValues),
          }));
        } else {
          _setModalProps((prevValues) => ({ ...prevValues, ...newValues }));
        }
      },

      []
    );

    const [disable, setDisable] = useState<boolean>(false);

    const onOk = useCallback(async () => {
      try {
        setDisable(true);
        modalProps.onOk && (await modalProps.onOk());
        if (typeof modal.resolve === 'function') {
          await modal.resolve(true);
        }
        await modal.hide();
      } catch (error) {
        setDisable(false);
      }
    }, [modal, modalProps]);

    const onCancel = useCallback(async () => {
      try {
        setDisable(true);
        modalProps.onCancel && (await modalProps.onCancel());
        await modal.resolve(false);
        modal.hide();
      } catch (error) {
        setDisable(false);
      }
    }, [modal, modalProps]);

    // Ok and cancel buttons
    const footer = [
      <Button key="cancel" onClick={onCancel}>
        {modalProps.cancelText || getTextCatalog.getString('Cancel')}
      </Button>,
      !modalProps.hideOk && (
        <Button
          {...{
            ...modalProps.okButtonProps,
            type: modalProps.okButtonProps?.type || 'primary',
          }}
          disabled={disable || modalProps.okButtonProps?.disabled}
          loading={disable}
          key="ok"
          onClick={onOk}
        >
          {modalProps.okText || getTextCatalog.getString('Ok')}
        </Button>
      ),
    ];
    return (
      <CustomModal
        {...antdModalV5(modal)}
        {...modalProps}
        {...{
          closeIcon: <CdClose />,
          onCancel,
          footer: modalProps.footer === null ? null : footer,
        }}
        destroyOnClose
        $maxBodyHeight={modalProps.maxBodyHeight}
        $noPadding={modalProps.noPadding}
      >
        <ErrorBoundary fallback={<CdErrorPage />}>
          <Suspense fallback={<Skeleton />}>
            <InnerModal
              setModalProps={setModalProps}
              closeModal={() => modal.hide()}
              loading={disable}
              {...callingProps}
            />
          </Suspense>
        </ErrorBoundary>
      </CustomModal>
    );
  });
};
