import React from 'react';
import { Space, Button, Col, Row } from 'antd';
import { useRecoilValue } from 'recoil';

import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';
import { EventRegistrationsQuery } from '@/react/calendar/store/events/event';
import { EventRegistration } from '@/react/calendar/types/event';
import MessageListCreateDropdown from '@/react/people/message-editor/shared/containers/MessageListCreateDropdown';
import AttendeesTableColumn from '@/react/calendar/event-details/components/attendees-list/AttendeesTableColumn';
import getTextCatalog from '@/react/services/I18nService';
import {
  CdBasic,
  CdEditIcon,
  CdPlus,
  EventIcons,
  CdDeleteIcon,
  CdRefresh,
  CdMessage,
} from '@/react/shared/components/Icons';
import {
  ActionMenu,
  ActionMenuType,
} from '@/react/shared/components/cd-table/types';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { MessageType } from '@/react/people/types/message';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { UserHasPermissionAnywhereQuery } from '@/react/user/store/permissions';

const AttendeesListView = (props: {
  eventId: number;
  attendees: EventRegistration[];
  hasTickets: boolean;
  onAddAttendee?: () => void;
  onEditAttendee: (submissionId: string) => void;
  onDeleteAttendee: (submissionId: string) => void;
  onResendTickets: (submissionId: string) => void;
  onRefreshAttendees: () => void;
}) => {
  const canCommunicate = useRecoilValue(
    UserHasPermissionAnywhereQuery('people.communicate')
  );
  const RegistrationSource = EventRegistrationsQuery({
    calendarId: props.eventId,
  });

  const navigateSendMessage = (
    registration: EventRegistration,
    messageType: MessageType,
    afterSend?: (func: () => void) => void
  ) => {
    switch (messageType) {
      case MessageType.BASIC:
      case MessageType.SIMPLE:
        const url = getStateUrl('app.private.people.messages.editorV2.view', {
          type: messageType,
          contactIds: [registration.personId],
        });

        window.open(url, '_blank');
        break;
      default:
        break;
    }

    afterSend && afterSend(() => {});
  };

  const actionButtons: ActionMenu<EventRegistration> = [
    {
      text: getTextCatalog.getString('Edit attendee'),
      icon: <CdEditIcon fixedWidth />,
      disabled: false,
      onClick: (attendee: EventRegistration) =>
        props.onEditAttendee(attendee.submissionId),
    },
    {
      text: getTextCatalog.getString('Resend tickets'),
      icon: <CdBasic fixedWidth />,
      disabled: false,
      onClick: async (data: EventRegistration) =>
        await props.onResendTickets(data.submissionId),
    },
    {
      type: ActionMenuType.DIVIDER,
    },
    {
      text: getTextCatalog.getString('Send newsletter'),
      icon: <CdMessage fixedWidth />,
      disabled: (data: EventRegistration) =>
        !canCommunicate || data.email === undefined,
      onClick: (data: EventRegistration) =>
        navigateSendMessage(data, MessageType.SIMPLE),
    },
    {
      text: (data) => {
        if (!data.canDelete) {
          if (data.hasPayment) {
            return (
              <CdTooltip
                type="warning"
                title={getTextCatalog.getString(
                  'You cannot delete attendees who have paid for the tickets.'
                )}
              >
                {getTextCatalog.getString('Delete')}
              </CdTooltip>
            );
          } else {
            return (
              <CdTooltip
                type="warning"
                title={getTextCatalog.getString(
                  'You do not have access to delete the attendee.'
                )}
              >
                {getTextCatalog.getString('Delete')}
              </CdTooltip>
            );
          }
        } else {
          return <div>{getTextCatalog.getString('Delete')}</div>;
        }
      },
      icon: <CdDeleteIcon fixedWidth />,
      disabled: (data) => !data.canDelete,
      onClick: (data) => {
        showConfirmModal({
          title: getTextCatalog.getString('Delete attendee'),
          message: getTextCatalog.getString(
            'Are you sure you want to delete "{{name}}"?',
            {
              name: data.firstName + ' ' + data.lastName,
            }
          ),
          icon: <CdDeleteIcon />,
          okButtonProps: { danger: true },
          okText: getTextCatalog.getString('Delete'),
          onOk: async () => await props.onDeleteAttendee(data.submissionId),
        });
      },
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '0 8px',
        }}
      >
        <div>
          {getTextCatalog.getString(
            'Attendees are automatically added from the sign-up form.'
          )}
        </div>

        <div>
          <Space>
            <MessageListCreateDropdown
              recipients={
                Array.isArray(props.attendees)
                  ? props.attendees.map((recipient) => ({
                      id: recipient.personId,
                    }))
                  : []
              }
              buttonLabel={getTextCatalog.getString('Send message')}
              recipientsHasSMS={false}
              recipientsHasEmail={true}
              disableStudio={true}
              buttonDisabled={
                Array.isArray(props.attendees)
                  ? props.attendees.length === 0
                  : true
              }
              openInNewTab={true}
            />
            <Button
              icon={<CdPlus />}
              disabled={props.onAddAttendee === undefined}
              onClick={props.onAddAttendee}
            >
              {getTextCatalog.getString('Add attendee')}
            </Button>
            <Button
              icon={<CdRefresh />}
              onClick={() => props.onRefreshAttendees()}
            >
              {getTextCatalog.getString('Refresh the attendees')}
            </Button>
          </Space>
        </div>
      </Row>
      <Col span={24}>
        <CdSimpleTable<EventRegistration>
          noStyle
          recoilSource={RegistrationSource}
          emptyStateProps={{
            title: getTextCatalog.getString('No attendees'),
            EmptyStateIcon: <EventIcons.Participants />,
          }}
          columns={AttendeesTableColumn(props.hasTickets)}
          showColumnSelector={false}
          actionButtons={actionButtons}
        />
      </Col>
    </Row>
  );
};

export default AttendeesListView;
