import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, Form, Input } from 'antd';
import { useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import gettextCatalog from '../../../services/I18nService';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';
import { IntentionFeeRecipient } from '@/react/settings/models/fee';
import {
  createIntentionFeeRecipient,
  updateIntentionFeeRecipient,
} from '@/react/settings/redux/intention-fees/Actions';

export const openIntentionRecipientModal = (
  intentionFeeRecipient?: IntentionFeeRecipient
) =>
  NiceModal.show('IntentionRecipientModal', {
    intentionFeeRecipient,
  });

export const IntentionRecipientModal = createCdModal<{
  intentionFeeRecipient?: IntentionFeeRecipient;
}>({
  modalName: 'IntentionRecipientModal',
  InnerModal: ({ setModalProps, intentionFeeRecipient }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
      form.setFieldsValue({
        feeRecipientName: intentionFeeRecipient?.name,
        feeRecipientId: intentionFeeRecipient?.id,
        isForwardingRecipient: intentionFeeRecipient?.isForwardingRecipient,
      });
    }, [intentionFeeRecipient, form]);

    const onOk = useCallback(() => {
      const recipientName = form.getFieldValue('feeRecipientName');
      const isForwardingRecipient = form.getFieldValue('isForwardingRecipient');
      const recipientId = form.getFieldValue('feeRecipientId');

      if (recipientId) {
        dispatch(
          updateIntentionFeeRecipient({
            id: recipientId,
            updatePayload: { name: recipientName, isForwardingRecipient },
          })
        );
      } else {
        dispatch(
          createIntentionFeeRecipient({
            name: recipientName,
            isForwardingRecipient,
          })
        );
      }
    }, [dispatch, form]);

    useEffect(() => {
      setModalProps({
        title: intentionFeeRecipient
          ? gettextCatalog.getString('Update recipient')
          : gettextCatalog.getString('Create recipient'),
        width: 500,
        onOk,
      });
    }, [intentionFeeRecipient, onOk, setModalProps]);

    return (
      <Form id="createFeeRecipientForm" form={form} layout="vertical">
        <Form.Item
          name="feeRecipientName"
          label={gettextCatalog.getString('Recipient name:')}
        >
          <Input
            placeholder={gettextCatalog.getString('Enter name...')}
          ></Input>
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name="isForwardingRecipient"
          label={gettextCatalog.getString('Weiterleitungsempfänger')}
        >
          <Checkbox />
        </Form.Item>
      </Form>
    );
  },
});

NiceModal.register('IntentionRecipientModal', IntentionRecipientModal);
