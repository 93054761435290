import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Form } from 'antd';
import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import getTextCatalog from '@/react/services/I18nService';
import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';
import { EventQuery } from '@/react/calendar/store/events/event';
import { IcalTokenQuery } from '@/react/calendar/store/ical/ical';
import cdApp from '@/react/config';
import { CdInputWithCopy } from '@/react/shared/components/cd-input-with-copy/CdInputWithCopy';

export interface AssignIntentionModalProps extends Record<string, unknown> {
  eventId: number;
}

export const ExportIcalModal = createCdModal<AssignIntentionModalProps>({
  modalName: 'ExportIcalModal',
  InnerModal: ({ setModalProps, eventId }) => {
    const event = useRecoilValue(
      EventQuery({ calendarId: eventId, clientVersion: 3 })
    );
    const icalToken = useRecoilValue(IcalTokenQuery);
    const iCalLink = `${cdApp.config.api.main}/ical/entity/${eventId}?token=${icalToken}`;

    useEffect(() => {
      setModalProps({
        cancelText: getTextCatalog.getString('Close'),
        title: getTextCatalog.getString('Export {{eventTitle}}', {
          eventTitle: event.title,
        }),
        hideOk: true,
      });
    }, [event, iCalLink, setModalProps]);
    return (
      <Form
        layout="vertical"
        initialValues={{
          feed: iCalLink,
        }}
      >
        <Form.Item
          name="feed"
          label={getTextCatalog.getString('Event feed')}
          extra={getTextCatalog.getString(
            'Export this calendar item by copying the following link to your calendar application.'
          )}
        >
          <CdInputWithCopy disabled />
        </Form.Item>
      </Form>
    );
  },
});

export const launchExportIcalModal = (eventId: number) => {
  NiceModal.show('ExportIcalModal', { eventId });
};
