'use strict';

const PeopleListService = (
  $rootScope,
  $filter,
  $state,
  _,
  People,
  gettextCatalog,
  moment,
  ColumnManager,
  localStorageService,
  appUtils,
  cdRedactedValue
) => {
  'ngInject';

  const defaultColumns = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'tags',
    'lastContacted',
    'lastSubmission',
  ];

  const constructor = function () {
    this.total = 0;
    this.totalMatching = 0;
    this.people = [];
    this.selected = [];
    this.busy = true;
    this.reset = true;
    this.lastSelected = null;
    this.metadata = {
      // Whether any of the currently selected people have e-mail addresses
      canSendEmail: true,
      // Whether any of the currently selected people have phone numbers
      canSendSMS: true,
    };

    this.resetOrdering();
    this.orderDirection = 'asc';
    this.error = null;
    this.allPersonsSelectedInCurrentViewCheckbox = false;
    this.showChurchSelector = cdApp.showChurchSelector;
  };

  /**
   * Fetch the people list
   *
   * @param {Object} filter The criteria to filter people by
   * @param {Boolean} resetOffset Whether we should fetch from the beginning, ignoring any existing offset
   * @param {Boolean} maintainSelection Whether to maintain the current selection
   *
   * @returns {Function} A debounced function that delays the HTTP request
   */
  constructor.prototype.fetchPeople = _.debounce(function (
    filter = {},
    resetOffset = false,
    maintainSelection = false
  ) {
    const limit = 30;
    const nameDisplayOrder = localStorageService.get(
      'peopleSettings.displayOrder'
    );

    this.reset = resetOffset;
    this.busy = true;

    People.searchPeople({
      filter,
      limit,
      searchAfter: resetOffset ? null : this.searchAfter,
      orderBy: this.orderBy,
      orderDirection: this.orderDirection,
      reverseName: nameDisplayOrder === 'lastFirst',
    })
      .$promise.then(({ people, searchAfter, total, totalMatching }) => {
        this.error = null;
        this.total = total;
        this.totalMatching = totalMatching;
        this.searchAfter = searchAfter;

        if (maintainSelection) {
          const selectedPeopleIds = _.map(this.getSelected(), 'id');
          _.each(selectedPeopleIds, (id) => {
            _.set(_.find(people, { id }), 'selected', true);
          });

          this.lastSelected = _.findIndex(this.people, {
            id: _.last(selectedPeopleIds),
          });

          this.updateSelected();
        }

        this.people = resetOffset
          ? people
          : _.unionBy(this.people, people, 'id');

        if (!this.columnManager || !_.size(this.columnManager.columns)) {
          let fields = _.get(this.people, '[0].fields');
          if (!this.showChurchSelector) {
            _.remove(fields, { property: 'churches' });
          }
          const columns = _.map(
            fields,
            ({ label, property, group, sensitive }) => ({
              label,
              property,
              group,
              sensitive,
              isVisible: _.includes(defaultColumns, property),
            })
          );

          this.columnManager = new ColumnManager({
            name: 'people',
            columns,
          });
        }

        this.busy = false;
      })
      .catch((error) => {
        this.total = 0;
        this.totalMatching = 0;
        this.busy = false;
        this.error = error;
      });
  }, 1000);

  /**
   * Update the ordering criteria and refetch the data
   *
   * @param {String} orderBy The field to order by
   */
  constructor.prototype.updateOrdering = function (orderBy = 'fullName') {
    if (this.orderBy === orderBy) {
      this.orderDirection = this.orderDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.orderBy = orderBy;
      this.orderDirection = 'asc';
    }
  };

  /**
   * Reset the ordering criteria
   *
   * @param {String} orderBy The field to order by
   */
  constructor.prototype.resetOrdering = function () {
    const nameDisplayOrder = localStorageService.get(
      'peopleSettings.displayOrder'
    );

    this.orderBy = nameDisplayOrder === 'lastFirst' ? 'lastName' : 'firstName';
  };

  /**
   * Get the currently selected people
   *
   * @returns {Object[]}
   */
  constructor.prototype.getSelected = function () {
    return _.filter(this.people, 'selected');
  };

  /**
   * Toggle the selection state of one or more persons
   *
   * @param {Object} person The person being selected or deselected
   * @param {Number} $index The index of the row in the ng-repeat, used for multi selection
   * @param {Object} $event The original ng-click event, used for multi selection
   */
  constructor.prototype.toggleSelection = function (person, $index, $event) {
    if (!$event.shiftKey || _.isNil(this.lastSelected)) {
      person.selected = !person.selected;
      this.lastSelected = $index;
    } else {
      const isDownwards = this.lastSelected < $index;

      if (isDownwards) {
        for (let i = this.lastSelected + 1; i <= $index; i++) {
          this.people[i].selected = !this.people[i].selected;
        }
      } else {
        for (let i = $index; i < this.lastSelected; i++) {
          this.people[i].selected = !this.people[i].selected;
        }
      }

      const selectedPeople = this.getSelected();
      const lastInArray = _.last(selectedPeople);
      this.lastSelected = _.findIndex(this.people, {
        id: _.get(lastInArray, 'id'),
      });
    }

    this.allPersonsSelectedInCurrentViewCheckbox = false;
    this.updateSelected();
  };

  /**
   * Update the list of selected people
   */
  constructor.prototype.updateSelected = function () {
    this.selected = this.getSelected();
    this.setMetadata();
  };

  /**
   * Check if the selection is indeterminate (at least one selected, but not all)
   *
   * @returns {Boolean}
   */
  constructor.prototype.isSelectionIndeterminate = function () {
    return _.some(this.people, 'selected') && !_.every(this.people, 'selected');
  };

  /**
   * Clear the selection
   */
  constructor.prototype.clearSelection = function () {
    _.each(this.people, (person) => {
      person.selected = false;
    });
    this.allPersonsSelectedInCurrentViewCheckbox = false;

    this.updateSelected();
  };

  /**
   * Get the formatted value for a field
   *
   * @param {Object} field
   * @param {Number} personId
   *
   * @returns {Any} The formatted value
   */
  constructor.prototype.getFieldViewValue = (field, personId) => {
    const emptyCellValue = `<span class="disabled hidden-print">${gettextCatalog.getString(
      'Unknown'
    )}</span>`;

    const valueIsEmpty = _.isNumber(field.value)
      ? _.isNil(field.value)
      : _.isEmpty(field.value);
    if (valueIsEmpty) return emptyCellValue;

    if (field.value === cdRedactedValue) return cdRedactedValue;

    // By field property
    switch (field.property) {
      case 'prefix':
        field.value = _.upperFirst(field.value);
        break;
      case 'firstName':
      case 'lastName':
      case 'email':
        return `<a href="${$state.href('app.private.people.contacts.view', {
          id: personId,
        })}">${field.value}</a>`;
      case 'phone':
      case 'workPhone':
      case 'homePhone':
        return `<a href="tel: ${field.value}"}">${$filter('formatPhoneNumber')(
          field.value
        )}</a>`;
      case 'country':
        return _.get(
          cdApp.data.countries,
          [_.toLower(field.value), 'nameTranslated'],
          field.value
        );
    }

    // By field value data type
    switch (field.type) {
      case 'text': {
        const length = 100;
        const value = field.value;
        const isLinky = appUtils.isLinky(value);

        if (isLinky) {
          return $filter('linky')(value, '_blank', {
            rel: 'noopener noreferrer',
          });
        } else {
          return _.size(value) > length ? _.truncate(value, { length }) : value;
        }
      }
      case 'number':
        return $filter('number')(field.value);
      case 'date':
      case 'lifeEvent':
        if (!moment(new Date(field.value)).isValid()) return field.value;
        return moment(field.value).format('L');
      case 'list': {
        // Check if the value is string in this case it is redacted due to access
        if (_.isString(field.value)) return field.value;
        const total = field.value.length;
        const shown = _.slice(field.value, 0, 5);
        const names = _(shown).map('label').compact().join(', ');
        return total === shown.length
          ? names
          : `${names} ${gettextCatalog.getString('and {{ count }} more', {
              count: total - shown.length,
            })}`;
      }
    }

    return field.value;
  };

  /**
   * Filter only fields that match a displayed column
   *
   * @returns {Boolean} Whether the field should be displayed or not
   */
  constructor.prototype.filterFields = function () {
    return (field) =>
      _.result(
        _.find(this.columnManager.columns, { property: field.property }),
        'isVisible'
      );
  };

  /**
   * Add a set of custom useful properties
   */
  constructor.prototype.setMetadata = function () {
    this.metadata.canSendEmail = this.selected.length
      ? _.some(this.selected, (person) => {
          const field = _.find(person.fields, { property: 'email' });
          return !_.isNil(field.value) || person.hasSharedEmail;
        })
      : true;

    this.metadata.canSendSMS = this.selected.length
      ? _.some(this.selected, (person) => {
          const field = _.find(person.fields, { property: 'phone' });
          return !_.isNil(field.value) || person.hasSharedPhone;
        })
      : true;
  };

  /**
   * Select all people in a list
   *
   * @params {Bool} selected - Boolean representing the current selection state
   */
  function selectAll(selected) {
    _.each(this.people, (person) => {
      person.selected = selected;
    });
    this.updateSelected();
  }

  constructor.prototype.isGlobalSelect = function () {
    // if totalMatching === selected.length that means that all locally loaded contacts has been selected.
    return (
      this.allPersonsSelectedInCurrentViewCheckbox &&
      this.totalMatching !== this.selected.length
    );
  };

  /**
   * Click on the select all checkbox
   */
  constructor.prototype.selectAllClick = function () {
    this.allPersonsSelectedInCurrentViewCheckbox =
      !this.allPersonsSelectedInCurrentViewCheckbox;
    return selectAll.call(this, this.allPersonsSelectedInCurrentViewCheckbox);
  };

  return constructor;
};
PeopleListService.$inject = [
  '$rootScope',
  '$filter',
  '$state',
  '_',
  'People',
  'gettextCatalog',
  'moment',
  'ColumnManager',
  'localStorageService',
  'appUtils',
  'cdRedactedValue',
];

angular.module('cdApp.people').factory('PeopleList', PeopleListService);
