import { Select } from 'antd';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import getTextCatalog from '../../../services/I18nService';
import { OrganizationChurches } from '../../../organization/store/organization';

export interface ParishProps {
  value?: number[];
  onChange?: any;
  disabled?: boolean | false;
  hasAccessToResource?: boolean;
}

export const CdParishSelect = (props: ParishProps) => {
  const churches = useRecoilValue(OrganizationChurches);
  const onChange = useCallback(
    (newParishIds: any) => {
      props.onChange(newParishIds);
    },
    [props]
  );

  return (
    <Select
      value={props.value}
      onChange={(value) => onChange(value)}
      disabled={props.disabled || props.hasAccessToResource}
      mode="multiple"
      allowClear
      filterOption={(input, option) =>
        (option.children as unknown as string)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      placeholder={getTextCatalog.getString('Choose one or more parishes...')}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {churches.map(({ id, name }) => (
        <Select.Option value={id} key={id}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};
