import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import {
  CalendarEvents,
  Taxonomy,
} from '@/react/calendar/reports/types/report.types';
import { Groups } from '@/react/calendar/reports/types/report.types';
import { User } from '@/react/user/types/User.types';

export const getSearchCalendarPrintReport = async ({
  filters,
  type,
}: {
  filters: {
    startDate: string;
    endDate: string;
    search: string;
    groups: number[];
    categories: number[];
    resources: number[];
    users: number[];
    eventsWithoutResourcesInChurchIds: number[];
  };
  type: string;
}): Promise<CalendarEvents> => {
  const response = await mainApi.get<CalendarEvents>(
    `/calendar/print`,
    filters,
    {
      paramsSerializer: (params) =>
        Object.entries(params)
          .map(([key, value]) => {
            if (value === undefined || value === null) return undefined;

            if (Array.isArray(value)) {
              if (value.length === 0) return undefined;

              return value.map((v) => `${key}=${v}`).join('&');
            }
            return `${key}=${value}`;
          })
          .filter(Boolean)
          .join('&'),
    }
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }
  if (type === 'search' || type === 'activityList') {
    return {
      ...response.data,
      events: response.data.events.filter((item) => item.type === 'event'),
    };
  } else if (type === 'absence') {
    return {
      ...response.data,
      events: response.data.events.filter((item) => item.type === 'absence'),
    };
  }
};

export const getSearchCalendarUsers = async (): Promise<User[]> => {
  const response = await mainApi.get<User[]>(`/users`);
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};

export const getSearchCalendarGroups = async (): Promise<Groups[]> => {
  const response = await mainApi.get<Groups[]>(`/groups`);
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};

export const getSearchCalendarCategories = async (): Promise<Taxonomy[]> => {
  const response = await mainApi.get<Taxonomy[]>(`/taxonomies`, {
    type: 'event',
  });
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};

export const getSearchCalendarAbsenceCategories = async (): Promise<
  Taxonomy[]
> => {
  const response = await mainApi.get<Taxonomy[]>(`/taxonomies`, {
    type: 'absence',
  });
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};
