import React, { Suspense, useCallback, useState } from 'react';
import {
  Alert,
  Avatar,
  Col,
  Input,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  theme,
} from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import { Form } from 'antd';
import styled from 'styled-components';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import moment from 'moment';

import { useRotaAndIntentionState } from '../../hooks/use-rota-and-intention-state';

import getTextCatalog from '@/react/services/I18nService';
import CdModal from '@/react/shared/components/cd-modal/CdModal';
import {
  CdClose,
  CdTime,
  CdUsersClock,
  EventIcons,
} from '@/react/shared/components/Icons';
import { CdGroupUserSelect } from '@/react/shared/components/cd-group-user-select/CdGroupUserSelect';
import { CheckUsersAvailablility } from '@/react/calendar/store/events/event';
import { GetAssignableUsersForSpecificRotaDuty } from '@/react/calendar/store/events/eventRotaDuties';
import NotificationService from '@/react/services/NotificationService';
import {
  GetOrganizationUser,
  OrganizationUsersIndexed,
} from '@/react/organization/store/organization';
import { DateInputValue } from '@/react/calendar/event-details/components/date-input/DateInput';
import { SlimUser } from '@/react/organization/types/organization';
import { orderUsers } from '@/react/organization/services/organization.service';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';

export interface RotaDetails {
  calendarId: number;
  taskId: number;
  date: Pick<DateInputValue, 'startDate' | 'endDate' | 'rrule'>;
  // Shift info
  note: string;
  users: { id: number; canUnAssign: boolean }[];
  // Rota info
  rotaDutyName: string;
  required: number;
}

const Container = styled.div`
  padding: 16px;
`;

const DateBox = styled.div`
  border: 2px solid #008db6;
  border-radius: 5px;
  width: 48px;
  height: 48px;
  color: #008db6;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  line-height: 16px;

  span {
    box-sizing: content-box;
  }
`;
const RotaDutyTitle = styled(Typography.Text)`
  &&&& {
    color: #008db6;
    font-size: 18px;
  }
`;

const RowContainer = styled(Row)`
  &&&& {
    height: 50px;
    display: flex;
    align-items: center;
    width: 420px;
    border: 1px solid #eff0ed;
    &:hover {
      background-color: #eff0ed;
    }
  }
`;
const { TextArea } = Input;

type AddUserToRotaModalProps = {
  rotaDetails: RotaDetails;
  canEditUsers: boolean;
  initialRrule: string;
};

export const openAddUserToRotaModal = (props: AddUserToRotaModalProps) =>
  NiceModal.show('AddUserToRotaModal', {
    rotaDetails: props.rotaDetails,
    canEditUsers: props.canEditUsers,
    initialRrule: props.initialRrule,
  });

export const AddUserToRotaModal = NiceModal.create(
  ({ rotaDetails, canEditUsers, initialRrule }: AddUserToRotaModalProps) => {
    const modal = useModal('AddUserToRotaModal');
    const { useToken } = theme;
    const { token } = useToken();
    const [form] = Form.useForm();
    const { updateRotaTableStateToAddUserToTheRota } =
      useRotaAndIntentionState();
    const { startDate, endDate, rrule } = rotaDetails.date;
    const usersLoadable = useRecoilValueLoadable(
      GetAssignableUsersForSpecificRotaDuty(rotaDetails.taskId)
    );
    const users: SlimUser[] =
      usersLoadable.state === 'hasValue' ? usersLoadable.contents : [];
    const usersIndexedLoadable = useRecoilValueLoadable(
      OrganizationUsersIndexed
    );
    const usersIndexed =
      usersIndexedLoadable.state === 'hasValue'
        ? usersIndexedLoadable.contents
        : [];

    const [userAssignedIds, setUserAssignedIds] = useState(
      rotaDetails?.users?.map((user) => user.id) || []
    );
    const userAssignedList = orderUsers(
      userAssignedIds.reduce((accumulator, userId) => {
        usersIndexed[userId] && accumulator.push(usersIndexed[userId]);
        return accumulator;
      }, [])
    );
    const [keyword, setKeyword] = useState('');
    const selectorOptionsUsers = users.filter(
      (user) => !userAssignedIds?.includes(user.id)
    );

    const busyUsersLoadable = useRecoilValueLoadable(
      CheckUsersAvailablility({
        eventId: rotaDetails.calendarId,
        endDate: endDate.toISOString(),
        startDate: startDate.toISOString(),
        users: userAssignedIds,
        rrule,
        initialRrule,
      })
    );
    const busyUsers =
      busyUsersLoadable.state === 'hasValue' ? busyUsersLoadable.contents : [];

    const loading =
      busyUsersLoadable.state === 'loading' ||
      usersLoadable.state === 'loading';
    const handleChange = (value) => {
      setUserAssignedIds([...userAssignedIds, value]);
    };

    const handleRemoveAssignedUser = (removeUserId) => {
      const unAssignableUser = rotaDetails?.users?.find(
        (u) => u.id === removeUserId && u.canUnAssign === false
      );
      if (!unAssignableUser) {
        setUserAssignedIds((prev) =>
          prev.filter((userId) => userId !== removeUserId)
        );
      } else {
        NotificationService.notifyError(
          getTextCatalog.getString('You can not unassign this user')
        );
      }
    };
    const handleOnOk = useCallback(() => {
      updateRotaTableStateToAddUserToTheRota(
        rotaDetails.calendarId,
        rotaDetails.taskId,
        userAssignedIds,
        form.getFieldValue('note')
      );
    }, [
      form,
      rotaDetails.calendarId,
      rotaDetails.taskId,
      updateRotaTableStateToAddUserToTheRota,
      userAssignedIds,
    ]);
    return (
      <CdModal
        title={getTextCatalog.getString('Assign user')}
        okText={getTextCatalog.getString('Add')}
        onOk={handleOnOk}
        modal={modal}
      >
        <Spin spinning={loading}>
          <Container>
            <Form layout="vertical" form={form}>
              <Row justify="center">
                <Space
                  direction="vertical"
                  align="center"
                  style={{ width: '100%' }}
                >
                  <Row>
                    <Space>
                      <DateBox>
                        <span style={{ fontSize: '18px' }}>
                          {moment(startDate).format('DD')}
                        </span>
                        <span>
                          {moment(startDate).format('MMM').toUpperCase()}
                        </span>
                      </DateBox>
                      <Space
                        direction="vertical"
                        style={{ lineHeight: '16px' }}
                        size={0}
                      >
                        <RotaDutyTitle>
                          {rotaDetails.rotaDutyName}
                        </RotaDutyTitle>
                        <div>
                          <Space>
                            <CdTime />
                            {moment(startDate).format('lll')}
                          </Space>
                        </div>
                      </Space>
                    </Space>
                  </Row>
                  <Form.Item
                    label={getTextCatalog.getString('Note')}
                    name="note"
                    initialValue={rotaDetails?.note}
                  >
                    <TextArea
                      rows={3}
                      style={{ width: '256px' }}
                      disabled={!canEditUsers}
                    />
                  </Form.Item>
                  <CdGroupUserSelect
                    placeholder={getTextCatalog.getString('Select user')}
                    onChange={(value) => handleChange(value)}
                    value={null}
                    users={selectorOptionsUsers}
                    disabled={
                      !canEditUsers ||
                      rotaDetails.required === userAssignedIds?.length ||
                      (rotaDetails.required &&
                        rotaDetails.required < userAssignedIds?.length)
                    }
                    keyword={keyword}
                    setKeyword={setKeyword}
                    style={{ width: '420px' }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {(userAssignedIds?.length > 0 || rotaDetails.required) && (
                      <Row>
                        <Space style={{ width: '420px' }}>
                          <Typography.Text>
                            {getTextCatalog.getPlural(
                              userAssignedIds.length,
                              '1 user assigned',
                              '{{ count }} users assigned',
                              {
                                count: userAssignedIds.length,
                              }
                            )}
                          </Typography.Text>

                          {rotaDetails.required > userAssignedIds?.length && (
                            <Typography.Text
                              style={{ color: token.colorError }}
                            >
                              {getTextCatalog.getString(
                                '/ {{ count }} missing',
                                {
                                  count:
                                    rotaDetails.required -
                                    userAssignedIds.length,
                                }
                              )}
                            </Typography.Text>
                          )}
                        </Space>
                      </Row>
                    )}
                    <Suspense fallback={<Spin />}>
                      {userAssignedIds.length > 0 ? (
                        userAssignedList.map((user) => (
                          <AssignedUser
                            key={user.id}
                            userId={user.id}
                            busy={busyUsers.includes(user.id)}
                            handleRemoveAssignedUser={handleRemoveAssignedUser}
                          />
                        ))
                      ) : (
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '32px',
                            width: '100%',
                          }}
                        >
                          <Avatar>
                            <EventIcons.Users size="lg" />
                          </Avatar>
                          <Typography.Title level={5}>
                            {getTextCatalog.getString('No users added')}
                          </Typography.Title>
                        </Row>
                      )}
                    </Suspense>
                  </div>
                  <div style={{ width: '420px' }}>
                    <Alert
                      message={getTextCatalog.getString(
                        'Everyone you add to the rota will be notified once you click “Save & notify”. They will also receive a reminder per email 3 days before the event.'
                      )}
                      type="info"
                    />
                  </div>
                </Space>
              </Row>
            </Form>
          </Container>
        </Spin>
      </CdModal>
    );
  }
);

const AssignedUser = (props: {
  userId: number;
  handleRemoveAssignedUser;
  busy: boolean;
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const user = useRecoilValue(GetOrganizationUser({ id: props.userId }));

  const isUserBlocked = user && user.status === 'blocked';
  const isUserDeleted = !user;

  return (
    <RowContainer key={props.userId}>
      <Col span={20}>
        <Space data-testid="user-details" style={{ marginLeft: '8px' }}>
          <CdUserAvatar
            light
            name={user.name}
            picture={user.picture}
            blocked={isUserBlocked || isUserDeleted}
          />
        </Space>
      </Col>
      <Col span={3} style={{ display: 'flex', justifyContent: 'end' }}>
        {props.busy ? (
          <Tag color={token.colorError}>
            <Space>
              <CdUsersClock />
              {getTextCatalog.getString('Busy')}
            </Space>
          </Tag>
        ) : (
          <Tag>
            <Space>
              <CdUsersClock />
              {getTextCatalog.getString('Available')}
            </Space>
          </Tag>
        )}
      </Col>

      <Col
        data-testid="remove-user-button"
        span={1}
        style={{ cursor: 'pointer' }}
        onClick={() => props.handleRemoveAssignedUser(props.userId)}
      >
        <CdClose style={{ color: 'gray' }} />
      </Col>
    </RowContainer>
  );
};
