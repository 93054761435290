import NiceModal from '@ebay/nice-modal-react';
import { Button, Col, ConfigProvider, List, Row, Typography } from 'antd';
import React, { useEffect } from 'react';

import getTextCatalog from '../../../services/I18nService';
import { useDomains } from '../../hooks/use-domains';

import { DomainListItem } from './DomainListItem';

import cdApp from '@/react/config';
import { supportGetYourWebsiteOnline } from '@/react/homepage/utils/support-articles';
import { createCdDrawer } from '@/react/shared/components/cd-drawer/CdDrawer';
import { CdDomains, CdPlus } from '@/react/shared/components/Icons';
import CdrEmptyState from '@/react/shared/components/CdrEmptyState';

export interface TemplateCard {
  name: 'string';
  templateId: number;
  templateView: string;
}

export const ManageDomainsDrawer = createCdDrawer<{ siteId: string }>({
  modalName: 'ManageDomainsDrawer',
  InnerDrawer: ({ setDrawerProps, siteId }) => {
    const { listDomains } = useDomains(siteId);

    useEffect(() => {
      setDrawerProps({
        title: getTextCatalog.getString('Domains'),
        width: 800,
      });
    }, [setDrawerProps]);

    const domainSupportArticle = supportGetYourWebsiteOnline(
      cdApp?.organization?.locale.language
    );

    return (
      <>
        <Row wrap={false} align="middle" style={{ marginBottom: '8px' }}>
          <Col flex="auto">
            <Typography.Paragraph style={{ marginBottom: '0px' }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: getTextCatalog.getString(
                    'Do you need help? <a>Click here to read our tutorial on domain management.</a>',
                    {
                      a: (text) =>
                        `<a href="${domainSupportArticle}" target="_blank" rel="noopener noreferrer">${text}</a>`,
                    }
                  ),
                }}
              />
            </Typography.Paragraph>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              icon={<CdPlus />}
              type="primary"
              onClick={() => NiceModal.show('AddDomainModal', { siteId })}
            >
              {getTextCatalog.getString('Add new domain')}
            </Button>
          </Col>
        </Row>

        <ConfigProvider
          renderEmpty={() => (
            <CdrEmptyState
              title={getTextCatalog.getString('No domains yet')}
              buttonText={getTextCatalog.getString('Add new domain')}
              onButtonClick={() => NiceModal.show('AddDomainModal', { siteId })}
              EmptyStateIcon={<CdDomains />}
            />
          )}
        >
          <List
            size="default"
            bordered
            dataSource={listDomains}
            renderItem={(domain) => (
              <DomainListItem
                domain={domain}
                numberOfDomains={listDomains?.length || 0}
                siteId={siteId}
              />
            )}
          />
        </ConfigProvider>

        <Typography.Title level={3}>
          {getTextCatalog.getString('Using the ChurchDesk Cookie Solution?')}
        </Typography.Title>
        <Typography.Paragraph>
          {getTextCatalog.getString(
            'Visitors of the website will automatically be redirected to your primary domain to centrally manage consents for cookies. Using a primary domain will also ensure that your website is indexed correctly by search engines.'
          )}
        </Typography.Paragraph>
      </>
    );
  },
});
