import React from 'react';
import { Checkbox, Form, Input } from 'antd';

import { createCdModal } from '../../../shared/components/cd-modal-new/CdModal2';
import gettextCatalog from '../../../services/I18nService';
import Redactor from '../../../people/message-editor/shared/containers/Redactor';
import AuthorizationService from '../../../services/AuthorizationService';

import { useNewGroupModal } from './useNewGroupModal';

export interface GroupInterface {
  id: number;
  name: string;
  description: string;
  absenceDisabled: boolean;
  hasTask: boolean;
  tasks: any[];
  type: string;
  leaders: any[];
  members: any[];
  picture: string;
}

export const NewGroupModal = createCdModal<{
  done?: any;
  initialValue?: GroupInterface;
  isEdit: boolean;
}>({
  modalName: 'NewGroupModal',
  InnerModal: ({ setModalProps, done, initialValue, isEdit }) => {
    const { groupModalForm, setRotaName, formattedInitialValue } =
      useNewGroupModal({
        isEdit,
        initialValue,
        setModalProps,
        done,
      });

    return (
      <Form
        form={groupModalForm}
        layout="vertical"
        initialValues={formattedInitialValue}
      >
        <Form.Item name="name" label={gettextCatalog.getString('Group name')}>
          <Input
            onChange={(e) => {
              setRotaName(e.target.value);
            }}
            placeholder={gettextCatalog.getString('i.e. PCC, Staff, Choir...')}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label={gettextCatalog.getString('Group description')}
        >
          <Redactor />
        </Form.Item>

        <Form.Item
          name="absenceDisabled"
          valuePropName="checked"
          extra={gettextCatalog.getString(
            'Allow absence to be registered in this group.'
          )}
        >
          <Checkbox>{gettextCatalog.getString('Tracks absence')}</Checkbox>
        </Form.Item>
        {AuthorizationService.hasPackage('planning') &&
          AuthorizationService.hasPermission('canAccessPlanning') && (
            <Form.Item
              name="hasTask"
              valuePropName="checked"
              extra={gettextCatalog.getString(
                "If you make this group a rota group you can add it to your events. Members of this group can hereafter assign themselves to it's rota duty."
              )}
            >
              <Checkbox>
                {gettextCatalog.getString('Is this a rota group?')}
              </Checkbox>
            </Form.Item>
          )}
        {AuthorizationService.hasPackage('planning') &&
          AuthorizationService.hasPermission('canAccessPlanning') && (
            <Form.Item
              noStyle
              dependencies={['name', 'hasTask']}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.hasTask !== curValues.hasTask ||
                (curValues.hasTask && prevValues.name !== curValues.name)
              }
            >
              {({ getFieldValue }) => {
                const hasTask = getFieldValue('hasTask');
                return (
                  <Form.Item
                    style={{ display: hasTask ? 'block' : 'none' }}
                    name={['tasks', '0', 'title']}
                    label={gettextCatalog.getString('Display name for rota')}
                    extra={gettextCatalog.getString(
                      'This is the title of the group as displayed in the rota scheme. Useful for providing a shorter name if you have many rota groups.'
                    )}
                  >
                    <Input
                      placeholder={gettextCatalog.getString(
                        'i.e. Reading, intercession, sides task...'
                      )}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          )}
        <Form.Item name="type" valuePropName="checked">
          <Checkbox>
            {gettextCatalog.getString('This is a priest group')}
          </Checkbox>
        </Form.Item>
      </Form>
    );
  },
});
