'use strict';

import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { CdSSOButtons } from '@/react/login/components/CdSSOButtons';
import { SsoPage } from '@/react/login/components/SsoPage';
class LoginPageComponent {
  constructor(
    $state,
    $stateParams,
    localStorageService,
    AuthenticationService,
    gettextCatalog
  ) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.localStorageService = localStorageService;
    this.AuthenticationService = AuthenticationService;
    this.gettextCatalog = gettextCatalog;
  }

  $onInit() {
    if (this.$stateParams.email) {
      this.email = this.$stateParams.email;
    }
  }

  getLegalLink = () => {
    switch (this.gettextCatalog.getCurrentLanguage()) {
      case 'da':
        return 'https://www.churchdesk.com/da/jura';
      case 'de':
        return 'https://www.churchdesk.com/de/rechtliches';
      default:
        return 'https://www.churchdesk.com/legal/privacy-policy';
    }
  };

  redirect() {
    // When coming from Drupal or Altar, redirect passing a loginToken in order to authenticate on those domains
    if (this.$stateParams.module) {
      this.AuthenticationService.redirectExternal(
        this.$stateParams.organization,
        this.$stateParams.module,
        this.$stateParams.continue || '/'
      );

      return;
    }

    // Otherwise go to the state where you can choose the organization, passing the `continue` param for taking the user where they wanted to go
    this.$state.go('app.public.chooseOrganization', {
      continue: this.$stateParams.continue,
    });
  }
}
LoginPageComponent.$inject = [
  '$state',
  '$stateParams',
  'localStorageService',
  'AuthenticationService',
  'gettextCatalog',
];

angular.module('cdApp.shared').component('cdLoginPage', {
  templateUrl: '@/app/shared/components/login-page/login-page.component.html',
  controller: LoginPageComponent,
});

angular
  .module('cdApp.shared')
  .component(
    'cdSSOButtons',
    react2angular(sharedContext.use(CdSSOButtons), [], [])
  );

angular
  .module('cdApp.shared')
  .component(
    'ssoPage',
    react2angular(sharedContext.use(SsoPage), [], ['$stateParams'])
  );
