import { Input } from 'antd';
import React from 'react';

import getTextCatalog from '@/react/services/I18nService';

export const CdInputAlias = (props: {
  value?: string;
  onChange?: any;
  allowAlias: boolean;
  disabled?: boolean;
}) => (
  <Input
    addonBefore={getTextCatalog.getString('www.your-church.com/b/')}
    disabled={!props.allowAlias || props.disabled}
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  />
);
