import React, { useCallback, useEffect } from 'react';
import { Alert, InputNumber, Select } from 'antd';
import 'react-image-crop/dist/ReactCrop.css';
import { Form } from 'antd';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';

import { useRotaAndIntentionState } from '../../hooks/use-rota-and-intention-state';

import {
  GetRotaDuties,
  RotaTableState,
} from '@/react/calendar/store/events/eventRotaDuties';
import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';
import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';
import gettextCatalog from '@/react/services/I18nService';

const { Option } = Select;

const RotaForm = styled(Form)`
  &&&& {
    .ant-form-item-label > label::after {
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-left: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
  }
`;

export const openAddRotaDutyModal = (isSeriesEvent: boolean) =>
  NiceModal.show('AddRotaDuty', {
    isSeriesEvent: isSeriesEvent,
  });

export const AddRotaDutyModal = createCdModal<{
  isSeriesEvent: boolean;
}>({
  modalName: 'AddRotaDuty',
  InnerModal: ({ setModalProps, isSeriesEvent }) => {
    const rotaTableState = useRecoilValue(RotaTableState);
    const { updateRotaTableStateByAddingNewRotaDuty } =
      useRotaAndIntentionState();
    const [form] = Form.useForm();
    const rotaDuties = useRecoilValue(GetRotaDuties);
    const rotaDutiesFiltered = rotaDuties
      .filter((rota) => !rotaTableState.rotas.some((r) => r.taskId === rota.id))
      .sort((a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase())
      );
    const onOk = useCallback(async () => {
      await form
        .validateFields()
        .then(() => {
          const { taskId, required } = form.getFieldsValue();
          const title = rotaDuties.find((rota) => rota.id === taskId).title;
          updateRotaTableStateByAddingNewRotaDuty(taskId, required, title);
        })
        .catch(handleAntFormValidationErrors);
    }, [form, rotaDuties, updateRotaTableStateByAddingNewRotaDuty]);

    useEffect(() => {
      setModalProps({
        title: gettextCatalog.getString('Add rota duty'),
        width: 500,
        onOk,
      });
    }, [onOk, setModalProps]);

    return (
      <RotaForm layout="vertical" form={form}>
        <Form.Item
          name="taskId"
          label={gettextCatalog.getString('Select rota duty')}
          rules={[
            {
              required: true,
              message: gettextCatalog.getString('Please select a duty'),
            },
          ]}
        >
          <Select
            placeholder={gettextCatalog.getString('Select rota duty')}
            style={{ width: '100%' }}
            optionFilterProp="children"
          >
            {rotaDutiesFiltered.map((rota) => (
              <Option value={rota.id} key={rota.id}>
                {rota.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="required"
          label={gettextCatalog.getString('How many are required?')}
        >
          <InputNumber
            placeholder={gettextCatalog.getString('No limit')}
            min={1}
            style={{ width: '100%', marginBottom: '8px' }}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prev, current) => prev.taskId !== current.taskId}
          noStyle
        >
          {({ getFieldValue }) => {
            const selectedRota = rotaDutiesFiltered.find(
              (element) => element.id === getFieldValue('taskId')
            );
            return (
              isSeriesEvent &&
              selectedRota && (
                <Alert
                  message={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: gettextCatalog.getString(
                          '{{dutyName}} will be added to all future events in the event series.',
                          {
                            dutyName: `<i>${selectedRota.title}</i>`,
                          }
                        ),
                      }}
                    ></span>
                  }
                  type="info"
                  showIcon
                />
              )
            );
          }}
        </Form.Item>
      </RotaForm>
    );
  },
});
