import React, { useCallback, useEffect } from 'react';
import {
  Typography,
  Alert,
  Form,
  Space,
  Input,
  Col,
  Row,
  FormInstance,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

import {
  StandardWorkingHoursInterface,
  UserTimeRegistrationCalendar,
} from './types/timeRegistration.types';
import { showStandardWorkingHoursModal } from './StandardWorkingHoursModal';
import { TimeRegistrationHoursInput } from './TimeRegistrationHoursInput';
import { useWorkingHours } from './hooks/useWorkingHours';

import getTextCatalog from '@/react/services/I18nService';
import {
  CdEditIcon,
  CdExclamationTriangleIcon,
  CdHoursSaved,
  CdHoursSaving,
} from '@/react/shared/components/Icons';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const WorkingHours = ({
  selectedDateInsight,
  timeRegistrationForm,
  standardWorkingHoursData,
}: {
  selectedDateInsight: UserTimeRegistrationCalendar;
  timeRegistrationForm: FormInstance;
  standardWorkingHoursData: StandardWorkingHoursInterface;
}) => {
  const { saveState, onInputChange, hoursInputReference, cancelDebounce } =
    useWorkingHours(
      selectedDateInsight.date.format('YYYY-MM-DD'),
      timeRegistrationForm
    );

  const renderWarningMessage = useCallback(() => {
    if (
      selectedDateInsight?.booking &&
      (selectedDateInsight.hours === undefined ||
        selectedDateInsight.hours === 0)
    ) {
      return (
        <Alert
          showIcon
          message={getTextCatalog.getString(
            'Bookings or rota in calendar but no hours logged'
          )}
          type="warning"
        />
      );
    }
  }, [selectedDateInsight]);

  useEffect(() => {
    timeRegistrationForm.setFieldsValue({
      hours: selectedDateInsight ? selectedDateInsight.hours : 0,
      standardWorkingHours: standardWorkingHoursData,
      comment: selectedDateInsight?.comment,
    });
  }, [timeRegistrationForm, selectedDateInsight, standardWorkingHoursData]);

  return (
    <>
      <Col span={24}>
        <Typography.Text strong>
          {getTextCatalog.getString('Add hours worked')}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {getTextCatalog.getString(
            'You can use negative numbers to adjust your time balance if required.'
          )}
        </Typography.Text>
      </Col>
      <Col span={24}>{renderWarningMessage()}</Col>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item
              label={
                <Space
                  direction="horizontal"
                  align="center"
                  style={{ height: 32, fontWeight: 'normal' }}
                >
                  <Typography.Text>
                    {moment(dayjs(selectedDateInsight?.date).toDate()).format(
                      'dddd, LL'
                    )}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {dayjs(selectedDateInsight?.date).isSame(
                      dayjs().toDate(),
                      'day'
                    ) && `(${getTextCatalog.getString('Today')})`}
                  </Typography.Text>
                  {saveState === 'saving' && (
                    <CdHoursSaving
                      animation="spin"
                      type="secondary"
                      style={{ fontSize: 20 }}
                    />
                  )}
                  {saveState === 'saved' && (
                    <CdHoursSaved
                      type="secondary"
                      style={{ fontSize: 20, display: 'flex' }}
                    />
                  )}
                  {saveState === 'failed' && (
                    <CdTooltip
                      title={getTextCatalog.getString('Failed to save')}
                    >
                      <CdExclamationTriangleIcon
                        type="secondary"
                        style={{ fontSize: 20 }}
                      />
                    </CdTooltip>
                  )}
                </Space>
              }
              name="hours"
              style={{ marginBottom: '0px', textAlign: 'right', width: '100%' }}
              rules={[
                {
                  validator: (_, value) => {
                    if (value) {
                      const splits = value.toString().split(/[.,]+/);
                      if (
                        splits.length > 1 &&
                        splits[splits.length - 1].length > 2
                      ) {
                        cancelDebounce();
                        return Promise.reject(
                          getTextCatalog.getString(
                            'No more than 2 decimal places'
                          )
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              extra={<ConvertedHours form={timeRegistrationForm} />}
            >
              <TimeRegistrationHoursInput
                ref={hoursInputReference}
                onChange={onInputChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Typography.Link onClick={() => showStandardWorkingHoursModal()}>
              <Space>
                <CdEditIcon />
                {getTextCatalog.getString('Edit your standard working hours')}
              </Space>
            </Typography.Link>
          </Col>
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }} size={0}>
              <Form.Item
                label={getTextCatalog.getString('Comment')}
                name="comment"
                style={{ marginBottom: '0px' }}
              />
              <Form.Item
                name="comment"
                rules={[
                  {
                    max: 255,
                    message: getTextCatalog.getString(
                      'Comment must be less than 255 characters'
                    ),
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder={getTextCatalog.getString(
                    'Overtime paid out, etc'
                  )}
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Col>
    </>
  );
};

function ConvertedHours(props: { form: FormInstance }) {
  const hours = Form.useWatch('hours', props.form);
  const duration = moment.duration(hours || 0, 'hours');
  const formattedDuration = getTextCatalog.getString(
    '{{hours}} hours {{minutes}} minutes',
    {
      hours: duration.hours(),
      minutes: duration.minutes(),
    }
  );
  return <>{formattedDuration}</>;
}
