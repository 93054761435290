import { useCallback, useEffect, useRef, useState } from 'react';
import { FormInstance } from 'antd';
import { debounce } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';

import { mainApi } from '@/react/api';
import cdApp from '@/react/config';
import { handleError } from '@/react/services/ErrorHandlingService';

// Selected date a string, to limit updates in the useEffect, and thus that the input field is reselected after saving.
export const useWorkingHours = (selectedDate: string, form: FormInstance) => {
  const hoursInputReference = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const [saveState, setSaveState] = useState<'saving' | 'saved' | 'failed'>(
    'saved'
  );

  useEffect(() => {
    // Hack to delay the select after the component is (re)rendered
    if (hoursInputReference.current)
      setTimeout(() => hoursInputReference.current.select(), 100);
  }, [hoursInputReference, selectedDate]);

  const saveHours = useCallback(
    async (value) => {
      const currentLoggedInUserId = cdApp?.me?.id;
      const response = await mainApi.post(
        `/users/${currentLoggedInUserId}/time-registrations`,
        {
          hours: value,
          date: selectedDate,
          comment: form.getFieldValue('comment'),
        }
      );
      if (!response.ok) {
        handleError(response);
        setSaveState('failed');
      } else {
        setSaveState('saved');
        queryClient.invalidateQueries({
          queryKey: ['useTimeRegistration'],
        });
      }
    },
    [form, queryClient, selectedDate]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(debounce(saveHours, 1000), [saveHours]);

  // If the debounce is not cancelled when the validation fails, when the user keeps typing the field will save while they are typing.
  // e.g. type: 1.2 and let it save. Then keep adding 2's. It will save 1.22, even though the field has 1.22222.
  const cancelDebounce = () => {
    setSaveState('failed');
    debouncedChange.cancel();
  };

  const onInputChange = (value) =>
    form.validateFields().then(() => {
      setSaveState('saving');
      debouncedChange(value);
    });

  return {
    hoursInputReference,
    onInputChange,
    cancelDebounce,
    saveState,
  };
};
