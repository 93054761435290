import React, { useCallback } from 'react';
import { Form, Radio, Space, Select, Typography } from 'antd';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import getTextCatalog from '../../../../services/I18nService';
import { EventIcons } from '../../../../shared/components/Icons';
import { GetAllGroups } from '../../../../organization/store/organization';
import { CdRadioWithIcon } from '../../../../shared/components/cd-radio-with-icon/CdRadioWithIcon';

import { formatOrganizationGroups } from './Visibility.helper';

const SelectGroupDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-bottom: 16px;
`;

interface visibilityProps {
  visibility: string;
  groupIds: object[];
}

interface VisibilityWrapperProps {
  value?: visibilityProps;
  onChange?: any;
  VisibilityAllowedValues: string[];
  disabled?: boolean;
}

const VisibilityWrapper = (props: VisibilityWrapperProps) => {
  const OrganizationGroups = useRecoilValue(GetAllGroups);
  const formattedGroups = formatOrganizationGroups(
    OrganizationGroups,
    props.VisibilityAllowedValues
  );
  const radioOnChangeHandler = useCallback(
    (e) => {
      const v = e.target.value;
      if (v === 'internal-all' || v === 'private') {
        props.onChange({
          ...props.value,
          visibility: e.target.value,
          groupIds: undefined,
        });
      } else {
        props.onChange({ ...props.value, visibility: e.target.value });
      }
    },
    [props]
  );

  const onGroupChangeHandler = useCallback(
    (groups) => {
      props.onChange({ ...props.value, groupIds: groups });
    },
    [props]
  );

  return (
    <>
      <Form.Item style={{ marginBottom: '0px' }}>
        <Radio.Group
          onChange={radioOnChangeHandler}
          value={props.value?.visibility}
        >
          <Space direction="vertical">
            <CdRadioWithIcon
              Icon={EventIcons.VisibilityPublic}
              iconSize="small"
              value="public"
              title={getTextCatalog.getString(
                'Public: Event can be shared everywhere'
              )}
              label={getTextCatalog.getString(
                'Allow this event on your website and in messages sent from People. Details are shared internally with all users.'
              )}
              align="start"
              disabled={
                !props.VisibilityAllowedValues.includes('public') ||
                props.disabled
              }
            />
            {props.value?.visibility === 'public' && (
              <>
                <SelectGroupDiv>
                  <Typography.Text strong style={{ fontSize: '14px' }}>
                    {getTextCatalog.getString(
                      'Restrict which groups can see the internal note'
                    )}
                  </Typography.Text>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={getTextCatalog.getString('Please select')}
                    onChange={onGroupChangeHandler}
                    optionLabelProp="label"
                    options={formattedGroups}
                    value={props.value?.groupIds}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabled={props.disabled}
                    filterOption={(input, option) =>
                      option?.label
                        .toLocaleLowerCase()
                        .includes(input.trim().toLocaleLowerCase())
                    }
                  />
                </SelectGroupDiv>
              </>
            )}
            <CdRadioWithIcon
              value="internal-all"
              Icon={EventIcons.VisibilityChurch}
              iconSize="small"
              title={getTextCatalog.getString(
                'All users: Every user can see event details'
              )}
              label={getTextCatalog.getString(
                'All users with calendar access can view details of this event. To see sensitive information additional permissions are required.'
              )}
              align="start"
              disabled={
                !props.VisibilityAllowedValues.includes('internal-all') ||
                props.disabled
              }
            />
            <CdRadioWithIcon
              value="internal-group"
              Icon={EventIcons.VisibilityUser}
              iconSize="small"
              title={getTextCatalog.getString(
                'Groups: Specific groups can see event details'
              )}
              label={getTextCatalog.getString(
                'Anyone in these specific groups can view the details of this event. All other users can only see that the booked users and resources are busy.'
              )}
              align="start"
              disabled={
                !props.VisibilityAllowedValues.includes('internal-group') ||
                props.disabled
              }
            />
            {props.value?.visibility === 'internal-group' && (
              <SelectGroupDiv>
                <Typography.Text strong style={{ fontSize: '14px' }}>
                  {getTextCatalog.getString(
                    'Restrict which groups can see the internal note'
                  )}
                </Typography.Text>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={getTextCatalog.getString('Please select')}
                  onChange={onGroupChangeHandler}
                  optionLabelProp="label"
                  options={formattedGroups}
                  value={props.value?.groupIds}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={props.disabled}
                  filterOption={(input, option) =>
                    option?.label
                      .toLocaleLowerCase()
                      .includes(input.trim().toLocaleLowerCase())
                  }
                />
              </SelectGroupDiv>
            )}
            <CdRadioWithIcon
              value="private"
              Icon={EventIcons.VisibilityLock}
              iconSize="small"
              title={getTextCatalog.getString(
                'Private: Only you and invited users can see event details'
              )}
              label={getTextCatalog.getString(
                'Only you and the invited users can see event details. All other users can only see that the booked users and resources are busy.'
              )}
              align="start"
              disabled={props.disabled}
            />
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default VisibilityWrapper;
