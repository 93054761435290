import { useRecoilValue, useRecoilValueLoadable, waitForNone } from 'recoil';

import {
  BookedUsersWithAttendingAndNotifications,
  CheckUsersAvailablility,
} from '@/react/calendar/store/events/event';
import {
  GetAllGroups,
  OrganizationUsers,
} from '@/react/organization/store/organization';

enum userStatus {
  BLOCKED = 'blocked',
  ACTIVE = 'active',
}

export const useUserBooking = ({
  startDate,
  endDate,
  eventId,
  users,
  rrule,
  initialRrule,
}) => {
  const [allusersLoadable, groupsLoadable] = useRecoilValue(
    waitForNone([OrganizationUsers, GetAllGroups])
  );
  const allUsers = hasValueOrEmptyArray(allusersLoadable);
  const groups = hasValueOrEmptyArray(groupsLoadable);

  const usersAndGroupsLoading =
    allusersLoadable.state === 'loading' || groupsLoadable.state === 'loading';

  const busyUsersLoadable = useRecoilValueLoadable(
    CheckUsersAvailablility({
      endDate: endDate.toISOString(),
      eventId: eventId,
      startDate: startDate.toISOString(),
      users,
      rrule,
      initialRrule,
    })
  );
  const busyUsers = hasValueOrEmptyArray(busyUsersLoadable);
  const busyUsersLoading = busyUsersLoadable.state === 'loading';

  const showUsersLoadable = useRecoilValueLoadable(
    BookedUsersWithAttendingAndNotifications({ users, eventId })
  );
  const showUsers = hasValueOrEmptyArray(showUsersLoadable);

  const idsOfUsersDoesnotMatterBusy = showUsers
    .filter((user) => user.attending === 'yes' || user.attending === 'no')
    .map((user) => user.id);

  const busyUserIds = busyUsers.filter(
    (userId) => !idsOfUsersDoesnotMatterBusy.includes(userId)
  );

  const unavailableUsersCount =
    showUsers.filter((user) => user.attending === 'no').length +
    busyUserIds.length;

  const selectorOptionsUsers = allUsers.filter(
    (user) => !users?.includes(user.id) && user?.status !== userStatus.BLOCKED
  );

  return {
    usersAndGroupsLoading,
    busyUsersLoading,
    groups,
    selectorOptionsUsers,
    showUsers,
    unavailableUsersCount,
    busyUsers,
  };
};

const hasValueOrEmptyArray = (loadable) =>
  loadable.state === 'hasValue' ? loadable.contents : [];
