import React, { useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { TimeRegistrationLandingPage } from './TimeRegistrationLandingPage';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';

export const TimeRegistrationLandingModal = createCdModal({
  modalName: 'TimeRegistrationLandingModal',
  InnerModal: ({ setModalProps }) => {
    useEffect(() => {
      setModalProps({
        title: '',
        width: 600,
      });
    }, [setModalProps]);

    return (
      <div style={{ height: 700, margin: 24 }}>
        <TimeRegistrationLandingPage />
      </div>
    );
  },
});

export const showTimeRegistrationLandingModal = () =>
  NiceModal.show('TimeRegistrationLandingModal');
NiceModal.register(
  'TimeRegistrationLandingModal',
  TimeRegistrationLandingModal
);
