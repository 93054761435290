import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { AuthenticateSSOToken } from '../store/login';

import cdApp from '@/react/config';

export const useSso = ({ idToken, state }) => {
  const { accessToken, error, email } = useRecoilValue(
    AuthenticateSSOToken({ idToken, state })
  );

  useEffect(() => {
    if (!accessToken) return;
    if (typeof accessToken !== 'string') return;
    localStorage.setItem('churchdesk.accessToken', accessToken);

    window.location.href = `${cdApp.references.appUrl}/choose`;
  }, [accessToken]);

  return { accessToken, error, email };
};
