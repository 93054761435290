import moment from 'moment';
import { useRecoilValue } from 'recoil';

import { getCalendarReportDownloadToken } from '@/react/calendar/reports/service/calendarExportService';
import cdApp from '@/react/config';
import { SearchCalendarPrintTableColumn } from '@/react/calendar/reports/SearchCalendarPrintTableColumn';
import NotificationService from '@/react/services/NotificationService';
import gettextCatalog from '@/react/services/I18nService';
import { HasMultipleChurches } from '@/react/user/store/user-session';

export const useExportReport = ({ filters, type }) => {
  const isMultiParish = useRecoilValue(HasMultipleChurches);

  const handleExport = async () => {
    try {
      const response = await getCalendarReportDownloadToken();
      if (!response?.token) {
        throw new Error('Invalid download token');
      }

      const params = {
        organizationId: cdApp.organization.id,
        downloadToken: response.token,
        startDate: moment(filters.startDate).format('YYYY-MM-DD'),
        endDate: moment(filters.endDate).format('YYYY-MM-DD'),
        search: filters.searchText || '',
        groups: filters.groupSelect || [],
        categories: filters.categorySelect || [],
        resources: filters.resourceSelect
          ?.filter((item) => item.includes('resource-'))
          ?.map((value) => value.replace('resource-', '')),
        eventsWithoutResourcesInChurchIds: filters.resourceSelect
          ?.filter((item) => item.includes('church-'))
          ?.map((value) => value.replace('church-', '')),
        users: filters.userSelect || [],
        columns: SearchCalendarPrintTableColumn(type, isMultiParish),
        output: 'xlsx',
      };

      const groupsParams = params.groups
        .map((group) => `groups[]=${group}`)
        .join('&');
      const categoriesParams = params.categories
        .map((category) => `categories[]=${category}`)
        .join('&');
      const resourcesParams = params.resources
        .map((resource) => `resources[]=${resource}`)
        .join('&');
      const eventsWithoutResourcesInChurchIdsParams =
        params.eventsWithoutResourcesInChurchIds
          .map((event) => `eventsWithoutResourcesInChurchIds[]=${event}`)
          .join('&');
      const usersParams = params.users
        .map((user) => `users[]=${user}`)
        .join('&');
      const columnsParams = params.columns
        .filter((column) => !column.hidden)
        .map((column) => `columns[]=${column.key}`)
        .join('&');

      window.open(
        `${cdApp.config.api.main}/calendar/export/download?downloadToken=${response?.token}&organizationId=${cdApp.organization.id}&startDate=${params.startDate}&endDate=${params.endDate}&search=${params.search}&${groupsParams}&${categoriesParams}&${resourcesParams}&${eventsWithoutResourcesInChurchIdsParams}&${usersParams}&${columnsParams}&output=${params.output}`,
        '_blank'
      );
    } catch (error) {
      NotificationService.notifyError(
        gettextCatalog.getString(
          'An error occurred while exporting the report. Please try again.'
        )
      );
    }
  };
  return { handleExport };
};
