import NiceModal from '@ebay/nice-modal-react';
import { Form } from 'antd';
import React from 'react';

import { StandardWorkingHours } from './StandardWorkingHours';
import useStandardWorkingHoursModal from './hooks/useStandardWorkingHoursModal';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';

const StandardWorkingHoursModal = createCdModal({
  modalName: 'StandardWorkingHoursModal',
  InnerModal: ({ setModalProps }) => {
    const { form, initialValues } = useStandardWorkingHoursModal(setModalProps);

    return (
      <Form form={form} colon={false} initialValues={initialValues}>
        <StandardWorkingHours />
      </Form>
    );
  },
});

NiceModal.register('StandardWorkingHoursModal', StandardWorkingHoursModal);
export const showStandardWorkingHoursModal = () =>
  NiceModal.show('StandardWorkingHoursModal');
