import {
  Form,
  DatePicker,
  Space,
  Select,
  Button,
  Row,
  Col,
  Typography,
  Card,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import getTextCatalog from '../../services/I18nService';

import { useTimeRegistrationReport } from '@/react/calendar/reports/hooks/useTimeRegistrationReport';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';

const { RangePicker } = DatePicker;

const StyledSelect = styled(Select)`
    .ant-select-selection-item{
        display: flex;
        align-items: center;
    }`;

export const TimeRegistrationReport = ({ filters }) => {
  const {
    timeRegistrationReportForm,
    initialValues,
    onExportTimeRegistrationReport,
    timeRegistrationReportUsers,
    setHasChanges,
    hasChanges,
  } = useTimeRegistrationReport({ filters });

  const userOptions = timeRegistrationReportUsers?.map((item) => ({
    label: (
      <Space>
        <CdUserAvatar picture={item?.picture} size={30} />
        {item.name || item.email}
      </Space>
    ),
    value: item.id,
  }));

  const onFormValueChange = React.useCallback(
    (changedValues) => {
      if (changedValues.user) {
        setHasChanges(true);
      }
    },
    [setHasChanges]
  );
  const customWeekFormat = (value) => {
    if (!value) {
      return '';
    }
    const formattedDate = getTextCatalog.getString('{{year}} - Week {{week}}', {
      year: value?.year(),
      week: value?.week(),
    });
    return formattedDate;
  };

  return (
    <Card style={{ height: '100vh', border: 'none' }}>
      <Row gutter={[12, 24]}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Text>
            {getTextCatalog.getString(
              'Please select your parameters for the time registration report. We will generate the report and send it to your email.'
            )}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Form
            form={timeRegistrationReportForm}
            initialValues={initialValues}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            onValuesChange={onFormValueChange}
          >
            <Space>
              <Form.Item
                name="dateRange"
                rules={[
                  {
                    validator: (rule, value) => {
                      const [start, end] = value;
                      const weekDifference = end.diff(start, 'weeks');
                      if (weekDifference >= 53) {
                        return Promise.reject(
                          getTextCatalog.getString(
                            'The selected date range should not exceed 53 weeks'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <RangePicker
                  size="large"
                  picker="week"
                  format={customWeekFormat}
                />
              </Form.Item>
              <Form.Item name="user">
                <StyledSelect
                  defaultValue={getTextCatalog.getString('Users')}
                  size="large"
                  style={{ width: 280 }}
                  options={userOptions}
                />
              </Form.Item>
              <Button
                size="large"
                onClick={onExportTimeRegistrationReport}
                style={{ marginBottom: '24px' }}
                type="primary"
                htmlType="submit"
                disabled={!hasChanges}
              >
                {getTextCatalog.getString('Export')}
              </Button>
            </Space>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
