import React from 'react';

import getTextCatalog from '@/react/services/I18nService';
import { COLOR_INDEX } from '@/app/ResourceColors';

export const renderColor = ({
  color,
  size,
}: { color?: number; size?: number }) => (
  <div
    style={{
      backgroundColor: COLOR_INDEX[color] || COLOR_INDEX[9],
      width: size || 12,
      height: size || 12,
      borderRadius: (size || 12) / 2,
    }}
  ></div>
);
export const getTagItemText = ({
  type,
  count,
}: { type: string; count: number }) => {
  switch (type) {
    case 'user':
      if (count === 1) {
        return getTextCatalog.getString('1 user');
      } else {
        return getTextCatalog.getString('{{ n }} users', { n: count });
      }
    case 'group':
      if (count === 1) {
        return getTextCatalog.getString('1 group');
      } else {
        return getTextCatalog.getString('{{ n }} groups', { n: count });
      }
    case 'event':
      if (count === 1) {
        return getTextCatalog.getString('1 event');
      } else {
        return getTextCatalog.getString('{{ n }} events', { n: count });
      }
    case 'absence':
      if (count === 1) {
        return getTextCatalog.getString('1 absence');
      } else {
        return getTextCatalog.getString('{{ n }} absences', { n: count });
      }
    default:
      return '';
  }
};

export const getPlaceHolderText = (type: string) => {
  switch (type) {
    case 'user':
      return getTextCatalog.getString('User');
    case 'group':
      return getTextCatalog.getString('Group');
    case 'event':
      return getTextCatalog.getString('Event');
    case 'absence':
      return getTextCatalog.getString('Absence');
    default:
      return;
  }
};
