import { map } from 'lodash';
import { useRecoilValueLoadable } from 'recoil';
import { Form, Input, Select, Space } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import gettextCatalog from '../services/I18nService';
import { UserChurchesByPermissionQuery } from '../user/store/permissions';

import {
  ConflictingPersonWithRelation,
  ContactInformationType,
} from './services/people.service';
import ConflictingPeopleRelationComponent from './components/ConflictingPeopleRelationComponent';

import AntdInputPhone from '@/react/shared/components/AntdPhoneInput';

const SimplePeopleForm: FunctionComponent<any> = ({
  person,
  prefixOptions,
  createMode,
  registerValidationHook,
  registerFetchValuesHook,
}) => {
  const [form] = Form.useForm();
  const churchesLoadable = useRecoilValueLoadable(
    UserChurchesByPermissionQuery({
      permissionContext: 'people',
      permissionType: 'create',
    })
  );

  const [conflictingPeopleByEmail, setConflictingPeopleByEmail] = useState<
    ConflictingPersonWithRelation[]
  >([]);
  const [conflictingPeopleByPhone, setConflictingPeopleByPhone] = useState<
    ConflictingPersonWithRelation[]
  >([]);

  const churches = useMemo(
    () =>
      churchesLoadable.state === 'hasValue' ? churchesLoadable.contents : [],
    [churchesLoadable.contents, churchesLoadable.state]
  );

  const showChurchSelector = churches && churches.length > 1;
  const churchIds = map(churches, 'id');
  const conflictingPeopleByEmailIds = map(
    conflictingPeopleByEmail,
    'conflictingPerson.id'
  );
  const conflictingPeopleByPhoneIds = map(
    conflictingPeopleByPhone,
    'conflictingPerson.id'
  );
  const prefixOptionsHtml = map(prefixOptions, (prefixOption, key) => (
    <Select.Option value={key} key={key}>
      {prefixOption}
    </Select.Option>
  ));
  const validate = useCallback(
    () =>
      form.validateFields().then((values) => {
        if (values?.errorFields) return false;
        return true;
      }),
    [form]
  );

  const getValues = useCallback(() => {
    const payload = form.getFieldsValue();
    // Set the selected churches
    const selectedChurches = showChurchSelector
      ? payload.churches
      : map(churches, 'id');
    payload.churches = map(selectedChurches, (church) => ({ id: church }));

    const relations: ConflictingPersonWithRelation[] = [];

    // Process conflicts with contacts sharing the selected email address
    if (conflictingPeopleByEmail.length > 0) {
      relations.push(...conflictingPeopleByEmail);
    }

    // Process conflicts with contacts sharing the selected phone number
    if (conflictingPeopleByPhone.length > 0) {
      relations.push(...conflictingPeopleByPhone);
    }

    if (payload.phone?.code && payload.phone.phone) {
      payload.phone = '+' + payload.phone.code + payload.phone.phone;
    } else if (createMode) {
      payload.phone = undefined;
    }

    // Set the person relations based on conflicting contact data
    if (relations.length > 0) {
      payload.relations = relations;
    }
    if (prefixOptionsHtml.length > 0) {
      if (!payload.prefix) {
        payload.prefix = '';
      }
    }
    return payload;
  }, [
    churches,
    conflictingPeopleByEmail,
    conflictingPeopleByPhone,
    createMode,
    form,
    prefixOptionsHtml.length,
    showChurchSelector,
  ]);

  useEffect(() => {
    if (person.id) {
      form.setFieldsValue({
        prefix: person.prefix,
        firstName: person.firstName,
        lastName: person.lastName,
        churches: map(person.churches, 'id'),
      });
    }
  }, [form, person]);

  useEffect(() => {
    /**
     * Register functions on the parent, each time any of the fields that live outside the
     * form are updated. The reason to do this is that, when registering the hooks, a
     * closure is generated with the current state of the respective function (in these
     * cases "validate" and "getValues"), and at that point, no value for the properties
     * not being tracked by the form ("churches", etc.) is defined. By registering the hooks
     * each time the values of these non-tacked properties change, it is ensured that the
     * closure with the latest values is always registered.
     */
    registerValidationHook(validate);
    registerFetchValuesHook(getValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    churchIds,
    conflictingPeopleByEmailIds,
    conflictingPeopleByPhoneIds,
    getValues,
    validate,
  ]);

  const setConflictingPeopleByEmailSelectedValues = (
    selectedValues: ConflictingPersonWithRelation[]
  ): void => {
    setConflictingPeopleByEmail(selectedValues);
  };

  const setConflictingPeopleByPhoneSelectedValues = (
    selectedValues: ConflictingPersonWithRelation[]
  ): void => {
    setConflictingPeopleByPhone(selectedValues);
  };

  return (
    <Form form={form} layout="vertical" name="create-person">
      {prefixOptionsHtml.length > 0 ? (
        <Form.Item name="prefix" label={gettextCatalog.getString('Title')}>
          <Select allowClear>{prefixOptionsHtml}</Select>
        </Form.Item>
      ) : null}
      <Form.Item
        name="firstName"
        label={gettextCatalog.getString('First name')}
        rules={[{ type: 'string' }]}
      >
        <Input placeholder={gettextCatalog.getString('e.g. John')} />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={gettextCatalog.getString('Last name')}
        rules={[{ type: 'string' }]}
      >
        <Input placeholder={gettextCatalog.getString('e.g. Smith')} />
      </Form.Item>
      {createMode && (
        <>
          <Form.Item
            name="email"
            label={gettextCatalog.getString('E-mail address')}
            rules={[
              {
                type: 'email',
                message: gettextCatalog.getString(
                  'The email address you entered is not valid.'
                ),
              },
            ]}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Input
                placeholder={gettextCatalog.getString(
                  'e.g. john.smith@example.com'
                )}
              />
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => (
                  <ConflictingPeopleRelationComponent
                    emailValue={getFieldValue('email')}
                    phoneValue={
                      getFieldValue('phone') &&
                      `+${
                        getFieldValue('phone').code +
                        getFieldValue('phone').phone
                      }`
                    }
                    valueType={ContactInformationType.EMAIL}
                    shouldListSingleConflictingPeople={false}
                    setSelectedValues={
                      setConflictingPeopleByEmailSelectedValues
                    }
                  />
                )}
              </Form.Item>
            </Space>
          </Form.Item>

          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: '12px' }}
          >
            <AntdInputPhone />
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <ConflictingPeopleRelationComponent
                  emailValue={getFieldValue('email')}
                  phoneValue={
                    getFieldValue('phone') &&
                    `+${
                      getFieldValue('phone').code + getFieldValue('phone').phone
                    }`
                  }
                  valueType={ContactInformationType.PHONE}
                  shouldListSingleConflictingPeople={true}
                  setSelectedValues={setConflictingPeopleByPhoneSelectedValues}
                />
              )}
            </Form.Item>
          </Space>
        </>
      )}
      {showChurchSelector ? (
        <Form.Item
          name="churches"
          label={gettextCatalog.getString('Parish')}
          rules={[
            {
              required: true,
              message: gettextCatalog.getString('Parish is required.'),
            },
          ]}
          extra={gettextCatalog.getString(
            'Only users from selected parishes will have access to the contact.'
          )}
        >
          <Select
            mode="multiple"
            allowClear
            filterOption={(
              input: string,
              option: {
                key: string;
                value: number;
                children: string;
                disabled: boolean;
              }
            ) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={gettextCatalog.getString(
              'Choose one or more parishes...'
            )}
          >
            {map(churches, (church) => (
              <Select.Option
                value={church.id}
                key={church.id}
                disabled={!church.access}
              >
                {church.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : null}
    </Form>
  );
};

export default SimplePeopleForm;
