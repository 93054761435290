import React from 'react';
import styled from 'styled-components';
import { Col, Row, Select, Typography } from 'antd';

import getTextCatalog from '../../../../services/I18nService';

import { TIME_OPTIONS } from './PrepAndCleanUpTimeOptions';
import { FormItemWithAccess } from './FormItemWithAccess';

const PrepAndCleanUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreparationAndCleanUpTime = () => (
  <PrepAndCleanUpWrapper>
    <Row gutter={4} style={{ marginTop: '8px' }}>
      <Col span={12}>
        <FormItemWithAccess
          name={['resourcesLocation', 'prepTimeMinutes']}
          fieldAccessName="preparationStartDate"
          noStyle
        >
          <Select
            data-testid="prep-time"
            placeholder={getTextCatalog.getString('Set preparation time...')}
            allowClear
            options={TIME_OPTIONS()}
            style={{ marginRight: '8px', width: '100%' }}
          />
        </FormItemWithAccess>
      </Col>
      <Col span={12}>
        <FormItemWithAccess
          name={['resourcesLocation', 'cleanupTimeMinutes']}
          noStyle
          fieldAccessName="cleanupEndDate"
        >
          <Select
            data-testid="cleanup-time"
            placeholder={getTextCatalog.getString('Set clean up time...')}
            allowClear
            options={TIME_OPTIONS()}
          />
        </FormItemWithAccess>
      </Col>
    </Row>
    <Typography.Text type="secondary">
      {getTextCatalog.getString(
        'Prevent others from booking the resources before and after the event.'
      )}
    </Typography.Text>
  </PrepAndCleanUpWrapper>
);

export default PreparationAndCleanUpTime;
