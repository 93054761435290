import { selectorFamily } from 'recoil';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';

export const GetFile = selectorFamily({
  key: 'GetFile',
  get: (fileId: number) => async () => {
    if (!fileId) return;
    const response = await mainApi.get(`/files/${fileId}`);

    if (!response.ok) {
      NotificationService.notifyError((response as any)?.data?.message);
      throw response;
    }
    return response.data;
  },
});
