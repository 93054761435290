import React from 'react';
import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import useCreatePortalCategory from '../hooks/use-create-portal-category';
import getTextCatalog from '../../services/I18nService';
import { PortalCategoryType } from '../state/portal';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';

export const CreatePortalCategoryModal = createCdModal<{
  portalId: string;
  categoryType: PortalCategoryType;
  onFinish: any;
}>({
  modalName: 'CreatePortalCategoryModal',
  InnerModal: ({ setModalProps, portalId, categoryType, onFinish }) => {
    const { form } = useCreatePortalCategory(
      setModalProps,
      portalId,
      categoryType,
      onFinish
    );

    return (
      <Form name="createPortalCategory" form={form}>
        <Form.Item
          label={getTextCatalog.getString('Name')}
          name="name"
          rules={[
            {
              required: true,
              message: getTextCatalog.getString(
                'Please input the category name!'
              ),
            },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={getTextCatalog.getString('Description')}
          name="description"
          extra={getTextCatalog.getString('Maximum 255 characters')}
          rules={[
            {
              max: 255,
              message: getTextCatalog.getString('Maximum characters reached!'),
            },
          ]}
          labelCol={{ span: 6 }}
        >
          <TextArea />
        </Form.Item>
      </Form>
    );
  },
});
