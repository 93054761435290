import React, { FunctionComponent, Suspense, useEffect, useState } from 'react';
import { Divider, Modal, Space, Switch, Typography, Form } from 'antd';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { useOrganization } from '../../organization/hooks/useOrganization';
import { NewsletterandFilterDataInterface } from '../store/newsletterListState';

import NewsletterListForm from './NewsletterListForm';

import useNewsletterList from '@/react/people/hooks/useNewsletterList';
import { ChurchSettingsState } from '@/react/organization/store/church';
import { CdCheckIcon, CdClose } from '@/react/shared/components/Icons';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import gettextCatalog from '@/react/services/I18nService';

const { Text, Title } = Typography;
const Container = styled.div`
  margin: 24px 32px 16px;
`;
const NewsletterSignupWidgetForm = styled(Form)`
  &&&& {
    display: flex;
    flex-direction: column;
  }
`;
const CreateNewsletterListModal = NiceModal.create(() => (
  <CreateNewsletterList />
));
const CreateNewsletterList: FunctionComponent = () => {
  const modal = useModal('CreateNewsletterList');
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] =
    useState<NewsletterandFilterDataInterface>();
  const { createList } = useNewsletterList();
  const { updateSettings } = useOrganization();
  const [newsletterSignupWidgetPartForm] = Form.useForm();
  const churchSettingInformation = useRecoilValue(ChurchSettingsState);

  useEffect(() => {
    if (churchSettingInformation) {
      newsletterSignupWidgetPartForm.setFieldsValue(churchSettingInformation);
    }

    // eslint-disable-next-line
  }, [churchSettingInformation]);

  const showConfirm = (
    filter: NewsletterandFilterDataInterface,
    name: string,
    setup: { churchIds: number[] },
    filterIds: number[],
    type: string,
    parent: string,
    newsletterWidgetEnabled: boolean
  ) => {
    NiceModal.show('ConfirmRegisterConsentOnContacts', {
      filter,
      name,
      setup,
      filterIds,
      type,
      parent,
      newsletterWidgetEnabled,
    });
  };

  const createNewsletterListHandle = async () => {
    if (form.getFieldValue('filterIds')) {
      showConfirm(
        selectedFilter,
        form.getFieldValue('name'),
        form.getFieldValue('setup'),
        form.getFieldValue('filterIds'),
        'newsletter',
        'createNewsletterModal',
        churchSettingInformation?.newsletterWidgetEnabled === false &&
          newsletterSignupWidgetPartForm.getFieldValue(
            'newsletterWidgetEnabled'
          )
      );
    } else {
      await createList(
        form.getFieldValue('name'),
        form.getFieldValue('setup'),
        undefined,
        'newsletter'
      );
      if (!churchSettingInformation?.newsletterWidgetEnabled) {
        await updateSettings({
          newsletterWidgetEnabled: newsletterSignupWidgetPartForm.getFieldValue(
            'newsletterWidgetEnabled'
          ),
        });
      }
    }
  };

  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        maskClosable: false,
        title: gettextCatalog.getString('Create newsletter list'),
        bodyStyle: { padding: 0 },
        onCancel: modal.remove,
        okText: gettextCatalog.getString('Create'),
        confirmLoading,
        onOk: async () => {
          try {
            setConfirmLoading(true);
            await createNewsletterListHandle();
            await modal.resolve();
            modal.remove();
          } catch (error) {
            setConfirmLoading(false);
          }
        },
      }}
    >
      <Container>
        <NewsletterListForm form={form} setSelectedFilter={setSelectedFilter} />

        {!churchSettingInformation?.newsletterWidgetEnabled && (
          <NewsletterSignupWidgetForm
            layout="vertical"
            form={newsletterSignupWidgetPartForm}
          >
            <Divider />
            <Space style={{ display: 'flex', alignContent: 'center' }}>
              <Form.Item
                name="newsletterWidgetEnabled"
                valuePropName="checked"
                initialValue={false}
                noStyle
              >
                <Switch
                  checkedChildren={<CdCheckIcon />}
                  unCheckedChildren={<CdClose />}
                />
              </Form.Item>
              <Title level={4}>
                {gettextCatalog.getString(
                  'Add the newsletter sign-up widget to your website'
                )}
              </Title>
            </Space>

            <Text style={{ color: '#899499' }}>
              {gettextCatalog.getString(
                `If disabled the newsletter widget will not load on your website even when installed`
              )}
            </Text>
          </NewsletterSignupWidgetForm>
        )}
      </Container>
    </Modal>
  );
};
const CreateNewsletterListModalParent = (props) => (
  <Suspense fallback={<CdrPageLoader />}>
    <CreateNewsletterListModal {...props} />
  </Suspense>
);
export default CreateNewsletterListModalParent;
