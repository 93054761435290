import React, { FunctionComponent, Suspense } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import gettextCatalog from '@/react/services/I18nService';
import { TimeRegistrationReport } from '@/react/calendar/reports/TimeRegistrationReport';
import { SearchCalendar } from '@/react/calendar/reports/SearchCalendar';
import { navigate } from '@/react/services/StateServiceFactory';
import AuthorizationService from '@/react/services/AuthorizationService';
import { ReportsProps } from '@/react/calendar/reports/types/report.types';

const AllReports = ({ filters, activeTab }) => {
  const title = gettextCatalog.getString('Reports');
  const subTitle = gettextCatalog.getString(
    'You can check and download all reports here'
  );
  const canAccessTimeRegistration = AuthorizationService.hasPermission(
    'canAccessTimeRegistration'
  );
  const items: TabsProps['items'] = [
    {
      key: 'search',
      label: gettextCatalog.getString('Search'),
      children: <SearchCalendar filters={filters} tabKey="search" />,
    },
    {
      key: 'activityList',
      label: gettextCatalog.getString('Worship overview'),
      children: <SearchCalendar filters={filters} tabKey="activityList" />,
    },
    {
      key: 'timeRegistration',
      label: gettextCatalog.getString('Time registration'),
      children: <TimeRegistrationReport filters={filters} />,
      disabled: !canAccessTimeRegistration,
    },
    {
      key: 'absence',
      label: gettextCatalog.getString('Absence'),
      children: <SearchCalendar filters={filters} tabKey="absence" />,
    },
  ];

  const tabClickHandler = (key: string) =>
    navigate('app.private.calendar.print', {
      default: undefined,
      searchText: undefined,
      startDate: undefined,
      endDate: undefined,
      groupSelect: undefined,
      categorySelect: undefined,
      resourceSelect: undefined,
      selectedEventsWithoutResourcesInChurchIds: undefined,
      churchSelect: undefined,
      userSelect: undefined,
      // time registration params
      startWeekNumber: undefined,
      startYear: undefined,
      endWeekNumber: undefined,
      endYear: undefined,
      timeRegistrationUserSelect: undefined,
      // current tab
      current: key,
    });

  return (
    <CdPage
      pageHeaderProps={{
        title,
        subTitle,
      }}
    >
      <Tabs
        type="card"
        tabBarStyle={{ marginBottom: 0 }}
        tabBarGutter={0}
        defaultActiveKey={activeTab}
        items={items}
        onTabClick={tabClickHandler}
      />
    </CdPage>
  );
};

export const Reports: FunctionComponent<ReportsProps> = ({ $stateParams }) => (
  <Suspense fallback={<CdrPageLoader />}>
    <AllReports filters={$stateParams} activeTab={$stateParams.current} />
  </Suspense>
);
