import { Form, Input, Radio, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import styled from 'styled-components';

import { PeopleFiltersWhereUserCanAddToList } from '../store/newsletterListState';

import { UserChurchesWithPermissionQuery } from '@/react/user/store/permissions';
import { HasMultipleChurches } from '@/react/user/store/user-session';
import gettextCatalog from '@/react/services/I18nService';

const RadioButtonArea = styled(Radio.Group)`
  &&&& {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    row-gap: '4px';
  }
`;

const { Option } = Select;
const NewsletterListForm = ({ form, setSelectedFilter }) => {
  const hasMultipleChurches = useRecoilValue(HasMultipleChurches);
  const parishesLoadable = useRecoilValueLoadable(
    UserChurchesWithPermissionQuery({
      permissionContext: 'people',
      permissionType: 'access',
    })
  );
  const filtersLoadable = useRecoilValueLoadable(
    PeopleFiltersWhereUserCanAddToList
  );

  const parishes =
    parishesLoadable.state === 'hasValue' ? parishesLoadable.contents : [];

  const [radioButtonsValue, setRadioButtonsValue] = useState(1);

  const [enableFilter, setEnableFilter] = useState(true);

  const filters =
    filtersLoadable.state === 'hasValue' ? filtersLoadable.contents : [];

  const loading =
    filtersLoadable.state === 'loading' || parishesLoadable.state === 'loading';
  const onToggleFilter = (e) => {
    setRadioButtonsValue(e.target.value);
    if (radioButtonsValue === 2) {
      setEnableFilter(true);
      form.setFieldsValue({
        filterIds: undefined,
      });
    } else {
      setEnableFilter(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      setup: {
        churchIds: parishes.map((p) => p.id),
      },
    });

    // eslint-disable-next-line
  }, [parishes]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFieldsChange={() => {
        if (form.getFieldValue('filterIds')) {
          setSelectedFilter(
            filters.find(
              (filter) => filter.id === form.getFieldValue('filterIds')
            )
          );
        }
      }}
    >
      <Spin spinning={loading}>
        <Form.Item
          name="name"
          label={gettextCatalog.getString('Title')}
          rules={[
            {
              required: true,
              message: gettextCatalog.getString('Please input a title.'),
            },
          ]}
          help={gettextCatalog.getString(
            'This is the title as it will appear on where people subscribe to your newsletters'
          )}
          style={{ width: '100%', marginBottom: '24px' }}
        >
          <Input />
        </Form.Item>
        {filters?.length > 0 && (
          <Form.Item
            label={gettextCatalog.getString(
              'Will you start by adding contacts as subscribers to this newsletter list?'
            )}
          >
            <RadioButtonArea
              onChange={onToggleFilter}
              value={radioButtonsValue}
            >
              <Radio value={1} style={{ color: '#4F4F4F' }}>
                {gettextCatalog.getString(
                  'No. Create the newsletter without adding subscribers'
                )}
              </Radio>
              <Radio value={2} style={{ color: '#4F4F4F' }}>
                {gettextCatalog.getString(
                  'Yes. Add contacts as subscribers from a current filter'
                )}
              </Radio>
            </RadioButtonArea>

            <Form.Item name="filterIds" noStyle>
              <Select
                placeholder={gettextCatalog.getString(
                  'Select filter for adding contacts as subscribers'
                )}
                disabled={enableFilter}
                style={{ width: '100%' }}
                allowClear
              >
                {filters?.map((filter) => (
                  <Option value={filter.id} key={filter.id}>
                    {filter.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
        )}

        {hasMultipleChurches && (
          <>
            <Form.Item
              name={['setup', 'churchIds']}
              label={gettextCatalog.getString(
                'Parish access and contact creation'
              )}
              help={gettextCatalog.getString(
                'Choose which parish(es) should be able to use this newsletter list. When a new contact signs-up to this newsletter the contact will be associated with the selected parishes.'
              )}
            >
              <Select
                placeholder={gettextCatalog.getString('Please select')}
                mode="multiple"
                style={{ width: '100%' }}
              >
                {parishes.map((parish, i) => (
                  <Option value={parish.id} key={parish.name + `${i}`}>
                    {parish.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
      </Spin>
    </Form>
  );
};

export default NewsletterListForm;
