import { atom, selector, selectorFamily } from 'recoil';

import { mainApi } from '@/react/api';
import { EventsRotaTableValue } from '@/react/calendar/event-details/components/rotas/EventsRotaTable';
import { OrganizationUsersCache } from '@/react/organization/store/organization';
import { SlimUser } from '@/react/organization/types/organization';
import { handleError } from '@/react/services/ErrorHandlingService';

export const GetRotaDuties = selector({
  key: 'GetRotaDuties',
  get: async () => {
    const res =
      await mainApi.get<
        { group: object; groupId: number; id: number; title: string }[]
      >('/tasks');
    if (res.ok) {
      return res.data;
    } else {
      handleError(res);
      throw res;
    }
  },
});

export const GetAssignableUsersForSpecificRotaDuty = selectorFamily<
  SlimUser[],
  number
>({
  key: 'GetAssignableUsersForSpecificRotaDuty',
  get:
    (taskId: number) =>
    async ({ get }) => {
      get(OrganizationUsersCache);
      const res = await mainApi.get<SlimUser[]>(
        `/tasks/${taskId}/assignable-users`
      );
      if (res.ok) {
        return res.data;
      } else {
        handleError(res);
        throw res;
      }
    },
});

export const RotaTableInitialRRuleValue = atom<string | undefined>({
  key: 'RotaTableInitialRRuleValue',
  // null so we can detect whether this has been set by the form.
  default: null,
});

export const RotaTableState = atom<EventsRotaTableValue>({
  key: 'RotaTableState',
  // null so we can detect whether this has been set by the form.
  default: null,
});

export const AssignedIntentionsAtom = atom<{
  items: EventsRotaTableValue['shiftsAndIntentions'][number]['intentions'];
  total: number;
}>({
  key: 'AssignedIntentionsAtom',
  default: { items: [], total: 0 },
});
