import styled from 'styled-components';
import { Col, Row, Select, Space } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  chain,
  debounce,
  each,
  find,
  isEmpty,
  isFinite,
  map,
  size,
} from 'lodash';

import PeopleService, {
  ContactInformationType,
  ConflictingPersonWithRelation,
} from '../services/people.service';
import PeopleRelationService from '../services/people-relation.service';
import gettextCatalog from '../../services/I18nService';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import {
  People,
  PeopleRelation,
  PeopleRelationType,
} from '../../shared/models/people';

import PersonInformationComponent from './PersonInformationComponent';

const { Option } = Select;

interface SelectOption {
  value: string;
  label: string;
}

const ConflictingPeopleRelationComponent: FunctionComponent<any> = ({
  emailValue,
  phoneValue,
  valueType,
  shouldListSingleConflictingPeople,
  omittingPersonId,
  existingRelations,
  setSelectedValues,
}: {
  emailValue: string;
  phoneValue: string;
  valueType: ContactInformationType;
  shouldListSingleConflictingPeople: boolean;
  omittingPersonId: number;
  existingRelations: PeopleRelation[];
  setSelectedValues: (selectedValues: ConflictingPersonWithRelation[]) => void;
}) => {
  const relations: SelectOption[] = PeopleRelationService.getRelations();

  const [conflictingPeople, setConflictingPeople] = useState<People[]>([]);
  const [selectedRelations, setSelectedRelations] = useState<
    Map<number, PeopleRelationType>
  >(new Map());

  const [conflictingPeopleMessage, setConflictingPeopleMessage] =
    useState<string>(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  const valueUpdateDebouncer = useCallback(
    debounce((updatedEmailValue: string, updatedPhoneValue: string) => {
      if (isEmpty(updatedEmailValue) && isEmpty(updatedPhoneValue)) return;

      const params: any = {};
      if (!isEmpty(updatedEmailValue)) {
        params.email = updatedEmailValue;
      }
      if (!isEmpty(updatedPhoneValue)) {
        params.phone = updatedPhoneValue;
      }
      if (isFinite(omittingPersonId)) {
        params.omittingPersonId = omittingPersonId;
      }

      // Check for conflicting people via API call
      PeopleService.checkConflictingPeople(params)
        .then((conflictingPeople) => {
          const filteredConflictingPeople = chain(conflictingPeople)
            .filter((conflictingPerson) => {
              if (
                valueType === ContactInformationType.EMAIL &&
                conflictingPerson.isEmailConflict
              ) {
                return shouldListSingleConflictingPeople
                  ? !conflictingPerson.isPhoneConflict
                  : true;
              }
              if (
                valueType === ContactInformationType.PHONE &&
                conflictingPerson.isPhoneConflict
              ) {
                return shouldListSingleConflictingPeople
                  ? !conflictingPerson.isEmailConflict
                  : true;
              }
              return false;
            })
            .filter(
              /**
               * Check whether the conflicting people are one of the existing related people and if so,
               * exclude them from the conflicting contacts, since the purpose of this component is to
               * provide relations between unrelated people that share contact information.
               * HOWEVER, if an existing contact has a "unspecified" relation, include them in the list
               * of conflicting people, so as to give the user the opportunity to upgrade that relation.
               */
              (conflictingPerson) =>
                !find(existingRelations, (existingRelation) => {
                  const isPersonMatch =
                    existingRelation.person.personId ===
                    conflictingPerson.person.id;
                  const isRelationSpecified =
                    existingRelation.relation !==
                    PeopleRelationType.UNSPECIFIED;
                  return isPersonMatch && isRelationSpecified;
                })
            )
            .map('person')
            .value();

          const preSelectedRelations = new Map<number, PeopleRelationType>();
          each(filteredConflictingPeople, (filteredConflictingPerson) => {
            preSelectedRelations.set(
              filteredConflictingPerson.id,
              PeopleRelationType.UNSPECIFIED
            );
          });

          const noOfConflictingPeople = size(filteredConflictingPeople);
          let conflictMessage = null;
          if (valueType === ContactInformationType.EMAIL) {
            conflictMessage = gettextCatalog.getPlural(
              noOfConflictingPeople,
              'It looks like a contact with the specified email address {{value}} already exists.',
              'It looks like contacts with the specified email address {{value}} already exist.',
              { value: updatedEmailValue }
            );
          } else if (valueType === ContactInformationType.PHONE) {
            conflictMessage = gettextCatalog.getPlural(
              noOfConflictingPeople,
              'It looks like a contact with the specified phone number {{value}} already exists.',
              'It looks like contacts with the specified phone number {{value}} already exist.',
              { value: updatedPhoneValue }
            );
          }
          setConflictingPeopleMessage(conflictMessage);

          // Update the state accordingly
          setConflictingPeople(filteredConflictingPeople);
          setSelectedRelations(preSelectedRelations);

          setSelectedValues(
            mapConflictingPeople(
              filteredConflictingPeople,
              preSelectedRelations
            )
          );
        })
        .catch(ErrorHandlingService.handleError);
    }, 1000),
    /* eslint-disable react-hooks/exhaustive-deps */
    []
  );

  // Monitor the value provided via props so as to perform cleaning
  useEffect(() => {
    if (!isEmpty(emailValue) || !isEmpty(phoneValue)) {
      valueUpdateDebouncer(emailValue, phoneValue);
    } else {
      /**
       * If no value has been specified, set the conflicting person to "null" so as to hide the corresponding
       * alert section.
       * Set also the flag for indicating that the conflicting person already sharing contact information to
       * "false", so as to hide the corresponding alert section.
       */
      setConflictingPeople([]);
      setSelectedRelations(new Map());
      setSelectedValues([]);
    }
  }, [emailValue, phoneValue]);

  const mapConflictingPeople = (
    conflictingPeopleToMap: People[],
    selectedRelationsToMap: Map<number, PeopleRelationType>
  ): ConflictingPersonWithRelation[] =>
    map(conflictingPeopleToMap, (conflictingPersonToMap) => ({
      type: valueType,
      conflictingPerson: conflictingPersonToMap,
      relation: selectedRelationsToMap.get(conflictingPersonToMap.id),
    }));

  const updateSelectedRelation = (
    personId: number,
    newSelectedRelation: PeopleRelationType
  ): void => {
    const newSelectedRelations = new Map<number, PeopleRelationType>(
      selectedRelations
    );
    newSelectedRelations.set(personId, newSelectedRelation);
    setSelectedRelations(newSelectedRelations);

    // Update the selected relations to the conflicting people
    setSelectedValues(
      mapConflictingPeople(conflictingPeople, newSelectedRelations)
    );
  };

  if (!isEmpty(conflictingPeople)) {
    return (
      <ConflictingPeopleRow>
        <ConflictingPeopleMessageCol>
          <strong>{conflictingPeopleMessage}</strong>
        </ConflictingPeopleMessageCol>
        {conflictingPeople.map((conflictingPerson) => (
          <ConflictingPeoplePanelDiv key={conflictingPerson.id}>
            <ConflictingPeopleData>
              <Col span={24}>
                <PersonInformationComponent person={conflictingPerson} />
                <ConflictingPeopleRelationSelectionRow>
                  <Col span={24}>
                    <RelationSelectionSpace direction="vertical">
                      <strong>
                        {gettextCatalog.getString(
                          'Is this a family member that shares contact info?'
                        )}
                      </strong>
                      <ConflictingPeopleRelationSelect
                        value={selectedRelations.get(conflictingPerson.id)}
                        onChange={(newSelectedRelation: PeopleRelationType) =>
                          updateSelectedRelation(
                            conflictingPerson.id,
                            newSelectedRelation
                          )
                        }
                        allowClear
                      >
                        {relations.map((relation) => (
                          <Option key={relation.value} value={relation.value}>
                            {relation.label}
                          </Option>
                        ))}
                      </ConflictingPeopleRelationSelect>
                    </RelationSelectionSpace>
                  </Col>
                </ConflictingPeopleRelationSelectionRow>
              </Col>
            </ConflictingPeopleData>
          </ConflictingPeoplePanelDiv>
        ))}
      </ConflictingPeopleRow>
    );
  }

  return null;
};

const ConflictingPeopleRow = styled(Row)`
  &&&& {
    color: #777;
  }
`;

const ConflictingPeopleMessageCol = styled(Col)`
  &&&& {
    padding: 8px 0px;
  }
`;

const ConflictingPeoplePanelDiv = styled.div`
  &&&& {
    margin-top: 8px;
    width: 100%;
  }
`;

const ConflictingPeopleData = styled(Row)`
  &&&& {
    padding: 16px;
    border: 1px solid #e5e5e5;
  }
`;

const ConflictingPeopleRelationSelectionRow = styled(Row)`
  &&&& {
    padding-top: 16px;
  }
`;

const ConflictingPeopleRelationSelect = styled(Select)`
  &&&& {
    width: 100%;
  }
`;

const RelationSelectionSpace = styled(Space)`
  &&&& {
    width: 100%;
  }
`;

export default ConflictingPeopleRelationComponent;
