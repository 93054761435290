import React, { Suspense, useCallback } from 'react';
import { Select, Form, Card, Space, Input } from 'antd';

import getTextCatalog from '@/react/services/I18nService';
import useOrganizations from '@/react/organization/hooks/useOrganizations';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import {
  PeopleMessageContributions,
  PeopleMessageContributionsType,
} from '@/react/people/types/message';
import { useContributionsSearch } from '@/react/people/hooks/useContributions';
import { ContributionsByTypeState } from '@/react/shared/store/contributions';
import AuthorizationService from '@/react/services/AuthorizationService';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';

const formColumns = () => [
  {
    title: getTextCatalog.getString('Title'),
    dataIndex: 'title',
    key: 'title',
    sorter: true,
    active: true,
  },
];

interface ContributionsTableProps {
  onRowsChange?: (contributions: PeopleMessageContributions[]) => void;
}

const ContributionsTable = ({ onRowsChange }: ContributionsTableProps) => (
  <CdSimpleTable<PeopleMessageContributions>
    emptyStateProps={{
      title: getTextCatalog.getString('No contributions found'),
    }}
    recoilSource={ContributionsByTypeState(
      PeopleMessageContributionsType.DONATION
    )}
    columns={formColumns()}
    rowSelection={{
      type: 'checkbox',
      onChange: (_id, contributions) => onRowsChange(contributions),
    }}
  />
);

export default function ContributionsSearchForm({ form }) {
  const { organizations, setSearchId, searchId, searchByName } =
    useOrganizations();

  const {
    debounce: { searchContributions },
  } = useContributionsSearch();

  const handleSearch = useCallback(
    ({ title }) => searchContributions(title),
    [searchContributions]
  );

  const hasCollaborationPackage =
    AuthorizationService.hasPackage('collaboration');

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card>
          <Form layout="vertical" onValuesChange={handleSearch}>
            {hasCollaborationPackage && (
              <Form.Item label={getTextCatalog.getString('Organization')}>
                <Select<number>
                  showSearch
                  value={searchId}
                  onSearch={searchByName}
                  filterOption
                  optionFilterProp="label"
                  onChange={setSearchId}
                  options={organizations.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )}
            <Form.Item name="title">
              <Input placeholder={getTextCatalog.getString('Search')} />
            </Form.Item>
          </Form>
        </Card>
        <Form form={form}>
          <Suspense fallback={<CdrPageLoader />}>
            <Form.Item
              name="contributions"
              trigger="onRowsChange"
              valuePropName="selectedKeys"
            >
              <ContributionsTable />
            </Form.Item>
          </Suspense>
        </Form>
      </Space>
    </>
  );
}
