import React, { useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';

import gettextCatalog from '../../services/I18nService';
import {
  courseTypes,
  documentTypes,
  documentWhereaboutsTypes,
  fileUploadTypes,
} from '../types/SafeguardingTypes';
import { getFileUploadModalTitle } from '../common/helpers';
import { useSafeguardingFileUpload } from '../hooks/useSafeguardingFileUpload';
import UploadFile from '../../people/components/UploadFile';
import { useSafeguardingAddRecord } from '../hooks/useSafeguardingAddRecord';
import { createCdModal } from '../../shared/components/cd-modal-new/CdModal2';

export const SafeguardingFileUploadModal = createCdModal<{
  currentUserId: number;
  fileType: string;
}>({
  modalName: 'SafeguardingFileUploadModal',
  InnerModal: ({ setModalProps, currentUserId, fileType }) => {
    const { uploadFile, userOptions } = useSafeguardingFileUpload();

    const {
      fileUploadForm,
      onFileUploadFormFinish,
      fileUploadFormInitialValue,
    } = useSafeguardingAddRecord(currentUserId, fileType);

    useEffect(() => {
      setModalProps({
        title: getFileUploadModalTitle(fileType),
        okText: gettextCatalog.getString('Save'),
        onOk: onFileUploadFormFinish,
      });
    }, [fileType, onFileUploadFormFinish, setModalProps]);

    return (
      <Form
        layout="vertical"
        form={fileUploadForm}
        initialValues={fileUploadFormInitialValue}
      >
        <Row gutter={12}>
          {/* general info for all file upload types */}
          <Col span={12}>
            <Form.Item
              name="reviewedBy"
              label={gettextCatalog.getString('Reviewed by')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                options={userOptions}
                optionFilterProp="label"
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="reviewDate"
              label={gettextCatalog.getString('Date of review')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <DatePicker
                placeholder={gettextCatalog.getString('Select review date')}
                style={{ width: '100%' }}
                format={gettextCatalog.getLongDateFormat()}
              />
            </Form.Item>
          </Col>
          {/* specific info for file upload type certificateOfConduct- Document whereabouts, Date of follow-up */}
          {fileType === fileUploadTypes.CERTIFICATE_OF_CONDUCT && (
            <Col span={24}>
              <Form.Item
                name="documentWhereabouts"
                label={gettextCatalog.getString('Document status')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={gettextCatalog.getString(
                    'Select document status'
                  )}
                  options={documentWhereaboutsTypes()}
                ></Select>
              </Form.Item>
            </Col>
          )}
          {fileType === fileUploadTypes.CERTIFICATE_OF_CONDUCT && (
            <Col span={24}>
              <Form.Item
                name="refreshDate"
                label={gettextCatalog.getString('Date of expiration')}
                extra={gettextCatalog.getString(
                  'You need to provide a expiration date for certificate of conduct.'
                )}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <DatePicker
                  placeholder={gettextCatalog.getString(
                    'Select date of expiration'
                  )}
                  style={{ width: '100%' }}
                  format={gettextCatalog.getLongDateFormat()}
                />
              </Form.Item>
            </Col>
          )}
          {fileType === fileUploadTypes.CERTIFICATE_OF_CONDUCT && (
            <Col span={24}>
              <Form.Item
                name="comments"
                label={gettextCatalog.getString('Comments')}
                extra={gettextCatalog.getString(
                  'Please write your comments here. Maximum 250 characters.'
                )}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                  {
                    max: 250,
                    message: 'Comment can not exceed 250 characters',
                  },
                ]}
              >
                <Input.TextArea
                  autoSize
                  placeholder={gettextCatalog.getString('Comments')}
                />
              </Form.Item>
            </Col>
          )}
          {/* specific info for file upload type documents - documetntType, file  */}
          {fileType === fileUploadTypes.DOCUMENT && (
            <Col span={24}>
              <Form.Item
                name="documentType"
                label={gettextCatalog.getString('Document type')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={gettextCatalog.getString(
                    'Select type of document'
                  )}
                  options={documentTypes()}
                />
              </Form.Item>
            </Col>
          )}
          {fileType === fileUploadTypes.DOCUMENT && (
            <Col span={24}>
              <Form.Item
                label={gettextCatalog.getString('Date of expiration')}
                name="refreshDate"
                extra={gettextCatalog.getString(
                  'You can leave this blank if expiration date is not required.'
                )}
              >
                <DatePicker
                  placeholder={gettextCatalog.getString(
                    'Select date of expiration'
                  )}
                  style={{ width: '100%' }}
                  format={gettextCatalog.getLongDateFormat()}
                />
              </Form.Item>
            </Col>
          )}
          {fileType === fileUploadTypes.DOCUMENT && (
            <Col span={24}>
              <Form.Item
                name="file"
                label={gettextCatalog.getString('Document')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <UploadFile
                  uploadFile={uploadFile}
                  maxFileSize={10}
                  multiple={false}
                  maxCount={1}
                  showUploadList={true}
                >
                  <Space direction="vertical">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <Typography.Text>
                      {gettextCatalog.getString(
                        'Click or drag file to this area to upload'
                      )}
                    </Typography.Text>
                  </Space>
                </UploadFile>
              </Form.Item>
            </Col>
          )}
          {/* specific info for file upload type courses - Course date, Title, Type, File  */}
          {fileType === fileUploadTypes.COURSE && (
            <Col span={24}>
              <Form.Item
                name="courseDate"
                label={gettextCatalog.getString('Course date')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <DatePicker
                  placeholder={gettextCatalog.getString('Select course date')}
                  style={{ width: '100%' }}
                  format={gettextCatalog.getLongDateFormat()}
                />
              </Form.Item>
            </Col>
          )}
          {fileType === fileUploadTypes.COURSE && (
            <Col span={24}>
              <Form.Item
                name="courseTitle"
                label={gettextCatalog.getString('Course title')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={gettextCatalog.getString('Name of course')}
                />
              </Form.Item>
            </Col>
          )}
          {fileType === fileUploadTypes.COURSE && (
            <Col span={24}>
              <Form.Item
                name="courseType"
                label={gettextCatalog.getString('Course type')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={gettextCatalog.getString('Select course type')}
                  options={courseTypes()}
                />
              </Form.Item>
            </Col>
          )}
          {fileType === fileUploadTypes.COURSE && (
            <Col span={24}>
              <Form.Item
                name="refreshDate"
                label={gettextCatalog.getString('Date of expiration')}
                extra={gettextCatalog.getString(
                  'You can leave this blank if expiration date is not required.'
                )}
              >
                <DatePicker
                  placeholder={gettextCatalog.getString(
                    'Select date of expiration'
                  )}
                  style={{ width: '100%' }}
                  format={gettextCatalog.getLongDateFormat()}
                />
              </Form.Item>
            </Col>
          )}
          {fileType === fileUploadTypes.COURSE && (
            <Col span={24}>
              <Form.Item
                name="file"
                label={gettextCatalog.getString('Course certificate')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <UploadFile
                  uploadFile={uploadFile}
                  maxFileSize={10}
                  showUploadList={true}
                >
                  <Space direction="vertical">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <Typography.Text>
                      {gettextCatalog.getString(
                        'Click or drag file to this area to upload'
                      )}
                    </Typography.Text>
                  </Space>
                </UploadFile>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    );
  },
});
