import React, { useState, FunctionComponent, useEffect } from 'react';
import { Form, Input, Button, Steps, Alert, Row, Col, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { each, isEmpty, map, reduce, get } from 'lodash';

import { ActionCreators } from '../../organization/redux/groups';
import { JobActionCreators } from '../../organization/redux/jobs';
import { AppState } from '../../redux';
import UserService from '../services/user.service';
import gettextCatalog from '../../services/I18nService';
import { FetchUsers } from '../redux/actions';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import UserRolesService from '../services/user-roles.service';
import { getChurches } from '../../shared/store/resources/index';

import AssignUserRoles from './assign-user-roles/AssignUserRoles';
import { TimeRegistrationUserSettings } from './TimeRegistrationUserSettings';

import {
  useOrganization,
  useOrganizationRefreshUserList,
} from '@/react/organization/hooks/useOrganization';
import NotificationService from '@/react/services/NotificationService';
import CdSelect from '@/react/shared/components/cd-select/CdSelect';
import AuthorizationService from '@/react/services/AuthorizationService';

interface CollectionCreateFormProps {
  launchNewGroupModal: any;
  closeModal: any;
  userInfo: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
}

const CreateUserModal = ({
  launchNewGroupModal,
  closeModal,
  userInfo = {},
}: CollectionCreateFormProps) => {
  const [form] = Form.useForm();
  const { refreshUserList } = useOrganizationRefreshUserList();
  const [currentStep, setCurrentStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const groupState = useSelector(
    (state: AppState) => state.organization.groups
  );
  const churches = useSelector(getChurches);
  const dispatch = useDispatch();
  const showMultiChurch = get(window, 'cdApp.showChurchSelector');
  const userFirstName = Form.useWatch(['user', 'firstName']);
  const userEmail = Form.useWatch(['user', 'email']);

  const { organizationSettings } = useOrganization();

  const getSupportLink = () => {
    switch (gettextCatalog.getCurrentLanguage()) {
      case 'da':
        return 'https://support.churchdesk.com/da/category/grupper-14czapd/';
      case 'de':
        return 'https://support.churchdesk.com/de/category/gruppen-16o6jkt/';
      case 'sv':
        return 'https://support.churchdesk.com/da/category/grupper-14czapd/';
      default:
        return 'https://support.churchdesk.com/en/category/groups-brtuz2/';
    }
  };

  useEffect(() => {
    dispatch(ActionCreators.FetchGroups());
    dispatch(JobActionCreators.FetchJobs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prevStep = () => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  };
  const nextStep = () => {
    if (currentStep === 2) return;
    form.validateFields().then((values) => {
      if (!isEmpty(values.errorFields)) return false;
      setCurrentStep(currentStep + 1);
    });
  };

  const cancelModal = () => closeModal();

  const save = async () => {
    await form.validateFields();
    const values = form.getFieldsValue(true);
    if (!isEmpty(values.errorFields)) return false;
    try {
      setIsSaving(true);

      const newUser = await UserService.createUser({
        email: values.user.email,
        ...(values.user.firstName && {
          firstName: values.user.firstName,
        }),
        ...(values.user.lastName && {
          lastName: values.user.lastName,
        }),
        groupIds: values.groups,
        timeRegistrationEnabled: values.timeRegistrationEnabled,
        initialTimeBalance: values.initialTimeBalance,
      });
      let name = `${values.user.firstName} ${values.user.lastName}`;
      if (name.length < 2) name = values.user.email;
      const userId = newUser.id;

      const payload = {};
      if (showMultiChurch) {
        each(values.church, (churchIds, roleId) => {
          each(churchIds, (churchId) => {
            if (!payload[churchId]) {
              payload[churchId] = {
                churchId,
                roles: [],
              };
            }
            payload[churchId].roles.push({ id: roleId });
          });
        });
      } else {
        const churchId = churches[0].id;
        payload[churchId] = {
          churchId,
          roles: [],
        };
        each(values.churchSwitches, (isEnabled, roleId) => {
          if (isEnabled) payload[churchId].roles.push({ id: roleId });
        });
      }

      const payloadAsArray = map(payload, (p) => p);
      // Organization roles
      const organizationRoles = reduce(
        values.organization,
        (accumulator, value, key) => {
          if (value) {
            accumulator.push({ id: key });
          }
          return accumulator;
        },
        []
      );
      await Promise.all([
        UserRolesService.saveUserOldRoles(userId, values.oldRoles),
        !isEmpty(payloadAsArray)
          ? UserRolesService.saveUserChurchRoles(userId, payloadAsArray)
          : Promise.resolve(),
        !isEmpty(organizationRoles)
          ? UserRolesService.saveUserOrganizationRoles(
              userId,
              organizationRoles
            )
          : Promise.resolve(),
      ]);
      dispatch(FetchUsers());
      refreshUserList();
      setIsSaving(false);
      closeModal(newUser);
      NotificationService.notifySuccess(
        gettextCatalog.getString('Invitation is sent!')
      );
    } catch (error) {
      setIsSaving(false);
      ErrorHandlingService.handleError(error);
    }
  };
  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');

  const groupModalWasClosed = () => {
    dispatch(ActionCreators.FetchGroups());
  };
  const onClickLaunchNewGroupModal = () =>
    launchNewGroupModal(groupModalWasClosed);

  const hasNoSeatsLeft =
    organizationSettings.noOfEnabledTimeRegistrations >=
    organizationSettings.timeRegistrationCap;

  const steps = [
    {
      message: (
        <Row gutter={8}>
          <Col flex="auto">
            <Form.Item
              name={['user', 'firstName']}
              label={gettextCatalog.getString('First name')}
            >
              <Input placeholder={gettextCatalog.getString('e.g. John')} />
            </Form.Item>
            <Form.Item
              name={['user', 'lastName']}
              label={gettextCatalog.getString('Last name')}
            >
              <Input placeholder={gettextCatalog.getString('e.g. Smith')} />
            </Form.Item>
            <Form.Item
              name={['user', 'email']}
              label={gettextCatalog.getString('Email')}
              rules={[
                {
                  type: 'email',
                  message: gettextCatalog.getString(
                    'The email address you entered is not valid.'
                  ),
                },
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Input
                placeholder={gettextCatalog.getString(
                  'e.g. john.smith@example.com'
                )}
              />
            </Form.Item>
          </Col>
          <Col flex="10px"></Col>
        </Row>
      ),
    },
    {
      message: (
        <Space size="middle" direction="vertical">
          <Alert
            message={gettextCatalog.getString(
              'Groups enable users to collaborate'
            )}
            description={
              <span>
                {gettextCatalog.getString(
                  'With groups in ChurchDesk it is easy to collaborate on work in and around the church. Each group contains features that facilitate internal communication and collaboration.'
                )}{' '}
                {gettextCatalog.getString('Need more help?')}{' '}
                <a
                  href={getSupportLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {gettextCatalog.getString('Click here.')}
                </a>
              </span>
            }
            type="info"
            showIcon
          />
          <Row gutter={8} align="bottom">
            <Col flex="auto">
              <Form.Item
                name={['groups']}
                label={gettextCatalog.getString('Groups')}
              >
                <CdSelect
                  mode="multiple"
                  placeholder={gettextCatalog.getString(
                    'Choose one or more groups...'
                  )}
                  options={groupState.groups.map((group) => ({
                    value: group.id,
                    label: group.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col flex="130px">
              <Form.Item name={['groupButton']} label="">
                <Button onClick={onClickLaunchNewGroupModal}>
                  {gettextCatalog.getString('New group')}
                </Button>
              </Form.Item>
            </Col>
          </Row>

          {hasUserAdminRole && (
            <TimeRegistrationUserSettings
              noOfEnabledTimeRegistrations={
                organizationSettings?.noOfEnabledTimeRegistrations
              }
              timeRegistrationCap={organizationSettings?.timeRegistrationCap}
              hasNoSeatsLeft={hasNoSeatsLeft}
            />
          )}
        </Space>
      ),
    },
    {
      message: <AssignUserRoles />,
    },
  ];

  return (
    <Form
      form={form}
      initialValues={{ user: userInfo }}
      preserve
      layout="vertical"
      name="form_in_modal"
      className="cdx-create-user-modal"
    >
      <div className="modal-header">
        <a className="close" onClick={cancelModal}>
          &times;
        </a>
        <h4 className="modal-title">
          {currentStep === 1 &&
            gettextCatalog.getString('Settings for {{ name }}', {
              name: userFirstName || userEmail,
            })}
          {currentStep === 2 &&
            gettextCatalog.getString('Grant {{ name }} permissions', {
              name: userFirstName || userEmail,
            })}
          {currentStep !== 1 &&
            currentStep !== 2 &&
            gettextCatalog.getString('Who do you want to invite?')}
        </h4>
      </div>
      <div className="modal-body">
        <Space size="large" direction="vertical" style={{ display: 'block' }}>
          <Steps
            size="small"
            current={currentStep}
            style={{ marginBottom: '24px' }}
          >
            <Steps.Step title={gettextCatalog.getString('Information')} />
            <Steps.Step title={gettextCatalog.getString('Setup')} />
            <Steps.Step title={gettextCatalog.getString('Permissions')} />
          </Steps>
          {steps[currentStep].message}
        </Space>
      </div>

      <div className="modal-footer">
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            {currentStep > 0 ? (
              <Button onClick={prevStep}>
                {gettextCatalog.getString('Back')}
              </Button>
            ) : null}
          </Col>
          <Col span={12}>
            <Button onClick={cancelModal} type="link">
              {gettextCatalog.getString('Cancel')}
            </Button>
            {currentStep < 2 ? (
              <Button type="primary" onClick={nextStep}>
                {gettextCatalog.getString('Next')}
              </Button>
            ) : (
              <Button onClick={save} type="primary" loading={isSaving}>
                {gettextCatalog.getString('Send invite')}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

const CreateUserModalRoot: FunctionComponent<{
  launchNewGroupModal?: any;
  closeModal?: any;
  userInfo?: any;
}> = ({ launchNewGroupModal, closeModal, userInfo }) => (
  <CreateUserModal
    launchNewGroupModal={launchNewGroupModal}
    closeModal={closeModal}
    userInfo={userInfo}
  />
);

export default CreateUserModalRoot;
