import angular from 'angular';

import { getCDAccessToken, getCDLoginToken } from './ChurchEventSync';

import I18nService from '@/react/services/I18nService';
import LanguageService from '@/react/organization/services/language.service';
import { navigate } from '@/react/services/StateServiceFactory';

angular
  .module('cdApp')
  .service('routingHelpers', [
    'cdApp',
    function (cdApp) {
      'ngInject';

      return {
        buildTitle(title, isPrivate = true) {
          const parts = [title];

          if (isPrivate) {
            parts.push('|', _.get(cdApp, 'organization.name'));
          }
          return parts.join(' ');
        },
      };
    },
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      'ngInject';

      // General configuration
      $stateProvider

        // Main application state
        .state('app', {
          abstract: true,
          views: {
            app: {
              template:
                '<shared-context-react /><div class="app-view" ui-view="area"></div>',
            },
          },
        })

        // Border state for state that do not require authentication
        .state('app.public', {
          abstract: true,
          views: {
            area: {
              template: '<div class="app-view" ui-view></div>',
            },
          },
          onEnter: [
            'Me',
            '$http',
            'amMoment',
            function (Me, $http, amMoment) {
              // When entering a public (non authenticated page) we retrieve the language using the following order:
              // 1. Me(), (if organizationId is specified we ignore Me(), even though the user is logged in.)
              // 2. If Me() cannot authenticated we use organizationId to fetch the language publically available.
              // 3. Using the current browser language
              // ..keep in mind that Me() is getCurrentUser() inside AuthenticationService
              return new Promise((resolve) => {
                Me.then(() => resolve()).catch(() => {
                  const queryString = window.location.search;
                  const urlParams = new URLSearchParams(queryString);
                  const organizationId = urlParams.get('organizationId');

                  if (organizationId) {
                    // Override language mode, ignore language from the Me endpoint and instead fetch public organization info to get the language
                    return LanguageService.getLanguage(organizationId)
                      .then((response) => {
                        amMoment.changeLocale(response.language);
                        return I18nService.setCurrentLanguage(
                          response.language
                        );
                      })
                      .then(resolve);
                  } else {
                    let browserLang = navigator.languages
                      ? navigator.languages[0]
                      : navigator.language || navigator.userLanguage;

                    if (browserLang && browserLang.indexOf('-') !== -1) {
                      browserLang = browserLang.split('-')[0];
                    }
                    amMoment.changeLocale(browserLang);
                    return I18nService.setCurrentLanguage(browserLang).then(
                      resolve
                    );
                  }
                });
              });
            },
          ],
        })

        // Border state for state that require authentication
        .state('app.private', {
          abstract: true,
          url: '/o/{oid:int}',
          params: {
            oid: window.churchdeskOrganizationId,
          },

          resolve: {
            currentUser: [
              'Me',
              function (Me) {
                return Me;
              },
            ],

            hasStripeSetup: [
              '$window',
              '$http',
              '$rootScope',
              'cdApp',
              function ($window, $http, $rootScope, cdApp) {
                return $http
                  .get(
                    `${cdApp.config.api.main}/organizations/${$window.churchdeskOrganizationId}/account`
                  )
                  .then((response) => {
                    if (!response || response?.status !== 200) {
                      throw response;
                    }
                    $rootScope.hasStripeSetup = true;
                    $rootScope.accountData = response.data;
                  })
                  .catch(() => {
                    $rootScope.hasStripeSetup = false;
                  });
              },
            ],

            isLocked: [
              '$window',
              '$http',
              '$state',
              'gettextCatalog',
              'cdApp',
              '_',
              function ($window, $http, $state, gettextCatalog, cdApp, _) {
                return $http
                  .get(
                    `${cdApp.config.api.main}/v2/organizations/${$window.churchdeskOrganizationId}/locked`
                  )
                  .then(_.noop, (error) => {
                    if (error.status !== 402) return false;

                    setTimeout(() => {
                      $window.swal(
                        {
                          title: gettextCatalog.getString(
                            'Your ChurchDesk has been suspended'
                          ),

                          text: gettextCatalog.getString(
                            'As no payment has been received from you, we have now locked your ChurchDesk. If you have any questions or need help processing your payment to reactivate your ChurchDesk, ' +
                              'please contact us at <a href="mailto:support@churchdesk.com">support@churchdesk.com</a>. ' +
                              '<br><br>' +
                              'If you pay by credit card, click the button below to add your payment details and proceed immediately.'
                          ),

                          type: 'warning',
                          allowEscapeKey: false,
                          customClass: 'custom-swal',
                          confirmButtonColor: '#008db6',
                          confirmButtonText:
                            gettextCatalog.getString('Continue'),
                          closeOnConfirm: false,
                          closeOnCancel: false,
                          html: true,
                        },

                        () => {
                          $window.swal.close();
                          $state.go('app.public.billing', {
                            stripeCustomerId:
                              cdApp.organization.stripeCustomerId,
                          });
                        }
                      );
                    }, 3000);
                  });
              },
            ],
          },

          onEnter: [
            'Me',
            '$state',
            '$stateParams',
            function (Me, $state, $stateParams) {
              Me.then(() => {
                // If the organizationId in the URL isn't one that the user is member of, prevent further access
                const organizationIds = _.map(Me.organizations, 'id');
                if (!_.includes(organizationIds, $stateParams.oid)) {
                  return $state.go('app.public.chooseOrganization');
                }
              });
            },
          ],

          views: {
            area: {
              template: `
              <cd-announcements class="announcements"></cd-announcements>
              <cd-app-menu class="app-menu"></cd-app-menu>
              <div class="app-body" ui-view></div>
            `,
            },
          },
        });

      // Public state definitions
      $stateProvider

        .state('app.public.login', {
          abstract: true,
          url: '/login',
          template: '<div class="app-view" ui-view></div>',
        })
        // Authentication
        .state('app.public.login.form', {
          url: '?organization&module&continue',
          params: {
            organization: {
              value: null,
              type: 'int',
              squash: true,
            },
            module: {
              value: null,
              type: 'string',
              squash: true,
            },
            continue: {
              value: null,
              type: 'string',
              squash: true,
            },
          },
          component: 'cdLoginPage',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Log in');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base),
                    false
                  ),
                };
              },
            ],
          },

          onEnter: [
            'Me',
            '$state',
            '$stateParams',
            'AuthenticationService',
            '_',
            async function (
              Me,
              $state,
              $stateParams,
              AuthenticationService,
              _
            ) {
              const organizationId =
                $stateParams.organization || window.churchdeskOrganizationId;

              // Check whether we are masquerading, by looking at the hash parameter
              if ($stateParams['#']) {
                const masqueradeToken =
                  $stateParams['#'].split('masquerade=')[1];

                if (!_.isEmpty(masqueradeToken)) {
                  await AuthenticationService.handleMasquerade(
                    masqueradeToken,
                    organizationId,
                    $stateParams.module
                  );

                  return false;
                }
              }

              // If logged in, redirect the user to the appropriate place, otherwise load the login page
              return Me.then(
                () => {
                  // When coming from Drupal or Altar, redirect passing a loginToken in order to authenticate on those domains
                  if ($stateParams.module) {
                    AuthenticationService.redirectExternal(
                      organizationId,
                      $stateParams.module,
                      $stateParams.continue || '/'
                    );

                    return false;
                  }

                  // Otherwise go to the state where you can choose the organization, passing the `continue` param for taking the user where they wanted to go
                  return $state.target('app.public.chooseOrganization', {
                    continue: $stateParams.continue,
                  });
                },
                () => {
                  AuthenticationService.removeAccessToken();
                  return true;
                }
              );
            },
          ],
        })
        .state('app.public.login.sso', {
          url: '/sso',
          component: 'ssoPage',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Authenticate SSO');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base),
                    false
                  ),
                };
              },
            ],
          },
        })
        .state('app.public.logout', {
          url: '/logout',
          onEnter: [
            '$state',
            '$http',
            'AuthenticationService',
            'cdApp',
            'Me',
            function ($state, $http, AuthenticationService, cdApp, Me) {
              return Me.then(
                () => AuthenticationService.logout(true),
                () => $state.target('app.public.login.form')
              );
            },
          ],
        })
        .state('app.public.chooseOrganization', {
          url: '/choose?{continue:string}',
          component: 'cdUserOrganizationsPage',
          params: {
            continue: null,
          },

          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Log in');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base),
                    false
                  ),
                };
              },
            ],
          },

          onEnter: [
            '$state',
            '$stateParams',
            'Me',
            'AuthenticationService',
            '_',
            function ($state, $stateParams, Me, AuthenticationService, _) {
              // Check whether the `Me` service is already loading, to avoid double requests
              // This is necessary sometimes when we redirect to this page from a public page, such as the forgot password page
              Me = Me.$$state.pending
                ? Me
                : AuthenticationService.getCurrentUser();
              let organizationIdFromUrl = null;

              if ($stateParams.continue) {
                const match = $stateParams.continue.match(/\/o\/([\d]+)/);
                if (match) {
                  organizationIdFromUrl = _.parseInt(match[1]);
                }
              }

              // If logged in and user is only part of one organization, it's not necessary to show this page, so we take them directly to that organization
              // Furthermore if a `continue` param was specified, we extract the organizationId from that and redirect the user to that specific page
              // If none of these conditions match, then just show the list of organizations for the user to choose from
              return Me.then(() => {
                if (
                  _.isNumber(organizationIdFromUrl) ||
                  Me.organizations.length === 1
                ) {
                  AuthenticationService.redirect(
                    organizationIdFromUrl || Me.organizations[0].id,
                    $stateParams.continue
                  );

                  return false;
                }
              });
            },
          ],
        })
        .state('app.public.forgotPassword', {
          url: '/recover',
          component: 'cdForgotPasswordPage',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Reset password');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base),
                    false
                  ),
                };
              },
            ],
          },

          onEnter: [
            '$state',
            'AuthenticationService',
            function ($state, AuthenticationService) {
              // Ensure that the user is logged out so we allow the recover to proceed.
              return AuthenticationService.logout(false)
                .then(() => true)
                .catch(() => true);
            },
          ],
        })

        // Register new users
        .state('app.public.register', {
          url: '/register?{token:string}&{language:string}',
          component: 'cdRegisterPage',
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Register');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base),
                    false
                  ),
                };
              },
            ],
          },

          onEnter: [
            '$stateParams',
            'Me',
            function ($stateParams, Me) {
              /**
               * Wait for the Me endpoint to be resolved in order to get the organization's language if the user
               * is not logged in. Otherwise, it will be set from the user's browser
               * @see app.config.js
               */
              return Me.catch(() =>
                I18nService.setCurrentLanguage($stateParams.language)
              );
            },
          ],
        })

        // People subscription confirmation
        .state('app.public.confirmPeopleSubscription', {
          url: '/confirm?{user:int}&{organizationId:int}&{outsideAccess:string}',
          component: 'cdPeopleSubscriptionConfirmState',
          onEnter: [
            '$state',
            '$stateParams',
            '$http',
            function ($state, $stateParams, $http) {
              if (
                !$stateParams.user ||
                !$stateParams.organizationId ||
                !$stateParams.outsideAccess
              ) {
                return true;
              }

              return $http
                .post(
                  `${cdApp.config.api.main}/people/people/${$stateParams.user}/confirm`,
                  {},
                  {
                    params: {
                      organizationId: $stateParams.organizationId,
                      outsideAccess: $stateParams.outsideAccess,
                    },
                    headers: {
                      'CD-IGNORE-AUTH-REDIRECT': true,
                    },
                  }
                )
                .catch(() => {
                  navigate('app.public.errorLinkExpiration');
                  return null;
                });
            },
          ],
        })
        // People subscription management
        // Matches either for path `subscription` (backward compatibility) or `unsub`
        // Changing path to `unsub` due to bug https://churchdesk.atlassian.net/browse/SUP-2348
        .state('app.public.managePeopleSubscription', {
          url: '/{path:subscription|unsub}?{user:int}&{organization:int}&{outsideAccess:string}&{message:int}&{lang:string}&{notificationLogId:int}',
          component: 'cdPeopleSubscriptionManageState',
          resolve: {
            organization: [
              '$q',
              '$http',
              '$state',
              '$stateParams',
              'cdApp',
              function ($q, $http, $state, $stateParams, cdApp) {
                return $http
                  .get(
                    `${cdApp.config.api.main}/organizations/${$stateParams.organization}/public`,
                    {
                      headers: {
                        'CD-IGNORE-AUTH-REDIRECT': true,
                      },
                    }
                  )
                  .catch((error) => {
                    $state.go('app.public.errorState', {
                      errorCode: 'NOT_FOUND',
                    });

                    return $q.reject(error);
                  });
              },
            ],
          },

          onEnter: [
            '$stateParams',
            function ($stateParams) {
              return I18nService.setCurrentLanguage($stateParams.lang);
            },
          ],
        })

        // Public billing page
        .state('app.public.billing', {
          url: '/billing/:stripeCustomerId',
          template: `
          <div class="app-main">
            <cd-billing-information stripe-customer-id="$ctrl.stripeCustomerId" read-only="true"></cd-billing-information>
          </div>
        `,
          controller: [
            '$stateParams',
            function ($stateParams) {
              'ngInject';

              this.stripeCustomerId = $stateParams.stripeCustomerId;
            },
          ],

          controllerAs: '$ctrl',
        })

        // Generic error state
        .state('app.public.errorState', {
          component: 'cdErrorState',
          params: {
            errorCode: null,
          },

          resolve: {
            errorCode: [
              '$stateParams',
              ($stateParams) => $stateParams.errorCode || 'NOT_FOUND',
            ],

            $title: [
              'gettext',
              '$stateParams',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, $stateParams, gettextCatalog, routingHelpers) {
                switch ($stateParams.errorCode) {
                  case 'ACCESS_DENIED': {
                    const base = gettext('Access Denied');
                    return {
                      base,
                      rendered: routingHelpers.buildTitle(
                        gettextCatalog.getString(base),
                        false
                      ),
                    };
                  }
                  case 'NOT_FOUND': {
                    const base = gettext('Page Not Found');
                    return {
                      base,
                      rendered: routingHelpers.buildTitle(
                        gettextCatalog.getString(base),
                        false
                      ),
                    };
                  }
                  default: {
                    const base = gettext('Errors');
                    return {
                      base,
                      rendered: routingHelpers.buildTitle(
                        gettextCatalog.getString(base),
                        false
                      ),
                    };
                  }
                }
              },
            ],
          },
        });

      // Other miscellaneous states
      $stateProvider.state('app.private.churchevents', {
        url: '/churchevents',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Churchevents');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base),
                  false
                ),
              };
            },
          ],

          errorCode: [
            'Authorization',
            function (Authorization) {
              return !Authorization.hasPackage('churchEvents')
                ? 'PACKAGE_NOT_ENABLED'
                : null;
            },
          ],
        },

        onEnter: [
          '$stateParams',
          'errorCode',
          'AuthenticationService',
          'cdApp',
          function ($stateParams, errorCode, AuthenticationService, cdApp) {
            if (!errorCode) {
              getCDLoginToken(cdApp.organization.modules.churchEvents);
            }
          },
        ],
      });
    },
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    ($stateProvider, $urlRouterProvider) => {
      'ngInject';
      $urlRouterProvider.when('/transfer', [
        '$state',
        '$location',
        function ($state, $location) {
          // eslint-disable-next-line destructuring/no-rename
          const { organizationId, continue: _continue } = $location.search();
          if (!organizationId || !_continue) {
            alert(
              `Church Events integration requires OrganizationId and a Continue param:\n recived: OrganizationId: ${organizationId} \n continue: ${_continue}`
            );
          }
          const redirectUrl = `/o/${$location.search().organizationId}/${
            $location.search().continue
          }`;
          getCDAccessToken(
            $location.hash().replace('loginToken=', ''),
            redirectUrl
          );
        },
      ]);
      $stateProvider.decorator('parent', function (internalStateObj, parentFn) {
        // The first arg is the internal state. Capture it and add an accessor to public state object.
        internalStateObj.self.$$state = function () {
          return internalStateObj;
        };

        // Pass through to default .parent() function
        return parentFn(internalStateObj);
      });
      /**
       * Redirect old URLs
       *
       * Each state has 2 types of redirects:
       *    - one for routes before the :oid param was added and that didn't have a parent before (e.g. /event/:id -> /calendar/event/:id)
       *    - another one for routes after the :oid param was added and that didn't have a parent before and (e.g. /o/:oid/event/:id -> /o/:oid/calendar/event/:id)
       *
       * Keep in mind that there is a custom function making sure you are always redirected to the correct state containing the :oid if not specified
       */
      $urlRouterProvider.otherwise(function ($injector, $location) {
        let Me = $injector.get('Me');
        let $state = $injector.get('$state');
        let targetState = 'app.private.dashboard.default';
        let targetStateParams = {};

        // If logged in, match the current URL with a state. If it matches an existing
        // state, we redirect to that state, otherwise we redirect to dashboard. If not
        // logged in, redirect to the log in page.
        Me.then(
          () => {
            angular.forEach($state.get(), function (state) {
              if (!state.$$state) return;

              let privatePortion = state.$$state();
              let match;

              if (
                privatePortion &&
                privatePortion.url &&
                !privatePortion.abstract
              ) {
                match = privatePortion.url.exec(
                  '/o/' + window.churchdeskOrganizationId + $location.path(),
                  $location.search()
                );
              }

              if (match) {
                targetState = state.name;
                targetStateParams = match;
              }
            });
            return window.churchdeskOrganizationId
              ? $state.go(targetState, targetStateParams)
              : $state.go('app.public.login.form');
          },
          () => $state.go('app.public.login.form')
        );
      });
      // Calendar
      $urlRouterProvider.when('/calendar', [
        '$state',
        '$location',
        function ($state, $location) {
          $state.go('app.private.calendar.full', $location.search());
        },
      ]);
      $urlRouterProvider.when('/o/:oid/calendar', [
        '$state',
        '$location',
        function ($state, $location) {
          $state.go('app.private.calendar.full', $location.search());
        },
      ]);
      $urlRouterProvider.when('/event/:id', '/calendar/event/:id');
      $urlRouterProvider.when(
        '/o/:oid/event/:id',
        '/o/:oid/calendar/event/:id'
      );

      $urlRouterProvider.when('/absence/:id', '/calendar/absence/:id');
      $urlRouterProvider.when(
        '/o/:oid/absence/:id',
        '/o/:oid/calendar/absence/:id'
      );

      $urlRouterProvider.when('taxonomies/:type', '/calendar/taxonomies/:type');
      $urlRouterProvider.when(
        '/o/:oid/taxonomies/:type',
        '/o/:oid/calendar/taxonomies/:type'
      );

      $urlRouterProvider.when(
        '/taxonomies/:type/create',
        '/calendar/taxonomies/:type/create'
      );

      $urlRouterProvider.when(
        '/o/:oid/taxonomies/:type/create',
        '/o/:oid/calendar/taxonomies/:type/create'
      );

      $urlRouterProvider.when('/o/:oid/calendar/rotas', [
        '$state',
        '$location',
        function ($state, $location) {
          $state.go('app.private.calendar.rotas.schemes', $location.search());
        },
      ]);

      // Intranet
      $urlRouterProvider.when('/group/:gid', '/intranet/group/:gid/overview');
      $urlRouterProvider.when(
        '/o/:oid/group/:gid',
        '/o/:oid/intranet/group/:gid/overview'
      );

      $urlRouterProvider.when(
        '/o/:oid/group/:gid/members',
        '/o/:oid/intranet/group/:gid/members'
      );

      $urlRouterProvider.when(
        '/o/:oid/group/:gid/messages',
        '/o/:oid/intranet/group/:gid/messages'
      );

      $urlRouterProvider.when(
        '/o/:oid/group/:gid/message/:id',
        '/o/:oid/intranet/group/:gid/message/:id'
      );

      $urlRouterProvider.when(
        '/o/:oid/group/:gid/blogs',
        '/o/:oid/intranet/group/:gid/blogs'
      );

      $urlRouterProvider.when(
        '/o/:oid/group/:gid/blog/:id?global',
        '/o/:oid/intranet/group/:gid/blog/:id?global'
      );

      $urlRouterProvider.when(
        '/o/:oid/group/:gid/blog/:id/revision/:rid',
        '/o/:oid/intranet/group/:gid/blog/:id/revision/:rid'
      );

      $urlRouterProvider.when('/groups', '/intranet/groups');
      $urlRouterProvider.when('/o/:oid/groups', '/o/:oid/intranet/groups');
      $urlRouterProvider.when('/files', '/intranet/files');
      $urlRouterProvider.when('/o/:oid/files', '/o/:oid/intranet/files');

      $urlRouterProvider.when('/blogs', '/intranet/blogs');
      $urlRouterProvider.when('/o/:oid/blogs', '/o/:oid/intranet/blogs');
      $urlRouterProvider.when(
        '/blogs/:id/revisions',
        '/intranet/blogs/:id/revisions'
      );

      $urlRouterProvider.when(
        '/o/:oid/blogs/:id/revisions',
        '/o/:oid/intranet/blogs/:id/revisions'
      );

      $urlRouterProvider.when('/team', '/settings/users');
      $urlRouterProvider.when('/o/:oid/team', '/o/:oid/settings/users');
      $urlRouterProvider.when('/team/user/:id', '/settings/users/:id');
      $urlRouterProvider.when(
        '/o/:oid/team/user/:id',
        '/o/:oid/settings/users/:id'
      );

      $urlRouterProvider.when(
        '/team/user/:id/edit',
        '/settings/users/:id/edit'
      );

      $urlRouterProvider.when(
        '/o/:oid/team/user/:id/edit',
        '/o/:oid/settings/users/:id/edit'
      );

      $urlRouterProvider.when('/churchscreen', '/intranet/churchscreen');
      $urlRouterProvider.when(
        '/o/:oid/churchscreen',
        '/o/:oid/intranet/churchscreen'
      );

      $urlRouterProvider.when('/team/user', [
        '$state',
        'Me',
        function ($state, Me) {
          return Me.then(function () {
            $state.go('app.private.settings.users.detail', { id: Me.id });
          });
        },
      ]);
      $urlRouterProvider.when('/team/user/edit', [
        '$state',
        'Me',
        function ($state, Me) {
          return Me.then(function () {
            $state.go('app.private.settings.users.edit', { id: Me.id });
          });
        },
      ]);
      /**
       * Rewrite old URLs that pointed to `intranet` to `groups`
       */
      $urlRouterProvider.when(/.*\/intranet\/*.*/, [
        '$location',
        ($location) => {
          'ngInject';

          return $location.path().replace('intranet', 'groups');
        },
      ]);
    },
  ]);

require('./dashboard/dashboard.routes');
require('./calendar/calendar.routes');
require('./intranet/intranet.routes');
require('./intranet/shifts/shifts.routes');
require('./homepage/homepage.routes');
require('./safeguarding/safeguarding.routes');
require('./people/people.routes');
require('./contributions/contributions.routes');
require('./forms/forms.routes');
require('./settings/settings.routes');
require('./intentionen/intentionen.routes');
require('./portal/portal.routes');
