import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Table as AntTable,
  Typography,
} from 'antd';
import { find } from 'lodash';

import ColumnSelect from '../ColumnSelect';
import CdrEmptyState from '../CdrEmptyState';

import actionMenu from './ActionsMenu';
import { CdPagination } from './CdPagination';

import {
  CdTableProps,
  ColumnType,
} from '@/react/shared/components/cd-table/types';
import { CdAngleDown, CdColumns } from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';
import { OrderDirection, TableOrdering } from '@/react/shared/models/table';

export const Table = styled<any>(AntTable)`
  .errorRow {
    background-color: #f2dede;
  }
  .warningRow {
    background-color: #fffbe6;
  }
  ${({ $rowStyles }) => ($rowStyles ? $rowStyles : '')}
`;

function CdTable<T extends Record<string, any> = any>({
  columns,
  data,
  actionButtons = [],
  onTableChange = () => {},
  headingText = '',
  rowSelection,
  showColumnSelector = true,
  isLoading = false,
  filterForm,
  noStyle = false,
  emptyStateProps,
  rowStyles,
  rowKey,
  rowClassName,
  page,
  pageSize = 30,
  OverridePagination,
  onRow = () => {},
}: CdTableProps<T>) {
  const [selectedColumns, setSelectedColumns] = useState(
    columns
      .filter(({ hidden }) => !hidden)
      .map(({ key, dataIndex }) => key || dataIndex)
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [pageNumber, setPageNumber] = page || useState<number>(1);
  const [tableOrdering, setTableOrdering] = useState<TableOrdering>(null);
  const [dropDownVisible, setDropDownVisible] = useState<T | boolean>(false);

  const churchdeskTableDefaults: Partial<TableProps<T>> = {
    size: 'small',
    bordered: true,
    rowKey: rowKey || 'id',
    scroll: { x: true },
    rowClassName: rowClassName
      ? rowClassName
      : (data) => (data?.deletedAt ? 'errorRow' : null),
  };
  useEffect(() => {
    const columnDetails = find(
      columns,
      (column) => column.defaultSortOrder
    ) as ColumnType<T>;
    if (columnDetails) {
      handleOrderingChange(null, null, {
        field: columnDetails.dataIndex as string,
        order: columnDetails.defaultSortOrder,
      });
    }
    // when we have dynamic columns, we need to update the selected columns
    setSelectedColumns(
      columns
        .filter(({ hidden }) => !hidden)
        .map(({ key, dataIndex }) => key || dataIndex)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const handleOrderingChange = (
    _newPagination: any,
    _filters: any,
    sorter: SorterResult<T>
  ) => {
    if (sorter.field && sorter.order) {
      const columnDetails = find(
        columns,
        (column) => column.dataIndex === sorter.field
      ) as ColumnType<T>;
      const newTableOrdering: TableOrdering = {
        orderBy: sorter.field.toLocaleString(),
        orderDirection:
          sorter.order === 'ascend'
            ? OrderDirection.ASC
            : sorter.order === 'descend'
              ? OrderDirection.DESC
              : null,
        orderType: columnDetails.orderType,
      };

      setTableOrdering(newTableOrdering);
      onTableChange(pageNumber, pageSize, newTableOrdering, 'ordering');
    }
  };

  const handlePaginationChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
    onTableChange(newPageNumber, pageSize, tableOrdering, 'pagination');
  };

  const actionColumn: ColumnType<T> = useMemo(
    () => ({
      title: null,
      dataIndex: null,
      key: 'actionsMenu',
      fixed: 'right',
      width: 120,
      render: function columnRender(dataItem: T) {
        return (
          <Dropdown
            onOpenChange={(visible) =>
              visible
                ? setDropDownVisible(dataItem)
                : setDropDownVisible(visible)
            }
            menu={{
              items: actionMenu({
                actionButtons,
                setDropDownVisible,
                item: dataItem,
              }),
            }}
            open={dropDownVisible === dataItem}
          >
            <Button size="small" onClick={() => setDropDownVisible(dataItem)}>
              {gettextCatalog.getString('Actions')} <CdAngleDown />
            </Button>
          </Dropdown>
        );
      },
    }),
    [actionButtons, dropDownVisible]
  );

  const filterCheckbox = useMemo(
    () =>
      columns.map(({ title, key, dataIndex }) => ({
        label: title,
        value: key || dataIndex,
      })),
    [columns]
  );

  const activeColumns = useMemo(
    () =>
      columns
        .filter(({ key, dataIndex }) =>
          selectedColumns.includes(key || dataIndex)
        )
        .concat(actionButtons.length ? actionColumn : []),
    [columns, actionButtons.length, selectedColumns, actionColumn]
  );

  const activeColumnsKeys = useMemo(
    () => activeColumns.map(({ key, dataIndex }) => key || dataIndex),
    [activeColumns]
  );

  return (
    <>
      {/* Filter row */}
      {filterForm && (
        <Card
          style={{ boxShadow: 'none' }}
          bodyStyle={{ padding: noStyle ? '0' : '24px', boxShadow: 'none' }}
          bordered={!noStyle}
        >
          <Row justify="end">
            <Col>{filterForm}</Col>
          </Row>
        </Card>
      )}
      {/* Num results and column Selector */}
      <Card
        style={{ boxShadow: 'none' }}
        bodyStyle={{ padding: noStyle ? '0' : '24px', boxShadow: 'none' }}
        bordered={!noStyle}
      >
        <Row wrap={false}>
          {headingText && (
            <Col flex="none">
              <Typography.Title level={3} style={{ marginTop: 8 }}>
                {headingText}
              </Typography.Title>
            </Col>
          )}
          {showColumnSelector && (
            <Col flex="auto">
              <div style={{ textAlign: 'right', marginBottom: '8px' }}>
                <ColumnSelect
                  value={activeColumnsKeys}
                  items={filterCheckbox}
                  onChange={setSelectedColumns}
                  buttonLabel={gettextCatalog.getString('Show columns')}
                  buttonIcon={<CdColumns />}
                />
              </div>
            </Col>
          )}
        </Row>
        {data.items?.length > 0 || isLoading ? (
          <>
            <Table
              {...churchdeskTableDefaults}
              columns={activeColumns}
              dataSource={data?.items}
              onChange={handleOrderingChange}
              pagination={false}
              // @ts-ignore
              $rowStyles={rowStyles}
              // preserveSelectedRowKeys is used to keep the selected rows when the table is re-rendered
              rowSelection={
                rowSelection && {
                  ...rowSelection,
                  preserveSelectedRowKeys: true,
                }
              }
              bordered
              loading={isLoading}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              onRow={onRow}
            />
          </>
        ) : (
          <Table
            bordered
            size={churchdeskTableDefaults.size}
            columns={activeColumns}
            scroll={churchdeskTableDefaults.scroll}
            locale={{
              emptyText: <CdrEmptyState {...emptyStateProps} />,
            }}
          ></Table>
        )}
        <CdPagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          data={data}
          handlePaginationChange={handlePaginationChange}
          OverridePagination={OverridePagination}
          isLoading={isLoading}
        />
      </Card>
    </>
  );
}

export default CdTable;
