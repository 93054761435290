import { useForm } from 'antd/es/form/Form';
import { useCallback, useEffect } from 'react';

import getTextCatalog from '../../services/I18nService';
import { PortalCategoryType } from '../state/portal';

import { mainApi } from '@/react/api';
import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';

export default function useCreatePortalCategory(
  setModalProps: any,
  portalId: string,
  categoryType: PortalCategoryType,
  onFinish: any
) {
  const [form] = useForm();

  const onOk = useCallback(
    () =>
      form
        .validateFields()
        .then(async (values) =>
          mainApi.post(`/portal/categories`, {
            name: values.name,
            description: values.description === '' ? null : values.description,
            type: categoryType,
            portalId,
          })
        )
        .then(() => {
          onFinish();
        })
        .catch(handleAntFormValidationErrors),
    [categoryType, form, onFinish, portalId]
  );

  useEffect(() => {
    setModalProps({
      title: getTextCatalog.getString('Create portal category'),
      width: 500,
      onOk,
    });
  }, [setModalProps, onOk]);

  return { form };
}
