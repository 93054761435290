import { launchFilePicker } from '@/react/angular/ReactModalBridge';

$.Redactor.prototype.imagePicker = function () {
  return {
    getTemplate() {
      return `
        <div class="redactor-modal-tab redactor-group" data-title="General">
          <div id="redactor-image-preview" class="redactor-modal-tab-side"></div>

          <div class="redactor-modal-tab-area">
            <section>
              <label>${this.lang.get('title')}</label>
              <input type="text" id="redactor-image-title" />
            </section>

            <section>
              <div class="row">
                <div class="col-sm-6">
                  <label class="redactor-image-position-option">${this.lang.get(
                    'image-position'
                  )}</label>
                  <select class="redactor-image-position-option" id="redactor-image-align">
                    <option value="none">${this.lang.get('none')}</option>
                    <option value="left">${this.lang.get('left')}</option>
                    <option value="center">${this.lang.get('center')}</option>
                    <option value="right">${this.lang.get('right')}</option>
                  </select>
                </div>

                <div class="col-sm-3">
                  <label>${this.lang.get('width')}</label>
                  <input type="number" min="1" id="redactor-image-width" class="form-control" />
                </div>

                <div class="col-sm-3">
                  <label>${this.lang.get('height')}</label>
                  <input type="number" min="1" id="redactor-image-height" class="form-control" />
                </div>
              </div>
            </section>

            <section>
              <label>${this.lang.get('link')}</label>
              <input type="text" id="redactor-image-link" />
            </section>

            <section>
              <div class="checkbox">
                <label><input type="checkbox" id="redactor-image-link-blank"> ${this.lang.get(
                  'link-in-new-tab'
                )}</label>
              </div>
            </section>

            <section>
              <button id="redactor-modal-button-action">${this.lang.get(
                'insert'
              )}</button>
              <button id="redactor-modal-button-cancel">${this.lang.get(
                'cancel'
              )}</button>
              <button id="redactor-modal-button-delete" class="pull-left">${this.lang.get(
                'delete'
              )}</button>
            </section>
          </div>
        </div>
      `;
    },

    init() {
      const that = this;
      const button = this.button.addAfter(
        'table',
        'image',
        this.lang.get('image')
      );

      this.button.setIcon(button, '<i class="re-icon-image"></i>');
      this.modal.addTemplate('image-edit', this.imagePicker.getTemplate());

      this.image.showEdit = _.wrap(
        this.image.showEdit,
        function (original, $image) {
          let result = original.apply(this, _.tail(arguments));

          $('#redactor-image-width').val($image.width());
          $('#redactor-image-height').val($image.height());

          return result;
        }
      );

      this.image.update = _.wrap(this.image.update, function (original) {
        let result = original.apply(this, _.tail(arguments));
        let _image = that.observe.image;

        let image = {
          original: {
            width: _image.width(),
            height: _image.height(),
          },

          modified: {
            width: _.parseInt($('#redactor-image-width').val()),
            height: _.parseInt($('#redactor-image-height').val()),
          },
        };

        _image.width(image.modified.width);
        _image.height(image.modified.height);

        if (
          image.modified.height !== image.original.height &&
          image.modified.width === image.original.width
        ) {
          _image.height(image.modified.height);
          _image.width('auto');
        }

        if (
          image.modified.width !== image.original.width &&
          image.modified.height === image.original.height
        ) {
          _image.width(image.modified.width);
          _image.height('auto');
        }

        return result;
      });

      window.addEventListener('message', (event) => {
        const messageType = event.data?.message;
        const isUsedFromEditor = event.data?.data?.isUsedFromEditor;

        if (
          messageType === 'FilePickerComponentModalCallback' &&
          isUsedFromEditor
        ) {
          const { file, imageFormat } = event.data?.data;
          const format = imageFormat || 'medium';
          const url = file.fileInfo[format].url;
          this.selection.restore();
          this.image.insert({ id: file.id, url }, false);
        }
      });

      this.button.addCallback(button, () => {
        this.selection.save();
        launchFilePicker(true);
      });
    },
  };
};
