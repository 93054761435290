import NiceModal, { useModal } from '@ebay/nice-modal-react';
import React, { useEffect } from 'react';

import { createCdModal } from '../../shared/components/cd-modal-new/CdModal2';
import { useSafeguarding } from '../../safeguarding/hooks/useSafeguarding';

import CreateUserModalRoot from './CreateUserModal';

export const NewUserModal = createCdModal({
  modalName: 'NewUserModal',
  InnerModal: ({ setModalProps, userInfo }) => {
    const { refreshSafeguardingList } = useSafeguarding();
    const modal = useModal('NewUserModal');
    const closeModal = (user) => {
      if (!user) {
        modal.hide();
        return;
      }
      refreshSafeguardingList();
      modal.hide();
    };
    const launchNewGroupModal = (done) => {
      NiceModal.show('NewGroupModal', { done: done });
    };
    useEffect(() => {
      setModalProps({
        footer: null,
        closable: false,
        width: 800,
        maskClosable: false,
      });
    }, [setModalProps]);

    return (
      <CreateUserModalRoot
        launchNewGroupModal={launchNewGroupModal}
        closeModal={closeModal}
        userInfo={userInfo}
      />
    );
  },
});
